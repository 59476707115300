import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { BeatLoader } from 'react-spinners';
import { GoogleLogin } from 'react-google-login';
import moment from "moment";
import * as userActions  from './../actions/auth.action';
import * as Settings from "settings";
import * as Services from "services";

class Registration extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        this.props.logout();

        this.state = {
            email: '',
            last_name: '',
            first_name: '',
            password: '',
            roles_mask: 'patient',
            date_of_birth: moment().format("YYYY-MM-DD"),
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.googleHandler = this.googleHandler.bind(this);
    }

    componentDidMount(){
        console.log('Registration componentDidMount ', window.location.origin)
    }    

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });

    }

    registrationHandler(e) {
        e.preventDefault();
        
        this.setState({ submitted: true });
        const { email, password, first_name, last_name } = this.state;
        
        if (password && email && first_name && last_name) {
            let user = Object.assign({}, this.state)
            delete user.submitted;
            this.props.register(Object.assign({}, { user: user}));   
        }
    }

    googleHandler(google){
        console.log(google['profileObj']);
        if(google['profileObj']){
            this.setState(state=>{
                return Object.assign({}, state, {
                    user_name: google['profileObj']['email']
                })
            })
            this.props.google(google);
        }
    }

    render() {
        console.log(this.props);    
        const { registering, registered, registrationFail, history } = this.props;
        const { email, user_name, last_name, first_name, password, date_of_birth, submitted } = this.state;
        
        return (            
            <div className="col-md-4 col-md-offset-4">
                
                <form name="form" >
                    <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                        <label htmlFor="email">Email</label>
                        <input type="text" className="form-control" name="email" value={email} onChange={this.handleChange} />
                        {submitted && !email &&
                            <div className="help-block">Email is required</div>
                        }
                    </div>
                    <div className={'form-group' + (submitted && !first_name ? ' has-error' : '')}>
                        <label htmlFor="last_name">First Name</label>
                        <input type="text" className="form-control" name="first_name" value={first_name} onChange={this.handleChange} />
                        {submitted && !first_name &&
                            <div className="help-block">First Name is required</div>
                        }
                    </div>
                    <div className={'form-group' + (submitted && !last_name ? ' has-error' : '')}>
                        <label htmlFor="last_name">Last Name</label>
                        <input type="text" className="form-control" name="last_name" value={last_name} onChange={this.handleChange} />
                        {submitted && !last_name &&
                            <div className="help-block">Last Name is required</div>
                        }
                    </div>
                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                        <label htmlFor="password">Password</label>
                        <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                        {submitted && !password &&
                            <div className="help-block">Password is required</div>
                        }
                    </div>
                    <div className={'form-group' + (submitted && !date_of_birth ? ' has-error' : '')}>
                        <label htmlFor="date_of_birth">Date of Birth</label>
                        <input type="text" className="form-control" name="date_of_birth" value={date_of_birth} onChange={this.handleChange} />
                        {submitted && !date_of_birth &&
                            <div className="help-block">Date of Birth is required</div>
                        }
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary" style={{width:120+"px"}} onClick={e => this.registrationHandler(e)}>
                            {!registering ?
                                "Register":""
                            }
                            <BeatLoader
                                sizeUnit={"px"}
                                size={16}
                                color={'#e3e3e5'}
                                loading={registering}
                            />
                        </button>  
                        {/* 
                        TO BE IMPLEMENTED LATER
                        <div style={{display:"inline", float:"right"}}>
                            <GoogleLogin
                                clientId={Settings.google.clientId}
                                buttonText="Register using Google"
                                onSuccess={this.googleHandler}
                                onFailure={this.googleHandler}
                                cookiePolicy={'single_host_origin'}
                            />     
                        </div>

                        */}

                    </div>
                    <div className="form-group">
                        <p style={{textAlign:"right"}}>
                            <Link to={"/login"}>Login</Link>
                        </p>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
	registering: state.auth.registering,
	registered: state.auth.registered,
	registrationFail: state.auth.registrationFail,
	formError: state.auth.formError,
	user: state.auth.user 
});

const mapActionsToProps = {
    ...userActions
};

export default connect(mapStateToProps, mapActionsToProps)(Registration)