import Api from 'utils/Http';
import fetch from 'node-fetch';
import moment from "moment";
import inflect from "jargon";
import objectPath from "object-path";
import * as Settings from "settings";
import * as PolicyProviders from "policies";
import Validations from "utils/validations";
import * as Services from './../../services';

export const document = {

    modelFromSchema: (schema) => {
        console.log(schema);
        if(schema.properties){
            let obj = {};
            for(let key of Object.keys(schema.properties)){     
                
                //set default values per property type
                if(schema.properties[key]["type"]){
                    switch(schema.properties[key]["type"]){
                        case "Number"   :    obj[key] = 0; break; 
                        case "Date"     :    obj[key] = moment().format(); break; 
                        case "Array"   :    obj[key] = []; break; 
                        case "Object"   :    obj[key] = {}; break;
                        default         :    obj[key] = ""; break;
                    }
                }    

                //set default values per specified schema default
                if(schema.properties[key]["default"]){
                    obj[key] = schema.properties[key]["default"];
                }

            }
            return obj;
        }

        return {}
    },

    schema : (store, options = {}) => {
        /* no api for schema yet
        let route = '/' + inflect(options.documentType).plural.toString();
        if(Settings[(options.documentType + "List")]){
            let setts = Settings[(options.documentType + "List")];            
            route = objectPath.get(setts, 'routes.service.path', null) || route;
            
            console.log('document.service routes',route);
        }
        return Api.get(route + '/schema' );
        */
        let schema = {}
        if(Settings[options.documentType] && Settings[options.documentType]['schema']){
            schema = Settings[options.documentType] && Settings[options.documentType]['schema'];
        }
        return new Promise( (resolve, reject)=>{
            resolve({ data: schema })
        })
    },      

    count : (store, options = {}) => { 
        let route = '/' + inflect(options.documentType).plural.toString();
        if(Settings[(options.documentType + "List")]){
            let setts = Settings[(options.documentType + "List")];            
            route = objectPath.get(setts, 'routes.service.path', null) || route;
            let queryPath = objectPath.get(setts, 'routes.service.query', null);
            if(queryPath){
                route = route + "/" + queryPath
            }
            console.log('document.service routes',route);
        }
        let filters = document.retrieveDocumentFilters(Object.assign({},options,{
            listSettings: Settings[(options.documentType + "List")] 
        }));
        return Api.get(route + '/count' + (filters ? '?' + filters: '') );
    },

    retrieveList : (store, options = {}) => { 
        let route = '/' + inflect(options.documentType).plural.toString();
        if(Settings[(options.documentType + "List")]){
            let setts = Settings[(options.documentType + "List")];            
            route = objectPath.get(setts, 'routes.service.path', null) || route;
            let queryPath = objectPath.get(setts, 'routes.service.query', null);
            if(queryPath){
                route = route + "/" + queryPath
            }
            console.log('document.service routes',route);
        }
        let filters = document.retrieveDocumentFilters(Object.assign({},options,{
            listSettings: Settings[(options.documentType + "List")] 
        }));
        return Api.get(route + (filters ? '?' + filters: '') );
    },

    afterRetrieveList : (store, options={}) => {

        //call policy provider afterRetrieveList handlers
        if(options.documentType && PolicyProviders[options.documentType] && PolicyProviders[options.documentType]["afterRetrieveList"]){
            PolicyProviders[options.documentType].afterRetrieveList(store, options);
        }   

        //call services afterRetrieveList handler
        if(options.documentType && Services[options.documentType] && Services[options.documentType]["afterRetrieveList"]){
            Services[options.documentType].afterRetrieveList(store, options);
        }

        //raise a document afterRetrieveList Event
        Services.event.triggerEvent("afterRetrieveList", store);

    },

    retrieve : (id, store, options = {}) => {        
        if(id){
            console.log('document.service retrieve axios', Api.defaults);
            let route = '/' + inflect(options.documentType).plural.toString();
            if(Settings[(options.documentType)]){
                let setts = Settings[(options.documentType)];            
                route = objectPath.get(setts, 'routes.service.path', null) || route;
                let queryPath = objectPath.get(setts, 'routes.service.query', null);
                if(queryPath){
                    route = route + "/" + queryPath
                }
                console.log('document.service routes',route);
            }
            let filters = document.retrieveDocumentFilters(options); 
            return Api.get(route + '/' + id + (filters ? '?' + filters: '') );
        }

        return new Promise( (resolve, reject)=>{
            resolve({ data: {} })
        })

    },

    afterRetrieve : (store, options={}) => {

        //call policy provider afterRetrieve handlers
        if(options.documentType && PolicyProviders[options.documentType] && PolicyProviders[options.documentType]["afterRetrieve"]){
            PolicyProviders[options.documentType].afterRetrieve(store, options);
        }   

        //call services afterRetrieve handler
        if(options.documentType && Services[options.documentType] && Services[options.documentType]["afterRetrieve"]){
            Services[options.documentType].afterRetrieve(store, options);
        }

        //raise a document afterRetrieve Event
        Services.event.triggerEvent("afterRetrieve", store);

    },

    retrieveFirst : (store, options = {}) => {        

        console.log('document.service retrieve axios', Api.defaults);
        let route = '/' + inflect(options.documentType).plural.toString();
        if(Settings[(options.documentType)]){
            let setts = Settings[(options.documentType)];            
            route = objectPath.get(setts, 'routes.service.path', null) || route;
            let queryPath = objectPath.get(setts, 'routes.service.query', null);
            if(queryPath){
                route = route + "/" + queryPath
            }
            console.log('document.service routes',route);
        }

        //remove order by filter
        if(options.listSettings && options.listSettings.routes && options.listSettings.routes.service && options.listSettings.routes.service.order){
            delete options.listSettings.routes.service['order'];
        }
        //append pagination limit with 1
        options.pagination={
            page: 0, limit: 2
        }
        let filters = document.retrieveDocumentFilters(options); 

        //add order by filter for first record
        filters += "&id[order]=asc"
        return Api.get(route +  (filters ? '?' + filters: '') );

    },

    retrievePrev : (store, options = {}) => {        

        console.log('document.service retrieve axios', Api.defaults);
        let route = '/' + inflect(options.documentType).plural.toString();
        if(Settings[(options.documentType)]){
            let setts = Settings[(options.documentType)];            
            route = objectPath.get(setts, 'routes.service.path', null) || route;
            let queryPath = objectPath.get(setts, 'routes.service.query', null);
            if(queryPath){
                route = route + "/" + queryPath
            }
            console.log('document.service routes',route);
        }

        //remove order by filter
        if(options.listSettings && options.listSettings.routes && options.listSettings.routes.service && options.listSettings.routes.service.order){
            delete options.listSettings.routes.service['order'];
        }
        //append pagination limit with 1
        options.pagination={
            page: 0, limit: 2
        }
        let filters = document.retrieveDocumentFilters(options); 

        //add order by filter for first record
        filters += "&id[order]=desc"

        //add filter for prev record
        let primaryKey = store.document.primaryKey;
        filters += "&id[lt]=" + primaryKey.id;

        return Api.get(route + (filters ? '?' + filters: '') );

    },

    retrieveNext : (store, options = {}) => {        

        console.log('document.service retrieve axios', Api.defaults);
        let route = '/' + inflect(options.documentType).plural.toString();
        if(Settings[(options.documentType)]){
            let setts = Settings[(options.documentType)];            
            route = objectPath.get(setts, 'routes.service.path', null) || route;
            let queryPath = objectPath.get(setts, 'routes.service.query', null);
            if(queryPath){
                route = route + "/" + queryPath
            }
            console.log('document.service routes',route);
        }

        //remove order by filter
        if(options.listSettings && options.listSettings.routes && options.listSettings.routes.service && options.listSettings.routes.service.order){
            delete options.listSettings.routes.service['order'];
        }
        //append pagination limit with 1
        options.pagination={
            page: 0, limit: 2
        }
        let filters = document.retrieveDocumentFilters(options); 

        //add order by filter for first record
        filters += "&id[order]=asc"

        //add filter for prev record
        let primaryKey = store.document.primaryKey;
        filters += "&id[gt]=" + primaryKey.id;

        return Api.get(route +  (filters ? '?' + filters: '') );

    },

    retrieveLast : (store, options = {}) => {        

        console.log('document.service retrieve axios', Api.defaults);
        let route = '/' + inflect(options.documentType).plural.toString();
        if(Settings[(options.documentType)]){
            let setts = Settings[(options.documentType)];            
            route = objectPath.get(setts, 'routes.service.path', null) || route;
            let queryPath = objectPath.get(setts, 'routes.service.query', null);
            if(queryPath){
                route = route + "/" + queryPath
            }
            console.log('document.service routes',route);
        }

        //remove order by filter
        if(options.listSettings && options.listSettings.routes && options.listSettings.routes.service && options.listSettings.routes.service.order){
            delete options.listSettings.routes.service['order'];
        }
        //append pagination limit with 1
        options.pagination={
            page: 0, limit: 2
        }
        let filters = document.retrieveDocumentFilters(options); 

        //add order by filter for first record
        filters += "&id[order]=desc"

        return Api.get(route + (filters ? '?' + filters: '') );

    },

    beforeSave : (store, options={}) => {

        let beforeSave = {success: true}

        //call validation
        if(Services[options.documentType] && Services[options.documentType]["validators"]){
            beforeSave = Services.document.validate(store.document.documentData, Object.assign({}, options, {
                documentType: options.documentType,
                validators: Services[options.documentType]["validators"]
            }) );
        }

        //call policy provider beforeSave handlers
        if(options.documentType && PolicyProviders[options.documentType] && PolicyProviders[options.documentType]["beforeSave"]){
            beforeSave = PolicyProviders[options.documentType].beforeSave(store, options);
        }   

        //call services beforeSave handler
        if(beforeSave.success){
            if(options.documentType && Services[options.documentType] && Services[options.documentType]["beforeSave"]){
                beforeSave = Services[options.documentType].beforeSave(store, options);
            }
        }

        //raise a document beforeSave Event
        Services.event.triggerEvent("beforeSave", options);
        
        return beforeSave;

    },

    save : (store, options = {}) => {
        let documentData = store.document.documentData;

        if(Services[options.documentType] && Services[options.documentType]["transformDocument"]){
            documentData = Services[options.documentType].transformDocument(store, documentData);
        }

        let primaryKey = store.document.primaryKey;
        let route = inflect(options.documentType).plural.toString();
        if(Settings[(options.documentType)]){
            let setts = Settings[(options.documentType)];            
            route = objectPath.get(setts, 'routes.service.path', null) || route;
            let queryPath = objectPath.get(setts, 'routes.service.query', null);
            if(queryPath){
                route = route + "/" + queryPath
            }
            console.log('document.service routes',route);
        }

        if(options.documentType && Services[options.documentType] && Services[options.documentType]["saveDocument"]){
            return Services[options.documentType].saveDocument(store, Object.assign({},options,{
                primaryKey: primaryKey,
                documentData: documentData
            }));
        }else{
            if(primaryKey.id){
                return Api.put(route + '/' + primaryKey.id, { [options.documentType] : documentData})
            }
            return Api.post(route, { [options.documentType] : documentData})
        }
    },

    afterSave : (store, options={}) => {

        //call policy provider beforeSave handlers
        if(options.documentType && PolicyProviders[options.documentType] && PolicyProviders[options.documentType]["afterSave"]){
            PolicyProviders[options.documentType].afterSave(store, options);
        }   

        //call services beforeSave handler
        if(options.documentType && Services[options.documentType] && Services[options.documentType]["afterSave"]){
            Services[options.documentType].afterSave(store, options);
        }

        //raise a document afterSave Event
        Services.event.triggerEvent("afterSave", options);

    },
    
    retrieveDocumentFilters: (options = {}) => {
        console.log('retrieveDocumentFilters options',options);
        let filters = "active=true";

        //route filter for include
        if(options.routes && options.routes.service && options.routes.service.include){
            filters = filters + "&" + options.routes.service.include;
        }else if(options.listSettings && options.listSettings.routes && options.listSettings.routes.service && options.listSettings.routes.service.include){
            filters = filters + "&" + options.listSettings.routes.service.include;
        }
        //route filter for additional conditions
        if(options.filters){
            filters +=  "&" + options.filters
        }
        //pagination
        if(options.pagination){
            if(!options.pagination.disabled){
                filters += "&page=" + options.pagination.page + "&per_page=" + options.pagination.limit
            }
        }else if(options.listSettings && options.listSettings.view && options.listSettings.view.pagination){
            filters += "&page=0&per_page=" + (options.listSettings.view.pagination.pageSize || 10);
        }
        //list finder
        if(options.finder &&  options.listSettings 
            && options.listSettings.view && options.listSettings.view.search && options.listSettings.view.search.attributes){
                if(options.finder.query){
                    let finderQuery = []
                    for(const [index, attr] of options.listSettings.view.search.attributes.entries() ){
                        finderQuery.push( attr + "[ilike]=" +  options.finder.query );
                    }
                    filters += "&" + finderQuery.join("&");
                }
                if(options.finder.custom){
                    filters += "&" + options.finder.custom;
                }
                if(options.finder.order){
                    filters += "&filter[order]=" + options.finder.order;
                }
        }
        //order by
        if(options.listSettings && options.listSettings.routes && options.listSettings.routes.service 
            && options.listSettings.routes.service.order && !filters.includes("filter[order]")){
            filters += "&" + options.listSettings.routes.service.order
        }

        if(options.documentType && PolicyProviders[options.documentType]){
            let f = PolicyProviders[options.documentType].retrieveDocumentFilters(options);
            if(f){
                filters = filters + "&" + f;
            }
        }
        return filters;
    },

    validate: (documentData, options={}) => {
        //let details = {error: {message: "Unable to save document as of this time."}};
        let validations = {success: true, details: {}}, errors = [], messages = {}
        if(documentData && options && options.documentType && options.validators){
            //validate main document
            if(options.validators[options.documentType]){

                let validateFields = options.validateAttributes || Object.keys(options.validators[options.documentType]);

                for(let attr of validateFields){
                    let rules = options.validators[options.documentType][attr];
                    for(let rule of rules){
                        //set validator params
                        let params = [documentData[attr]]
                        if(rule.regexp){
                            params.push(rule.regexp)
                        }
                        if(rule.max){
                            params.push(rule.max)
                        }
                        if(rule.min){
                            params.push(rule.min)
                        }
                        if(rule.length){
                            params.push(rule.length)
                        }
                        if(rule.fileTypes){
                            params.push(rule.fileTypes)
                        }

                        let validator = Validations['trim'];
                        if(rule.validator instanceof Function){
                            validator = rule.validator;
                        }else{
                            validator = Validations[rule.validator || 'trim'];
                        }
                        
                        //console.log('document.service validate rule', attr, rule, validator);
                        let isValid = validator(...params)
                        if(!isValid){
                            let message = "Validation Error for " + inflect(attr).pascal.toString() + ": " + documentData[attr];
                            if(Validations.defaultMessages[rule.validator]){
                                message = (Validations.defaultMessages[rule.validator]).replace('{attribute}', inflect(attr).pascal.toString())
                            }
                            errors.push(message)
                            messages[attr] = message
                        }
                    }
                }
            }

            //validate related:hasMany
            if(options.validators['hasMany']){
                let relatedValidations = []
                for(let related of options.validators['hasMany']){
                    if(documentData[related]){
                        for(let m of documentData[related]){
                            if(Services[related.toLowerCase()] && Services[related.toLowerCase()]['validators']){
                                //console.log('document.service validate hasMany', related)
                                let itemValidation = document.validate(m, {
                                    documentType: related.toLowerCase(),
                                    validators: (Services[related.toLowerCase()]).validators,
                                })
                                relatedValidations.push(itemValidation)
                            }
                        }
                    }
                }
                for(let related of relatedValidations){
                    if(!related.success && related.details && related.details.error){
                        errors.push( related.details.error.message);
                        messages = Object.assign({}, messages, related.details.error.details.messages )
                    }
                }
            }
        }

        if(errors.length){
            validations = {success: false, details: {
                error: {
                    message: errors.join("; "), 
                    details: { messages: messages } 
                }
            }}
        }
        return validations;
    },

    autocomplete: (query, options={}) => {
        //build filter based on autocomplete query 
        let filter = "1";
        if(query && options.documentType){
            let settings = Settings[options.documentType + "List"];
            if(settings && settings.view && settings.view.search && settings.view.search.attributes){
                let i=0;
                for(let attribute of settings.view.search.attributes){
                    filter += "&" + attribute + "[ilike]=" + query;
                }
            }
        }
        //console.log('document.service autocomplete ', filter);
        return document.retrieveList(null, Object.assign({}, options, {
                filters: filter,
                pagination: {
                    disabled: true
                }
            }))
            .then((res) => {
                let suggestions = []
                if(options.suggestions){
                    suggestions = options.suggestions(res.data)
                }
                console.log('document.service autocomplete suggestions', suggestions)
                return {suggestions};
            })
            .catch((err) => {
                console.log(err);
            });
    },

    task: {
        dispatch: (task) => {
            return fetch(process.env.REACT_APP_TASK_MANAGER_BASE_URL + "/task-dispatcher", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    task: task
                })
            })
        }
    }

}