import React, { PureComponent } from "react";
import {
    Label,
    Modal,
    Row,
    Col,
    FormGroup, ControlLabel    
} from "react-bootstrap";

import  FileInput  from "./../../common/components/FileInput.jsx";
import * as ContentManager from 'contents';

class PictureUploader extends PureComponent {
    
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleShow() {
        console.log("handleShow",this.state);
        this.setState({ showModal: true });
    }    

    handleClose() {
        console.log("handleClose",this.state);
        this.setState({ showModal: false });
    }    
    
    render(){

        let {source, title} = this.props;
        source = JSON.parse( source || "{}" );

        return(
            <div>
                <Modal show={this.state.showModal} onHide={this.handleClose} bsSize="medium" aria-labelledby="contained-modal-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>                
                    <Modal.Body>

                        <FormGroup>
                            <Row>
                                <Col md={2}></Col>
                                <Col md={8}>
                                    <div style={{border: 1 + "px solid #E3E3E3", padding: 15 + "px"}}>
                                        <FileInput
                                            accept="image/png, image/gif"
                                            className='dropzone'
                                            activeClassName='active-dropzone'
                                            multiple={false}
                                            filesdir={this.props.filesdir}
                                            initialPreview={[this.props.initialPreview]}
                                            name = "img"
                                            tag = "profile"
                                            contentManager = {ContentManager.userprofile}
                                            handleChange = {this.props.handleChange}
                                            imageHeight = {300 + "px"}
                                        >
                                        </FileInput>
                                    </div>
                                </Col>
                            </Row>
                        </FormGroup>

                    </Modal.Body>
                </Modal>
                <a onClick={this.handleShow}><Label bsStyle="info" >{title}</Label></a>
            </div> 
        )       
    }
    
}

export default PictureUploader;