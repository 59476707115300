import React from 'react';
import { connect } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import Button from "common/components/CustomButton.jsx";
import { Link } from "react-router-dom";
import * as userActions  from '../actions/auth.action';
import * as Services from "services";
import * as Settings from "settings";
import url from "../../utils/url"

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        this.props.logout();

        this.state = {
            email: '',
            submitting: false,
            submitted: false,
            success: false,
            error: false,
            message: '',
            basePath: '/',
            code: '',
            return: '',
            password: '',
            changed: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }

    componentDidMount(){

        let code = "", ret = "";
        if(this.props.location && this.props.location.search){
            let pageParams = url.urlSearchParams(this.props.location.search);
            code = pageParams.code || "";
            ret = pageParams.code || "";
        }

        this.setState(state=>{
            return Object.assign({}, state, {
                basePath: window.location.origin,
                code: code,
                return: ret
            })
        })
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    forgotPassword(e) {
        e.preventDefault();
        
        this.setState(state=>{
            return Object.assign({}, state, {
                submitting: true,
                submitted: false
            })
        });
        const { email } = this.state;
        
        if (email) {
            Services.user.resetPassword({
                confirmationLink: this.state.basePath + "/reset-password",
                returnLink: this.state.basePath + "/reset-password",
                email: email
            })
            .then(res=>{
                this.setState(state=>{
                    return Object.assign({}, state, {
                        submitting: false,
                        submitted: true,
                        error: false,
                        success: true
                    })
                });
                for(let task of Settings.extendeduser.tasks.resetPassword.notification.dispatchers){
                    Services.user.task.dispatch(task)
                    .then(res=>{
                        console.log('Reset Password  task dispatcher', res)
                    })       
                    .catch(err=>{
                        console.log('Reset Password  task dispatcher', err)
                    })   
                }
            })
            .catch(e=>{
                this.setState(state=>{
                    return Object.assign({}, state, {
                        submitting: false,
                        submitted: true,
                        error: true,
                        message: e.error ? e.error.message : e.message 
                    })
                });
            })
        }else{
            this.setState(state=>{
                return Object.assign({}, state, {
                    submitting: false,
                    submitted: true
                })
            });
        }
    }

    changePassword(e){
        const { password, code } = this.state; 
        if(password && password.length > 5){
            Services.user.changePassword({
                returnLink: this.state.basePath + "/reset-password?status=password-changed",
                code: code,
                password: password
            })
            .then(res=>{
                this.setState(state=>{
                    return Object.assign({}, state, {
                        submitting: false,
                        submitted: true,
                        changed: true,
                        error: false
                    })
                });
                for(let task of Settings.extendeduser.tasks.resetPassword.notification.dispatchers){
                    Services.user.task.dispatch(task)
                    .then(res=>{
                        console.log('Reset Password  task dispatcher', res)
                    })       
                    .catch(err=>{
                        console.log('Reset Password  task dispatcher', err)
                    })   
                }
            })
            .catch(e=>{
                console.log(e);
                this.setState(state=>{
                    return Object.assign({}, state, {
                        submitting: false,
                        submitted: true,
                        error: true,
                        message: e.error ? e.error.message : e.message 
                    })
                });
            })
        }else{
            console.log('Invalid Password');
            this.setState(state=>{
                return Object.assign({}, state, {
                    submitting: false,
                    submitted: true,
                    code: 'xxx-xxx'
                })
            });
        }
    }

    render() {
        console.log('ForgotPassword render', this.state);    
        const { email, password, submitting, submitted, error } = this.state;

        return (            
            <div className="col-md-4 col-md-offset-4">
                
                <form name="form" >

                    {
                        !this.state.code && !this.state.success ?
                        <React.Fragment>
                            <div className={'form-group' + (submitted && (!email || error) ? ' has-error' : '')}>
                                <label htmlFor="email">Enter your email to change your password</label>
                                <input type="text" className="form-control" name="email" value={email} onChange={this.handleChange} />
                                {submitted && !email &&
                                    <div className="help-block">Email is required</div>
                                }
                                {submitted && error &&
                                    <div className="help-block">Unable to process password change. {this.state.message}</div>
                                }
                            </div>
                            <div className="form-group">
                                <Button className="btn btn-primary" style={{width:120+"px"}} onClick={this.forgotPassword}>
                                    {!submitting ?
                                        "Submit":""
                                    }
                                    <BeatLoader
                                        sizeUnit={"px"}
                                        size={16}
                                        color={'#e3e3e5'}
                                        loading={submitting}
                                    />
                                </Button>  
                                <div style={{display:"inline", float:"right"}}>
                                    <Link to={"/login"}>Login</Link>
                                </div>
                            </div>
                        </React.Fragment> :
                        this.state.success && !this.state.code ?
                        <React.Fragment>
                            <div className="form-group">
                                <p style={{textAlign:"center"}}>
                                    We have sent an email to: {this.state.email}
                                </p>
                                <p style={{textAlign:"center"}}>
                                    Please follow instructions to change your password.
                                </p>
                            </div>
                            <div className="form-group">
                                <p style={{textAlign:"center"}}>
                                    <Link to={"/login"}>Login</Link>
                                </p>
                            </div>
                        </React.Fragment> :
                        this.state.code && !this.state.changed ? 
                        <React.Fragment>
                            <div className={'form-group' + (submitted && (!password || error) ? ' has-error' : '')}>
                                <label htmlFor="password">Enter New Password</label>
                                <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                                {submitted && !password &&
                                    <div className="help-block">Password is required</div>
                                }
                                {submitted && error &&
                                    <div className="help-block">Unable to process password change. {this.state.message}</div>
                                }
                            </div>
                            <div className="form-group">
                                <Button className="btn btn-primary" style={{width:120+"px"}} onClick={this.changePassword}>
                                    {!submitting ?
                                        "Submit":""
                                    }
                                    <BeatLoader
                                        sizeUnit={"px"}
                                        size={16}
                                        color={'#e3e3e5'}
                                        loading={submitting}
                                    />
                                </Button>  
                                <div style={{display:"inline", float:"right"}}>
                                    <Link to={"/login"}>Login</Link>
                                </div>
                            </div>
                        </React.Fragment> : 
                        this.state.code && this.state.changed ?
                        <React.Fragment>
                            <div className="form-group">
                                <p style={{textAlign:"center"}}>
                                    You have successfully changed your password.
                                </p>
                            </div>
                            <div className="form-group">
                                <p style={{textAlign:"center"}}>
                                    <Link to={"/login"}>Login</Link>
                                </p>
                            </div>
                        </React.Fragment> : 
                        <React.Fragment>"EMPTY"</React.Fragment>
                    }
                    
                </form>
            </div>
        );
    }
}


const mapActionsToProps = {
    ...userActions
};

export default connect(null, mapActionsToProps)(ResetPassword)