import React, { Component } from "react";

import ResetPassword from "../../authentication/components/ResetPassword";
import * as Settings from "settings";

class Password extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
        <div id="main-panel" className="" ref="mainPanel">
            <div style={{
				height: 200+"px", 
				backgroundSize: "100%",
                backgroundImage:"url('https://vaycay365-live.s3.us-east-2.amazonaws.com/app/public/images/banner.jpg')",
                backgroundPosition: "0 70%"
            }}>
			</div>          
			<div style={{textAlign:"center"}}>
				<img width="250" height="65" src="https://vaycay365-live.s3.us-east-2.amazonaws.com/app/public/images/logo.png" 
                class="custom-logo" itemprop="logo" style={{margin: 20+"px"}}></img>
			</div>
            <ResetPassword {...this.props}/>
            <div className="form-group col-md-12">
				<p className="copyright" style={{textAlign:"center", marginTop:30+"px"}}>
					<a href="#">{Settings.app.copyright}</a>
					&copy; {new Date().getFullYear()}{" "}
				</p>
            </div>            
        </div>
    );
  }
}

export default Password;
