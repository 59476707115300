/**
 * User
 */
export const userList = {
    documentType: "user",
    routes: {
        local: {
            path: "/users",
            config: "userList"
        }
    }   
}
export const user = {
    documentType: "user",
    routes: {
        local: {
            path: "/users",
            config: "user"
        }
    }   
}

/**
 * Extended User
 */
export const extendeduserList = {
    documentType: "extendeduser",
    routes: {
        local: {
            path: "/extendedusers",
            config: "extendeduserList"
        }
    }   
}
export const extendeduser = {
    documentType: "extendeduser",
    routes: {
        local: {
            path: "/extendedusers",
            config: "extendeduser"
        }
    }   
}

/**
 * User Profile
 */
export const userprofile = {
    documentType: "userprofile",
    routes: {
        local: {
            path: "/userprofiles",
            config: "userprofile"
        }
    }   
}

/**
 * Access Control List
 */
export const roleList = {
    documentType: "role",
    routes: {
        local: {
            path: "/roles",
            config: "roleList"
        }
    }   
}
export const role = {
    documentType: "role",
    routes: {
        local: {
            path: "/roles",
            config: "role"
        }
    }   
}
export const extendedroleList = {
    documentType: "extendedrole",
    routes: {
        local: {
            path: "/extendedroles",
            config: "extendedroleList"
        }
    }   
}
export const extendedrole = {
    documentType: "extendedrole",
    routes: {
        local: {
            path: "/extendedroles",
            config: "extendedrole"
        }
    }   
}

export const acl = {
    documentType: "acl",
    routes: {
        local: {
            path: "/acls",
            config: "acl"
        }
    },
    acl: {
        read: ['find','findById','exists','createChangeStream','count','findOne'],
        write: ['upsert','create','updateAttributes','deleteById','replaceById','replaceOrCreate','updateAll','upsertWithWhere'],
        execute: []
    }   
}