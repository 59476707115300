import React, {Suspense} from "react";

import {fallback} from "common/containers/fallback.jsx";
import Document from "common/components/Document.jsx";

export const withDocument = (Component, config) => {
    return class withDocument extends React.Component{
        render(){
            return(
                <Suspense fallback={fallback()}>
                    <Document document={Component} config={config} {...this.props}/>
                </Suspense>
            )
        }
    }    
}