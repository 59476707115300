import Api from 'utils/Http'
import fetch from 'node-fetch'

import moment from "moment";

export const userprofile = {

    pushContents: (content, key, options={}, cb) => {

        let {name, type} = content;
        console.log(name, type);
        
        let [fname, ext] = name.split(".");
        fname = key + "_" + moment().format('YYYYMMDDHHmmss') + "_" + 
                Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10) + "-" + fname;

        Api.post("/userprofiles/s3/signedrequest",{
            fileName : 'profile/' + (options.filesdir || 'PROFILE-TMPDIR' ) + '/image/' + fname + "." + ext,
            fileType : type
        }).then((res) => {
            let signedRequest = res.data.signedRequest;

            fetch(signedRequest.signedRequest, {
                method: 'PUT',
                headers: {
                    'Content-Type': type,
                    'x-amz-acl': 'public-read'
                },
                body: content
            }).then((res) => {
                
                if(cb){
                    cb(null, Object.assign({}, res, signedRequest));
                }

            }).catch((err) => {
                console.log(err);

                if(cb){
                    cb(err, null);
                }

                return err.response
            })

        })
        .catch((err) => {
            console.log(err);
            return err.response
        })

        return "";

    }

}