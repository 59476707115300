import Dashboard from "dashboard/views/Dashboard";
import UserProfile from "user/views/UserProfile";
import Finder from "finder/components/Finder.jsx";

const dashboardRoutes = [
	{
		path: "/dashboard",
		name: "Dashboard",
		icon: "pe-7s-graph",
		component: Dashboard,
		defaultRoles: ["everyone"]
	},
	{
		path: "/user",
		name: "User Profile",
		icon: "pe-7s-user",
		component: UserProfile,
		defaultRoles: ["everyone"]
    },
	{
		path: "/patients",
		name: "List of Patients",
		icon: "pe-7s-note2",
		defaultRoles: ["admin"]
    },
    { redirect: true, path: "/", to: "/dashboard", name: "Dashboard", defaultRoles: ["everyone"] }
];

export default dashboardRoutes;