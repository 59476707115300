import React, { Component } from "react"
import { Table } from "react-bootstrap"
import * as Services from "services"
import PatientCalendar from "dental/patient/components/PatientCalendar.jsx"
import { Card } from "common/components/Card.jsx";
import { DatePicker } from "common/components/DatePicker.jsx";
import { InputDropdown } from "common/components/InputDropdown.jsx";
import moment from "moment";


class PatientCalendarWidget extends Component {
	
	constructor(props) {            
        super(props);
        this.state = {
			events: []
		}
    }

    componentDidMount () {
		this.retrieveEventList()
	}
	
	handleChange = (e) => {
        const { name, value } = e.target
        
        this.setState((state) => {
			return Object.assign({}, state, {[name]:value});				
		}, () => {
			console.log('state ni: ', this.state)
			// We need to run this function after a state change
			this.retrieveEventList()
		})	
	}
	
	/**
     * Retrieves the employee list
     */
    retrieveEventList = () => {
		// Retrieve employee list and assign it to the state
		Services.patient.retrievePatientSchedules(null, {			
        })
		.then((res) => {
			console.log('PatientCalendar retrieveEventList', res)
			let events = []
			for(let event of res.data){
				let utc = moment(event.schedule_at).utc();
				console.log('PatientCalendarWidget ', event.schedule_at, utc.utcOffset(-5).local().format("YYYY-MM-DD HH:mm:ss"))
				let start = new Date(moment(utc).local().format("YYYY-MM-DD HH:mm:ss"))				
				events.push(Object.assign({}, event, {
					patient_id: event.patient_id,
					start: start,
					end: new Date(moment(utc).add({minutes:30}).local().format("YYYY-MM-DD HH:mm:ss")),
					allDay: false
				}))
			}
			console.log('PatientCalendarWidget events', events)
			this.setState((state) => {
				return Object.assign({}, state, 
				{
					events: events
				});				
			});
		});
	}
		

	render() {	

        return (			
            <Card
                statsIcon="fa fa-history"
                id="patientSchedule"
                title="Patient Schedule"
                category="View and Create Schedule by Month, Week or Day"
                stats="Updated 3 minutes ago"
                content={
					<div>
						<div className="row">

							<PatientCalendar events={this.state.events}/>

						</div>			
					</div>					
				}   
				
            />
		);
	}
}

export default PatientCalendarWidget;
