import React, { Component } from "react";
import { connect } from 'react-redux';
import { Grid, Row, Col } from "react-bootstrap";

import AdminDashboard from "dashboard/views/AdminDashboard.jsx";
import PatientDashboard from "dashboard/views/PatientDashboard.jsx";

import url from "./../../utils/url";
import history from './../../history';

class Dashboard extends Component {

    constructor(props){
        super(props);
    }

	componentDidMount(){  
        console.log('Dashboard componentDidMount', this.props.location)
        if(this.props.location && this.props.location.search){
            let pageParams = url.urlSearchParams(this.props.location.search);
            let next = pageParams.next || null;
            if(next){
                history.push(next);
            }
        }
    }

    render() {
        let { account } = this.props
        return (
            <div className="content">
                {
                    account && account.user.roles_mask == "admin" ? 
                    <AdminDashboard />
                    : <PatientDashboard />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => (
    {
        account: state.account
    }
)

export default connect(mapStateToProps)(Dashboard);