import React, { Component } from "react";

export class CustomCheckbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_checked: false
        };
        this.handleClick = this.handleClick.bind(this);
    }
    componentDidMount(){
        this.setState(state=>{
            return {
                is_checked: this.props.isChecked ? true: false
            }
        })        
    }
    componentWillReceiveProps(props){
        this.setState(state=>{
            return {
                is_checked: props.value ? true: false
            }
        })
    }
    handleClick(e) {
        console.log('CustomCheckbox props', this.props);
		this.props.onChange({
			target: {
				name: this.props.name,
                value: !this.state.is_checked,
                getAttribute: (prop) => {
                    return this.props[prop];
                } 
			}
        });
        console.log(this.props.name, !this.state.is_checked);
        this.setState({ is_checked: !this.state.is_checked });
    }
    render() {
        const { key, name, isChecked, number, label, inline, ...rest } = this.props;
        const classes =
            inline !== undefined ? "checkbox checkbox-inline" : "checkbox";
        return (
            <div className={classes}>
            <input
                id={number}
                key={key}
                name={name}
                type="checkbox"
                onClick={this.handleClick}
                checked={this.state.is_checked}
                {...rest}
            />
            <label htmlFor={number} onClick={this.handleClick}>{label}</label>
            </div>
        );
    }
}

export default CustomCheckbox;
