import React, { PureComponent } from "react";

import {
    Modal,
    Table,
    FormGroup,
    ControlLabel,
    FormControl,
} from "react-bootstrap";

import { Autocomplete } from "common/components/Autocomplete.jsx";
import Button from "common/components/CustomButton.jsx";
import { FormInputs } from "common/components/FormInputs.jsx";
import { DatePicker } from "common/components/DatePicker.jsx";
import { TimePickerComponent } from "common/components/TimePicker.jsx";
import { Calendar,momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import * as Services from "services"
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment)

class PatientCalendar extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            events: [],
            patients: [],
            showModal: false,
            selectedEvent: {
                id: 0,
                title: "",
                patient_name: "",
                patient_id: 0,
                schedule_at: new moment().format('YYYY-MM-DD'),
                time_start: new moment().format("HH:mm:ss"),
                description: ""
            }
        }
        this.handleEventChange = this.handleEventChange.bind(this)
    }

    componentDidMount() {

        Services.patient.retrieveList(null, {
            pagination: { disabled: true }
        })
        .then((res) => {
            this.setState((state) => {
                return Object.assign({}, this.state, 
                {
                    patients: res.data
                });
            });
        });

        this.setState((state)=>{
            return Object.assign({}, state, {
                events:  []
            })
        })
    }

    componentWillReceiveProps(props){
        this.setState((state)=>{
            return Object.assign({}, state, {
                events: props.events || Array.isArray(props.events) ? props.events: []
            })
        })
    }

    handlePatientAutocompleteSelection(selected, autocompleteProps){
        console.log('PatientCalendar selection', selected, autocompleteProps);
        if(selected){
            /*
            let { employeeBenefit } = this.props;
            let employeeBenefits = [...employeeBenefit];
            let rowId = autocompleteProps['data-rowId'];
            employeeBenefits[rowId]["referenceId"] = selected.id;
            employeeBenefits[rowId]["referenceType"] = selected.referenceType;
            employeeBenefits[rowId]["name"] = selected.name;
            employeeBenefits[rowId]["description"] = selected.description;
            employeeBenefits[rowId]["itemType"] = selected.itemType;
            this.props.handleDocumentChange(this.props.documentSettings, { data: {
                employeeBenefit: employeeBenefits
            }});
            */
        }
    }

    handleSelect = (event) => {
        console.log('PatientCalendar handleSelect event', event)

        let selectedEvent = Object.assign({}, this.state.selectedEvent)
        selectedEvent.title = event.title
        selectedEvent.description = event.description
        selectedEvent.schedule_at = event.start.toISOString()
        selectedEvent.time_start = moment(event.start.toISOString()).format("HH:mm:ss")
        if(event.title){
            selectedEvent.id = event.id
            selectedEvent.patient_id = event.patient_id
            selectedEvent.patient_name = event.patient_name             
        }else{
            selectedEvent.id = 0
            selectedEvent.patient_id = 0
            selectedEvent.patient_name = ""
        }
        console.log('PatientCalendar handleSelect selectedEvent', selectedEvent)
        this.setState((state)=>{
            return Object.assign({}, state, {
                showModal: true,
                selectedEvent: selectedEvent
            })
        })
    }

    handleClose = () => {
        this.setState((state)=>{
            return Object.assign({}, state, {
                showModal: false
            })
        })
    }

    handleEventChange = (e) => {
        const { name, value } = e.target;
        let val = value

        console.log('PatientCalendar handleEventChange', name, val)        

        let title = this.state.selectedEvent.title
        if(name=="patient_name"){
            title = val
        }

        let selectedPatient = null
        if(name=="patient_id"){
            let patients = this.state.patients.filter(patient=>patient.id==val)
            if(patients && Array.isArray(patients)){
                selectedPatient=patients[0]
            }
        }
        console.log('PatientCalendar monitor state handleEventChange', name, val)

        this.setState((state)=>{
            return Object.assign({}, state, {
                selectedEvent: Object.assign({}, state.selectedEvent, { 
                    [name]: val, title: title
                }, 
                selectedPatient ? {
                    patient_id: selectedPatient.id,
                    patient_name: selectedPatient.last_name + ", " + selectedPatient.first_name,
                    title: selectedPatient.last_name + ", " + selectedPatient.first_name
                }: {}
                )
            })
        })
    }

    handleSaveSchedule = (e) => { 
        
        if(this.state.selectedEvent.id==0 && this.state.selectedEvent.title){
            let start = moment(this.state.selectedEvent.schedule_at).format("YYYY-MM-DD") + " " + this.state.selectedEvent.time_start
            let end = moment(start).add({minutes:30}).format("YYYY-MM-DD HH:mm:ss")
            this.setState((state)=>{
                return Object.assign({}, state, {
                    events: [...state.events, Object.assign({}, this.state.selectedEvent, {
                        id: 1,
                        start: new Date(start),
                        end: new Date(end)
                    })]
                })
            }, ()=>{
                let schedule = moment(this.state.selectedEvent.schedule_at).format("YYYY-MM-DD") + " " + this.state.selectedEvent.time_start
                Services.patient.savePatientSchedule(null, {
                    patient_schedule: {
                        patient_id: this.state.selectedEvent.patient_id,
                        schedule_at: moment(schedule).utc().format("YYYY-MM-DD HH:mm:ss"),
                        title: this.state.selectedEvent.title,
                        description: this.state.selectedEvent.description
                    }
                })
            })
        }

        this.handleClose();   
    }

    handleRemoveSchedule = (e) => {  
        Services.patient.removePatientSchedule(null, this.state.selectedEvent)
        .then(res=>{
            let events = this.state.events
            events = events.filter(event=>event.id != this.state.selectedEvent.id)    
            this.setState(state=>{
                return Object.assign({}, state, {
                    events: events
                })
            })
        })
        this.handleClose();        
    }

    render() {
        
        console.log('PatientCalender monitor state', this.state)

        return (
            <div>
                <div className="row">
                    <div className="col-lg-12" style={{height:700+"px", padding:30+"px"}}>
                        <Calendar 
                            selectable
                            localizer={localizer}
                            events={ this.state.events }
                            startAccessor="start"
                            endAccesso="end"
                            onSelectEvent={this.handleSelect}
                            onSelectSlot={this.handleSelect}
                        />
                    </div>
                </div>


                <Modal show={this.state.showModal} onHide={this.handleClose} bsSize="large" aria-labelledby="contained-modal-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{"Patient Schedule"}</Modal.Title>
                    </Modal.Header>                
                    <Modal.Body style={{padding:0}}>
                    <Table striped hover style={{borderTop: 1 + "px solid #e3e3e5", borderBottom: 1 + "px solid #e3e3e5"}}>
                        <thead>
                        <tr>
                            <th style={{width:"100%"}}>Schedule</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr key={0}>
                                <td>
                                    <div className="row">
                                        <div className="col-lg-12">
                                        <FormGroup>
                                            <ControlLabel>Patient Name</ControlLabel>
                                            <FormControl
                                                componentClass="select"
                                                id = {"sex"}
                                                bsClass= {"form-control"}
                                                name= {"patient_id"}
                                                value={this.state.selectedEvent.patient_id}
                                                onChange = {this.handleEventChange}
                                            >
                                                { (function(patientList){
                                                    let patients = [];
                                                    for (let patient of patientList) {
                                                        patients.push(
                                                            <option key={patient.id} value={patient.id}>
                                                                {patient.patient_profile ? patient.patient_profile.last_name + ", " + patient.patient_profile.first_name: patient.last_name + ", " + patient.first_name}
                                                            </option>
                                                        );
                                                    }
                                                    return patients;
                                                })(this.state.patients) }
                                            </FormControl>
                                        </FormGroup>
                                        </div>
                                    </div>                                                                        
                                    <div className="row">
                                        <div className="col-lg-12">
                                        <DatePicker
                                            ncols={["col-md-3"]}
                                            proprieties={[
                                            {
                                                label: "Date of Visit",
                                                type: "text",
                                                bsClass: "form-control",
                                                placeholder: "",
                                                value: this.state.selectedEvent.schedule_at ? moment(this.state.selectedEvent.schedule_at).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
                                                name: "schedule_at",
                                                onChange: this.handleEventChange
                                            }
                                            ]}
                                        />                                        
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <TimePickerComponent
                                                label={"Time"}
                                                value = {this.state.selectedEvent.time_start ? this.state.selectedEvent.time_start : moment().format('HH:mm:ss')}
                                                name = {"time_start"}
                                                onChange ={this.handleEventChange}
                                            />       
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <FormGroup controlId="formControlsTextarea">
                                                <ControlLabel>Description</ControlLabel>
                                                <FormControl
                                                rows="5"
                                                componentClass="textarea"
                                                bsClass="form-control"
                                                placeholder="Description"
                                                name="description"
                                                value={this.state.selectedEvent.description}
                                                onChange={this.handleTimeChange}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                </td>
                            </tr>                                      
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <div className="row">
                                        <div className="col-lg-12" style={{float:"right"}}>
                                            {
                                            this.state.selectedEvent.id < 1 ?
                                            <Button bsStyle="primary" fill className="btn-fill " 
                                                style={{width: 150 + "px"}} onClick={this.handleSaveSchedule}
                                                >
                                                Save
                                            </Button>
                                            : ""
                                            }
                                            <Button bsStyle="default" fill className="btn-fill " 
                                                style={{width: 150 + "px", marginLeft: 5+"px"}} onClick={this.handleRemoveSchedule}
                                                >
                                                Remove
                                            </Button>
                                            <Button bsStyle="default" fill className="btn-fill " 
                                                style={{width: 150 + "px", marginLeft: 5+"px"}} onClick={this.handleClose}
                                                >
                                                Close
                                            </Button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                    </Modal.Body>
                </Modal>

            </div>
        )   
        
    }
}


export default PatientCalendar;