/**
 * ACCOUNT DATA REDUCER
 */

const defaultAuthState = {
	authenticating: false,
	success: false,
	fail: false,
	formError: '',
	user: null,
    authToken: null,
    registering: false,
    registered: false,
    registrationFail: false
}
export const auth = (state = defaultAuthState, action) => {
	switch (action.type) {
		case 'AUTH_USER_AUTHENTICATING':
			return {
				...state,
				authenticating: action.authenticating,
			}
		case 'AUTH_USER_AUTHENTICATION_SUCCESS':
			return {
				...state,
				success: action.success,
			}			
		case 'AUTH_USER_AUTHENTICATION_FAIL':
			return {
				...state,
				fail: action.fail,
			}						
		case 'AUTH_LOGIN_FORM_ERROR':
			return {
				...state,
				formError: action.formError,
			}
		case 'AUTH_USER_SET_ACCESS_TOKEN':
            return {
                ...state,
                authToken: action.authToken,
			}	
		case 'AUTH_SET_USER_DATA':
            return {
                ...state,
                user: action.user,
            }					
		case 'AUTH_LOGOUT':
            return state
        case 'AUTH_USER_REGISTERING':
			return {
				...state,
                registering: action.registering
			}
		case 'AUTH_USER_REGISTERED':
			return {
				...state,
				registered: action.registered,
            }
            case 'AUTH_USER_REGISTRATION_FAIL':
			return {
				...state,
				registrationFail: action.registrationFail,
			}			
		default:
			return state
	}
}