/**
 * Get URL params
 * @return object
 */
const url = {
    urlSearchParams: (params) => {

        let pageParams = {}
        let urlParams = new URLSearchParams(params);
        for(const param of urlParams.entries()){
            pageParams[param[0]] = param[1];
        }
        return pageParams;
        
    }
}

export default url;