import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as Settings from "settings";
import * as Services from "services";

class RegistrationWelcome extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    /*
    for(let task of Settings.extendeduser.tasks.registration.notification.dispatchers){
        Services.user.task.dispatch(task)
        .then(res=>{
            console.log('Registration handleUserRegistered task dispatcher', res)
        })       
        .catch(err=>{
            console.log('Registration handleUserRegistered task dispatcher', err)
        })   
    }*/
  }

  render() {
    return (
        <div id="main-panel" className="" ref="mainPanel">
            <div style={{
				height: 200+"px", 
				backgroundSize: "100%",
                backgroundImage:"url('https://vaycay365-live.s3.us-east-2.amazonaws.com/app/public/images/banner.jpg')",
                backgroundPosition: "0 70%"
            }}>
			</div>          
			<div style={{textAlign:"center"}}>
				<img width="250" height="65" src="https://starship-live.s3.us-east-2.amazonaws.com/starship.png" 
                class="custom-logo" itemprop="logo" style={{margin: 20+"px"}}></img>
			</div>
            <div className="col-md-4 col-md-offset-4" style={{textAlign:"center"}}>
                <h3>Thank you for registering with us.</h3>
                <p>We'll soon get in touch with you.</p>
                <p>Please confirm your registration using the email we sent you together with a verification link.</p>
            </div>
            <div className="col-md-4 col-md-offset-4" style={{textAlign:"center", marginTop:15+"px"}}>
                        <p style={{textAlign:"right"}}>
                            <Link to={"/login"}>Login</Link>
                        </p>
                    </div>
            <div className="form-group col-md-12">
				<p className="copyright" style={{textAlign:"center", marginTop:30+"px"}}>
					<a href="#">{Settings.app.copyright}</a>
					&copy; {new Date().getFullYear()}{" "}
				</p>
            </div>            
        </div>
    );
  }
}

export default RegistrationWelcome;