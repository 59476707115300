/**
 * Office List
 */
export const officeList = {
    documentType: "office",
    view: {
        headers: {
            labels: ["Description","Email","Status"],
            attributes: ["name","email",{convertByAttribute: "status"}]
        },
        body: {
            data:[]
        },
        pagination: {
            pageSize: 10
        },
    },
    routes: {
        local: {
            path: "/offices",
            name: "Office",
            icon: "pe-7s-graph",
            config: "officeList"
        }
    }
}
/**
 * Office
 */
export const office = {
    documentType: "office",
    routes: {
        local: {
            path: "/offices",
            config: "office"
        }
    }   
}