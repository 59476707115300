import React, { PureComponent } from "react";
import { connect } from 'react-redux';

import {
    Grid,
    Row,
    Col
} from "react-bootstrap";

import { FormInputs } from "common/components/FormInputs.jsx";


class ACLRoleInformation extends PureComponent {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e){
        console.log('handleChange', e);
        const { name, value } = e.target;
        this.props.handleDocumentChange(this.props.documentSettings, { data: {[name]:value} });
    }

    render() {
        
        let { success, documentData, documentSettings, actionInProgress, error } = this.props;
  
        if(documentData && (success||error))
            return (
                <div className="content">

                    <FormInputs
                        ncols={["col-md-6"]}
                        proprieties={[
                        {
                            label: "Name",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Name",
                            name: "name",
                            value: documentData.name,
                            onChange: this.handleChange
                        }
                        ]}
                    />
                    <FormInputs
                        ncols={["col-md-6"]}
                        proprieties={[
                        {
                            key: "description",
                            label: "Description",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Description",
                            name: "description",
                            value: documentData.description,
                            onChange: this.handleChange
                        }
                        ]}
                    />

                    <div className="clearfix" />            
                </div>
            )

        return (
            <div className="content">
                Unable to retrieve document.
            </div>
        )       
        
    }
}

const mapStateToProps = (state) => ({
    documentData: state.document.documentData,
    error: state.document.error,
    actionInProgress: state.document.actionInProgress,
    success: state.document.success
});

export default connect(mapStateToProps)(ACLRoleInformation);