import React, { Component } from "react";
import { connect } from 'react-redux';
import { Grid, Row, Col } from "react-bootstrap";

import WelcomeWidget from "dental/dashboard/widgets/WelcomeWidget.jsx";
import PatientSharedFilesWidget from "dental/dashboard/widgets/PatientSharedFilesWidget.jsx";

import url from "./../../utils/url";
import history from './../../history';

class PatientDashboard extends Component {

    constructor(props){
        super(props);
    }

	componentDidMount(){  
        console.log('Dashboard componentDidMount', this.props.location)
        if(this.props.location && this.props.location.search){
            let pageParams = url.urlSearchParams(this.props.location.search);
            let next = pageParams.next || null;
            if(next){
                history.push(next);
            }
        }
    }

    render() {
        return (
            <Grid fluid>
                <Row>
                    <Col lg={6} sm={6}>
                        <WelcomeWidget />
                    </Col>                
                </Row>    
                <Row>
                    <Col lg={12} sm={12}>
                        <PatientSharedFilesWidget />
                    </Col>                
                </Row>               
            </Grid>
        );
    }
}

const mapStateToProps = (state) => (
    {
        account: state.account
    }
)

export default connect(mapStateToProps)(PatientDashboard);