import React, { PureComponent } from "react";

import { Link } from "react-router-dom";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    FormControl,
    Table
} from "react-bootstrap";

import Button from "common/components/CustomButton.jsx";
import { BeatLoader } from 'react-spinners';
import * as Settings from "settings";
import * as Services from "services";

class FinderList extends PureComponent {

    constructor(props){
        super(props);

        this.handleSearch = this.handleSearch.bind(this);
        this.state = {
            finder: {
                searchResults: []
            }
        }
    }

    componentWillMount() {
        console.log('Finder Filter.componentDidMount',this.props);
        Services.event.listenEvent("onFinderSearch","FINDER-LIST-" + this.props.documentType, this.handleSearch)
    }

    componentWillUnmount(){
        Services.event.unlistenEvent("onFinderSearch","FINDER-LIST-" + this.props.documentType, this.handleSearch)
    }

    handleSearch(e){
        console.log("Finder List " + this.props.documentType, e);
        if(Settings[this.props.documentType]){
            let filter = "1";
            let settings = Settings[this.props.documentType];
            if(settings && settings.view && settings.view.search && settings.view.search.attributes){
                let i=0;
                for(let attribute of settings.view.search.attributes){
                    filter += "&filter[where][or][" + (i++) + "][" + attribute + "][like]=%" + e.query + "%";
                }
                Services.document.retrieveList(null, Object.assign({}, {
                    documentType: settings.documentType,
                    pagination: { disabled: true }
                }, {
                    filters: filter,
                    finder: {
                        order: "updatedAt DESC"
                    }
                }) )
                .then((res) => {
                    this.setState(state=>{
                        return Object.assign({}, state, {
                            finder: {
                                searchResults: res.data
                            }
                        })
                    })
                })
                .catch((err) => {
                    console.log(err);
                });
            }
        }

    }

    render(){
        let settings = Settings[this.props.documentType];
        console.log("FinderFilter render ", this.props);
        return (
            <div>
            {
            this.state.finder.searchResults && this.state.finder.searchResults.length ?
            <Table striped hover style={{marginTop:5+"px", marginBottom:5+"px", borderTop: 1 + "px solid #e3e3e5", borderBottom: 1 + "px solid #e3e3e5"}}>
                <thead>
                    <tr>
                        <th style={{paddingBottom: 10+"px", backgroundColor: "#efeff7"}}>
                            { Settings[this.props.documentType]["routes"]["local"]["name"] + " Search Results: " + (this.state.finder.searchResults.length > 100 ? 100:this.state.finder.searchResults.length) + " records found." }
                        </th>
                    </tr>
                </thead>
            </Table>
            : ""
            }
            {
            this.state.finder.searchResults && this.state.finder.searchResults.length ?
            <Table striped hover style={{marginTop:5+"px", borderTop: 1 + "px solid #e3e3e5", borderBottom: 1 + "px solid #e3e3e5"}}>
                <thead>
                    <tr>
                        {settings.view.headers.labels.map((prop, key) => {
                            if(settings.view.headers.widths){
                                return <th key={key} style={{width:settings.view.headers.widths[key]}}>{prop}</th>;    
                            }
                            return <th key={key}>{prop}</th>;
                        })}
                    </tr>
                </thead>                
                <tbody>
                    {
                        this.state.finder.searchResults.slice(0,100).map((result, key)=>{
                            let componentId = key;
                            return(
                                <tr>   
                                        {
                                            settings.view.headers.attributes.map((prop, key) => {
                                                if(typeof prop == 'object'){
                                                    if(prop['convertByAttribute'] && Services[settings.documentType]['valueConverter']){                                                                        
                                                        return <td key={prop['convertByAttribute']}>
                                                            <Link to={settings.routes.local.path + "/" + result.id}>
                                                            {Services[settings.documentType]['valueConverter']['convertByAttribute'](result, prop['convertByAttribute'])}
                                                            </Link>
                                                        </td>;
                                                    }
                                                    //componentize attribute
                                                    if(prop['componentize'] && Services[settings.documentType]['ui']){
                                                        return <td key={prop['componentize']}>
                                                            
                                                            {Services[settings.documentType]['ui']['componentize'](result, prop['componentize'], Object.assign({},{...this.props}), {componentId: (result.id || componentId) })}
                                                            
                                                        </td>
                                                    }
                                                }
                                                return <td key={prop}><Link to={settings.routes.local.path + "/" + result.id}>{result[prop]}</Link></td>;
                                            })    
                                        }                                     
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
            : ""
            }
            </div>
        )
    }

}

export default FinderList;