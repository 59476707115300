import * as Services from './../../services';

/**
 * DOCUMENT LIST ACTIONS
 */
export const documentListFetching = (fetching = false) => ({
    type: 'DOCUMENT_LIST_FETCHING',
    fetching,
})

export const documentListFetchSuccess = (success = false) => ({
    type: 'DOCUMENT_LIST_FETCH_SUCCESS',
    success,
})
  
export const documentListFetchFail = (fail = true) => ({
    type: 'DOCUMENT_LIST_FETCH_FAIL',
    fail,
})

export const documentListSetData = (listData = {}, dataProviders = {}) => ({
    type: 'DOCUMENT_LIST_SET_DATA',
    listData
})

export const documentListCrudSetState = (documentListCrud = {}) => ({
    type: 'DOCUMENT_LIST_CRUD_SET_STATE',
    documentListCrud
})

export const retrieveDocumentList = (documentSettings = {}, options = {}) => {

    console.log('documentList.action::retrieveDocumentList', documentSettings)
    //const serviceProvider = Services[documentSettings.documentType];
    const serviceProvider = Services.document;
    return (dispatch, getState) => {
        
        dispatch(documentListFetching(true))
        dispatch(documentListSetData(
            Object.assign({}, getState().documentList.listData, 
            { 
                [documentSettings.documentType + "List" ]: [],
                //[documentSettings.documentType + "ListPagination" ]: pagination,
                //[documentSettings.documentType + "ListFinder" ]: (options.finder || null),
                responseHeaders: null,
                pagination: null,
                finder: (options.finder || null)
            })))
        return serviceProvider.retrieveList(getState(), Object.assign({},{documentType: documentSettings.documentType},options) )
        .then((res) => {
            //check for pagination info
            let pagination = options.pagination || null;
            console.log('documentList.action documentSettings', documentSettings, res)
            if(documentSettings.view && documentSettings.view.pagination && 
                res.headers["x-pagination-total-count"] && res.headers["x-pagination-per-page"] && res.headers["x-pagination-current-page"]){                
                pagination = {
                    totalCount: res.headers["x-pagination-total-count"],
                    pageSize: res.headers["x-pagination-per-page"],
                    currentPage: res.headers["x-pagination-current-page"]
                }
                console.log('documentList.action pagination', pagination)
            }

            let state = getState();
            dispatch(documentListSetData(
            Object.assign({}, state.documentList.listData, 
            { 
                [documentSettings.documentType + "List" ]: res.data,
                [documentSettings.documentType + "ListPagination" ]: pagination,
                [documentSettings.documentType + "ListFinder" ]: (options.finder || null),
                responseHeaders: res.headers,
                pagination: pagination,
                finder: (options.finder || null)
            })))
            dispatch(documentListFetching(false))
            dispatch(documentListFetchSuccess(true))
            dispatch(documentListFetchFail(false))
            
            //call serviceprovider after retrieve handler
            serviceProvider.afterRetrieveList(getState(), {
                getState: getState,
                documentType: documentSettings.documentType, 
                documentSettings: documentSettings,
                dispatch: dispatch,
                handleDocumentListChange: handleDocumentListChange,
                handleDocumentListCrudStateChange: handleDocumentListCrudStateChange
            });

            if(options.next){
                options.next(getState())
            }

            //console.log('documentList response headers',res);
            return res
        })
        .catch((err) => {
            dispatch(documentListFetching(false))
            dispatch(documentListFetchFail(true))
            dispatch(documentListSetData({ [documentSettings.documentType + "List" ]: [] }))
            return err.response
        })
    }
}

export const handleDocumentListChange = (documentSettings = {}, options = {}) => {

    console.log('documentList.action::handleDocumentListChange')
    return (dispatch, getState) => {

        let state = getState();
        if(options.data){   
            let d = Object.assign({}, state.documentList.listData, options.data); 
            dispatch(documentListSetData(d))
        }

    }
}

export const handleDocumentListCrudStateChange = (documentSettings = {}, options = {}) => {

    console.log('documentList.action::handleDocumentListCrudStateChange')
    return (dispatch, getState) => {

        let state = getState();

        if(options.state){
            let d = Object.assign({}, state.documentList.documentListCrud, options.state);    
            dispatch(documentListCrudSetState(d))
        }

    }
}