import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import * as Services from 'services';
import moment from "moment";

class DocumentListInfoGraphics extends Component {

	constructor(props){
		super(props);
		this.state = {      
            active: {}
        }
    }

    componentDidMount(){   
    }

    render() {
        const { pagination } = this.props;
        console.log('DocumentListInfoGraphics', this.props.pagination)

        let pageDescription = pagination && pagination.totalCount && pagination.pageSize ? pagination.currentPage + ' of ' + Math.ceil(pagination.totalCount/pagination.limit) : "-";

        return (
            <React.Fragment>
            <Col md={4} style={{paddingRight:0}}>
                <div className="card card-stats" style={{marginBottom:0}}>
                    <div className="content">
                        <Row>
                            <Col xs={4}>
                            <div className="icon-big text-center">
                                {<i className="pe-7s-display2 text-info" />}
                            </div>
                            </Col>
                            <Col xs={8}>
                            <div className="numbers">
                                <p>Records</p>
                                {this.props.pagination ? this.props.pagination.totalCount: '-'}
                            </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
            <Col md={4} style={{paddingRight:0}}>
                <div className="card card-stats" style={{marginBottom:0}}>
                    <div className="content">
                        <Row>
                            <Col xs={4}>
                            <div className="icon-big text-center">
                                {<i className="pe-7s-less text-info" />}
                            </div>
                            </Col>
                            <Col xs={8}>
                            <div className="numbers">
                                <p>Page</p>
                                <p>{pageDescription}</p>
                            </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
            <Col md={4} style={{paddingRight:0}}>
                <div className="card card-stats" style={{marginBottom:0}}>
                    <div className="content">
                        <Row>
                            <Col xs={4}>
                            <div className="icon-big text-center">
                                {<i className="pe-7s-download text-danger" />}
                            </div>
                            </Col>
                            <Col xs={8}>
                            <div className="numbers">
                                <p>Pdf</p>
                            </div>
                            <div>
                                <a href={"/pdf?type=" + this.props.documentSettings.documentType} target="_blank" className="pull-right">Download</a>
                            </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
            </React.Fragment>
        );
    }
}

export default DocumentListInfoGraphics;