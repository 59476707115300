import React, { PureComponent } from "react";

import {
    MenuItem
} from "react-bootstrap";

class PatientListCrud extends PureComponent {

    constructor(props){
        super(props);
        
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e){
        console.log('PatientListCrud handleClick ', e.target.dataset.key);
    }

    render(){
        return (
            <MenuItem eventKey="printList" data-key="printList" onClick={this.handleClick}>Print List</MenuItem>
        )
    }

}

export default PatientListCrud;