import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { BeatLoader } from 'react-spinners';
import { GoogleLogin } from 'react-google-login';
import * as userActions  from './../actions/auth.action';
import * as Settings from "settings";
import url from "./../../utils/url";

class Login extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        this.props.logout();

        this.state = {
            username: '',
            password: '',
            submitted: false,
            next: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.googleHandler = this.googleHandler.bind(this);

    }

    componentDidMount(){
        console.log('Login componentDidMount ', this.props.location)
        if(this.props.location && this.props.location.search){
            let pageParams = url.urlSearchParams(this.props.location.search);
            let next = pageParams.next || null;
            if(next){
                this.setState(state=>{
                    return Object.assign({}, state, {
                        next: next
                    })
                })
            }
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    loginHandler(e) {
        e.preventDefault();
        
        this.setState({ submitted: true });
        const { username, password } = this.state;
        console.log('Login loginHandler ', this.props.location)
        if (username && password) {
            this.props.login(username, password, {next: this.state.next});
            //dispatch(userActions.login(username, password));
            //dispatch(userActions.authUserAuthenticationSuccess(true));      
        }
    }

    googleHandler(google){
        console.log(google['profileObj']);
        if(google['profileObj']){
            this.setState(state=>{
                return Object.assign({}, state, {
                    username: google['profileObj']['email']
                })
            })
            this.props.google(google);
        }
    }

    render() {
        const { authenticating, success, fail, history } = this.props;
        const { username, password, submitted } = this.state;
        console.log('Login render', this.state);
        return (            
            <div className="col-md-12" >
                
                <h4>Sign In to the Patient Portal</h4>
                <form name="form" onSubmit={this.handleSubmit}>
                    <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                        <label htmlFor="username">Email</label>
                        <input type="text" className="form-control" name="username" value={username} onChange={this.handleChange} />
                        {submitted && !username &&
                            <div className="help-block">Username is required</div>
                        }
                        {fail && username &&
                            <div className="help-block"><p className="text-danger">Invalid User</p></div>
                        }
                    </div>
                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                        <label htmlFor="password">Password</label>
                        <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                        {submitted && !password &&
                            <div className="help-block">Password is required</div>
                        }
                    </div>
                    <div className="form-group">
                        <button id="login" className="btn btn-primary" style={{width:120+"px"}} onClick={e => this.loginHandler(e)}>
                            {!authenticating ?
                                "Login":""
                            }
                            <BeatLoader
                                sizeUnit={"px"}
                                size={16}
                                color={'#e3e3e5'}
                                loading={authenticating}
                            />
                        </button>     

                        <div style={{display:"inline", float:"right"}}>
                            <GoogleLogin
                                clientId={Settings.google.clientId}
                                buttonText="Login with Google"
                                onSuccess={this.googleHandler}
                                onFailure={this.googleHandler}
                                cookiePolicy={'single_host_origin'}
                            />     
                        </div>

                    </div>
                    <div className="form-group">
                        <div style={{display:"inline", float:"left"}}>
                            <Link to={"/reset-password"}>Forgot Password</Link>
                        </div>
                        <div style={{display:"inline", float:"right"}}>
                            <Link to={"/register"}>Register</Link>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
	authenticating: state.auth.authenticating,
	success: state.auth.success,
	fail: state.auth.fail,
	formError: state.auth.formError,
	user: state.auth.user,
	authToken: state.auth.authToken    
});

const mapActionsToProps = {
    ...userActions
};

export default connect(mapStateToProps, mapActionsToProps)(Login)