import {withDocument} from "common/containers/withDocument.jsx";
import ACL from "user/components/ACL.jsx";
import ACLRole from "user/components/ACLRole.jsx"
import ACLUser from "user/components/ACLUser.jsx"

var userRoutes = [
    { path: "/acls", name: "Access Control List", component: ACL },
    { path: "/extendedroles/:id", name: "Roles", component: withDocument(ACLRole, "extendedrole") },
    { path: "/extendedusers/:id", name: "Roles", component: withDocument(ACLUser, "extendeduser") },
    { path: "/users/:id", name: "Roles", component: withDocument(ACLUser, "extendeduser") },
];

export default userRoutes;