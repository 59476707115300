import React, { Component } from "react";
import { connect } from 'react-redux';

import { Link } from "react-router-dom";
import { Grid, Row, Col, Table, ControlLabel } from "react-bootstrap";
import Card from "common/components/Card.jsx";

import DocumentListFinder from "common/components/DocumentListFinder";
import DocumentListCrud from "common/components/DocumentListCrud";
import DocumentListInfoGraphics from "common/components/DocumentListInfoGraphics"

import * as documentListActions from "./../actions/documentList.action";
import * as toastrActions from "common/actions/toastr.action";
import * as Services from 'services';
import * as Settings from "./../../settings";
import url from "./../../utils/url";

import 'tablesaw/dist/tablesaw.css'
import 'tablesaw/dist/tablesaw.js'

import debug from "debug";

class DocumentList extends Component {
    
    documentSettings = {}

    constructor(props) {        
        super(props);    
        debug("DocumentListContainer", props);
        if(props.config && Settings[props.config]){
            this.documentSettings = Settings[props.config];
        }        
    }

    componentDidMount() {
        console.log('DocumentListContainer.componentDidMount',this.props, this.documentSettings);

        //check url params
        let pageParams = {
            pageSize: this.documentSettings.view && this.documentSettings.view.pagination ? 
                this.documentSettings.view.pagination.pageSize: 10,
            page: 1
        };
        if(this.props.location && this.props.location.search){
            pageParams = Object.assign({}, pageParams, url.urlSearchParams(this.props.location.search));
        }
        this.props.retrieveDocumentList(this.documentSettings,{ 
            pagination: {
                pageSize: pageParams.pageSize,
                currentPage: pageParams.page,
                skip:   (pageParams.page - 1) * pageParams.pageSize,
                page: pageParams.page - 1,
                limit:  pageParams.pageSize
            },
            finder: this.props.listData.finder
        });
    }

    tableList() {

        let { fail, fetching, listData } = this.props;

        let pagination = null;
        if(listData && listData["pagination"]){
            pagination = listData["pagination"];
        }

        if(listData[this.documentSettings.documentType + "List"]){
            listData = listData[this.documentSettings.documentType + "List"];
        }

        let componentize = this.documentSettings.view && this.documentSettings.view.search &&
                this.documentSettings.view.search && 
                Services[this.documentSettings.documentType]['ui'] && 
                Services[this.documentSettings.documentType]['ui']['componentize'] ? Services[this.documentSettings.documentType]['ui']['componentize']: null;

        let infographics = componentize ? 
                           componentize(null, "infographics", Object.assign({}, {...this.props, documentSettings:this.documentSettings, pagination: pagination})) : null
                           || <DocumentListInfoGraphics pagination={pagination} documentSettings={this.documentSettings} />
        
        let finder = componentize
                    ? 
                    componentize(null, "search", Object.assign({}, {...this.props, documentSettings:this.documentSettings})) : null
                    || 
                    this.documentSettings.view.search ? 
                    <DocumentListFinder {...this.props} documentSettings={this.documentSettings}/>
                    : ""

        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={this.props.name}
                                category={"List of " + this.props.name}
                                ctTableFullWidth
                                ctTableResponsive
                                infographics={
                                    infographics
                                }
                                contentStyle={{minHeight:500+"px"}}
                                content={
                                <div>
                                    <div>
                                        {
                                            finder
                                        }
                                    </div>
                                    <div className="table-responsive">
                                        {
                                        <Table striped hover style={{borderTop: 1 + "px solid #e3e3e5", borderBottom: 1 + "px solid #e3e3e5"}} key={this.documentSettings.documentType} className="tablesaw" data-tablesaw-mode="swipe">
                                            <thead>
                                            <tr>
                                                {this.documentSettings.view.headers.labels.map((prop, key) => {
                                                    if(this.documentSettings.view.headers.widths){
                                                        return <th key={key} style={{width:this.documentSettings.view.headers.widths[key]}} data-tablesaw-sortable-col data-tablesaw-priority={key + 1} scope="col">{prop}</th>;    
                                                    }
                                                    return <th key={key} data-tablesaw-sortable-col data-tablesaw-priority={key} scope="col">{prop}</th>;
                                                })}
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    fail ? (
                                                        <tr>
                                                            <td colSpan={this.documentSettings.view.headers.labels.length}>
                                                                <p className="text-danger">
                                                                Unable to retrieve list.
                                                                </p>
                                                            </td>
                                                        </tr>                                                
                                                    ) :
                                                    listData.length ? (
                                                    listData.map((obj, key) => {     
                                                        let componentId = key;                                           
                                                        return(
                                                            <tr key={key}>
                                                                
                                                                {
                                                                    this.documentSettings.view.headers.attributes.map((prop, key) => {
                                                                        if(typeof prop == 'object'){
                                                                            if(prop['convertByAttribute'] && Services[this.documentSettings.documentType]['valueConverter']){                                                                        
                                                                                return <td key={prop['convertByAttribute']}>
                                                                                    
                                                                                    <Link to={this.documentSettings.routes.local.path + "/" + obj.id}>
                                                                                    {Services[this.documentSettings.documentType]['valueConverter']['convertByAttribute'](obj, prop['convertByAttribute'])}
                                                                                    </Link>
                                                                                </td>;
                                                                            }
                                                                            //componentize attribute
                                                                            if(prop['componentize'] && Services[this.documentSettings.documentType]['ui']){
                                                                                return <td key={prop['componentize']}>
                                                                                    
                                                                                    {Services[this.documentSettings.documentType]['ui']['componentize'](obj, prop['componentize'], Object.assign({},{...this.props}), {componentId: (obj.id || componentId) })}
                                                                                    
                                                                                </td>
                                                                            }
                                                                        }
                                                                        return <td key={prop}>
                                                                            <ControlLabel className="tablesaw-cell-label">{this.documentSettings.view.headers.labels[key]}</ControlLabel>
                                                                            <Link to={this.documentSettings.routes.local.path + "/" + obj.id}>{obj[prop]}</Link>
                                                                        </td>;
                                                                    })    
                                                                } 
                                                            </tr>  
                                                                    
                                                        );
                                                    }) ) 
                                                    :
                                                    fetching ? ( 
                                                        <tr>
                                                            <td colSpan={this.documentSettings.view.headers.labels.length}>
                                                                <p className="text-primary">
                                                                Retrieving list...
                                                                </p>
                                                            </td>                                                    
                                                        </tr> 
                                                    ) 
                                                    : 
                                                    (
                                                        <tr>
                                                            <td colSpan={this.documentSettings.view.headers.labels.length}>
                                                                <p className="text-danger">
                                                                No entries found.
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    )                                            
                                                }
                                            </tbody>
                                        </Table>
                                        }
                                    </div>
                                </div>
                                }

                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }

    render(){
        //window.Tablesaw.init();
        return(
            <div className="content">
                {
                    this.tableList()
                }
                <DocumentListCrud documentSettings={this.documentSettings} {...this.props} />
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    listData: state.documentList.listData,
    fetching: state.documentList.fetching,    
    success: state.documentList.success,
    fail: state.documentList.fail    
});

const mapActionsToProps = {
    ...documentListActions, ...toastrActions
};

export default connect(mapStateToProps, mapActionsToProps)(DocumentList);