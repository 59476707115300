import React, { PureComponent } from "react";
import {
    Label,
    Modal,
    Table,
    Button,
    FormControl
} from "react-bootstrap";
import { CustomCheckbox } from "common/components/CustomCheckbox.jsx";

import moment from "moment"
import * as Services from "services";
import * as Settings from "settings";
import Numeral from "numeral";
import Inflection from "inflection";

class ACLAccessPropertyDetail extends PureComponent {
    
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            selectedProperties: []
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    UNSAFE_componentWillReceiveProps(props){
        //console.log('ACLAccessPropertyDetail componentWillReceiveProps', props);
        if(props.model && props.rbac && props.rbac[props.model]){
            this.setState(state => {
                return Object.assign({}, state, {
                    selectedProperties: props.rbac[props.model]
                })
            })
        }else{
            this.setState(state => {
                return Object.assign({}, state, {
                    selectedProperties: []
                })
            })
        }
    }

    handleChange(e){
        let {name, value} = e.target;
        let acl = e.target.getAttribute('data-acl');
        console.log('ACLAccessPropertyDetail handleChange',acl, value, this.state.selectedProperties.includes(acl), this.state.selectedProperties);
        if(value>0){
            this.setState(state=>{
                return Object.assign({}, state, {
                    selectedProperties: Array.from(new Set([...state.selectedProperties, ...[acl] ])) 
                })
            })
        }else{
            console.log('remove: ', acl);
            this.setState(state=>{
                return Object.assign({}, state, {
                    selectedProperties: state.selectedProperties.filter(item=>item!==acl)
                })
            })
        }
    }

    handleClose(e){
        this.props.handleClose(e, {model: this.props.model, selectedProperties: this.state.selectedProperties});
    }

    render(){

        console.log('ACLAccessPropertyDetail render', Object.keys(Settings.acl.acl), this.state.selectedProperties);
        let {title} = this.props;
        
        let properties = [];
        for(let key of Object.keys(Settings.acl.acl)){
            let acls = Settings.acl.acl[key];
            for(let acl of acls){
                properties.push({
                    key: key,
                    acl: acl
                })   
            }
        }     

        return(
            <div>
                <Modal show={this.props.showModal} onHide={this.handleClose} bsSize="large" aria-labelledby="contained-modal-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{"ACL Property: " + this.props.title}</Modal.Title>
                    </Modal.Header>                
                    <Modal.Body style={{padding:0}}>

                    <Table striped hover style={{borderTop: 1 + "px solid #e3e3e5", borderBottom: 1 + "px solid #e3e3e5"}}>
                        <thead>
                        <tr>
                            <th style={{width:"30%"}}>Access Type</th>
                            <th style={{width:"45%"}}>Property</th>
                            <th style={{width:"25%"}}>Allow?</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            properties.map((property,i)=>{
                                return(
<                               tr key={property.key + "-" + i}>
                                    <td>{Inflection.humanize(property.key)}</td>
                                    <td>{Inflection.humanize(property.acl)}</td>
                                    <td>
                                        <FormControl
                                            key={property.key + "-acl-" + i}
                                            componentClass="select"
                                            bsClass= {"form-control"}
                                            value= {this.state.selectedProperties.includes(property.acl) ? 1: 0}
                                            onChange = {this.handleChange}
                                            data-acl={property.acl}
                                        >
                                            { (function(uomList){
                                                let uoms = [];
                                                for (let key of Object.keys(uomList)) {
                                                    uoms.push(
                                                        <option key={key} value={key}>
                                                            {uomList[key]}
                                                        </option>
                                                    );
                                                }
                                                return uoms;
                                            })({ 0:'DENIED', 1:'ALLOWED'}) }
                                        </FormControl>

                                    </td>
                                </tr>
                                )   
                            })                               
                        }
                        </tbody>
                    </Table>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleClose} className="btn-fill">Close</Button>
                    </Modal.Footer>
                </Modal>
            </div> 
        )       
    }
    
}

export default ACLAccessPropertyDetail;