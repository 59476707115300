import React, { Component } from "react";
import { connect } from 'react-redux';
import { Grid, Row, Col } from "react-bootstrap";

import WelcomeWidget from "dental/dashboard/widgets/WelcomeWidget.jsx";
import PatientCountWidget from "dental/dashboard/widgets/PatientCountWidget.jsx";
import PatientInactiveCountWidget from "dental/dashboard/widgets/PatientInactiveCountWidget.jsx";
import PatientCalendarWidget from "dental/dashboard/widgets/PatientCalendarWidget.jsx";

import url from "./../../utils/url";
import history from './../../history';

class AdminDashboard extends Component {

    constructor(props){
        super(props);
    }

	componentDidMount(){  
        console.log('Dashboard componentDidMount', this.props.location)
        if(this.props.location && this.props.location.search){
            let pageParams = url.urlSearchParams(this.props.location.search);
            let next = pageParams.next || null;
            if(next){
                history.push(next);
            }
        }
    }

    render() {
        return (
            <Grid fluid>
                <Row>
                    <Col lg={6} sm={6}>
                        <WelcomeWidget />
                    </Col>
                    <Col lg={3} sm={6}>
                        <PatientCountWidget />
                    </Col>
                    <Col lg={3} sm={6}>
                        <PatientInactiveCountWidget />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <PatientCalendarWidget />
                    </Col>
                </Row>               
            </Grid>
        );
    }
}

const mapStateToProps = (state) => (
    {
        account: state.account
    }
)

export default connect(mapStateToProps)(AdminDashboard);