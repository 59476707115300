import Dashboard from "layouts/Dashboard/Dashboard.jsx";
import Login from "layouts/Authentication/Login.jsx";
import SSOContainer from "layouts/Authentication/SSO.jsx";
import Registration from "./../layouts/Authentication/Registration.jsx";
import Password from "./../layouts/Authentication/Password.jsx";
import RegistrationWelcome from "./../layouts/Authentication/RegistrationWelcome.jsx";
import RegistrationConfirmation from "./../layouts/Authentication/RegistrationConfirmation.jsx";

var indexRoutes = [
    { path: "/register", name: "Registration", component: Registration },
    { path: "/reset-password", name: "Reset Password", component: Password },
    { path: "/confirm-verification", name: "Confirm Verification", component: RegistrationConfirmation },
    { path: "/registration/welcome", name: "Welcome", component: RegistrationWelcome },
    { path: "/login", name: "Login", component: Login },
    { path: "/logout", name: "Logout", component: Login },
    { path: "/sso", name: "Single Sign-on", component: SSOContainer },
    { path: "/", name: "Dashboard", component: Dashboard }   
];

export default indexRoutes;