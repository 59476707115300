import React, { Component } from "react";
import { FormGroup, ControlLabel, Label, FormControl, Row } from "react-bootstrap";
import MaskedInput from 'react-text-mask'

function FieldGroup({ label, ...props }) {

    let {error} = props;
    let message = error && error.data && error.data.error && error.data.error.details && error.data.error.details.messages[props.name] ? 
        error.data.error.details.messages[props.name]: null;

    return (
        <FormGroup className={message? "has-error":""}>
            <ControlLabel>{label}</ControlLabel>
            {
              props.masked ? 
              <MaskedInput {...props} 
                className="form-control"
              />
              :
              <FormControl {...props} />
            }
            {
                props.error ? 
                <span className="help-block">{message}</span>
                : ""
            }            
        </FormGroup>
    );
}

export class FormInputs extends Component {
  render() {
    var row = [];
    for (var i = 0; i < this.props.ncols.length; i++) {
      row.push(
        <div key={i} className={this.props.ncols[i]}>
          <FieldGroup {...this.props.proprieties[i]} />
        </div>
      );
    }
    return <Row>{row}</Row>;
  }
}

export default FormInputs;