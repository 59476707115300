import React, { Component } from "react";
import { connect } from 'react-redux';

import {
  Table,
  Button
} from "react-bootstrap";
import { Link } from "react-router-dom";
import * as documentListActions from "common/actions/documentList.action";
import * as Settings from "settings";
import history from "./../../history"

class ACLUserList extends Component {

    documentSettings = {}

    roles = {}

    constructor(props){
        super(props)
        this.documentSettings = Settings['extendeduser'];
        this.handleNew = this.handleNew.bind(this);
    }

    componentDidMount() {
        this.props.retrieveDocumentList(this.documentSettings);

        let { listData } = this.props;
        if(listData['extendedroleList']){
            listData = listData["extendedroleList"];
            this.roles = listData.reduce((obj, item) => {
                obj[item.id] = item.name
                return obj
            }, {});
        }
    }

    handleNew(e){
        e.preventDefault();
        history.push("/extendedusers/$");
    }

    tableList() {

        let { fail, fetching, listData } = this.props;

        if(listData['extendeduserList']){
            listData = listData["extendeduserList"];
        }

        return (
            <Table striped hover style={{borderTop: 1 + "px solid #e3e3e5", borderBottom: 1 + "px solid #e3e3e5"}} >
                <thead>
                <tr>
                    <th key={"email"} style={{width:"25%"}}>Email</th>
                    <th key={"lastName"} style={{width:"25%"}}>Last Name</th>
                    <th key={"firstName"} style={{width:"25%"}}>First Name</th>
                    <th key={"role"} style={{width:"25%"}}>Role</th>
                </tr>
                </thead>
                <tbody>
                    {
                        fail ? (
                            <tr>
                                <td colSpan={3}>
                                    <p className="text-danger">
                                    Unable to retrieve list.
                                    </p>
                                </td>
                            </tr>                                                
                        ) :
                        listData.length ? (
                        listData.map((obj, key) => {                                                
                            return(
                                <tr key={key}>

                                <td key={"email-" + key}><Link to={"/users/" + obj.id}>{obj['email']}</Link></td>
                                <td key={"lastName-" + key}><Link to={"/users/" + obj.id}>{obj['lastName']}</Link></td>
                                <td key={"firstName-" + key}><Link to={"/users/" + obj.id}>{obj['firstName']}</Link></td>
                                <td key={"role-" + key}><Link to={"/users/" + obj.id}>{ this.roles[obj['role']] || obj['role']  }</Link></td>

                                </tr>  
                                            
                            );
                        }) ) 
                        :
                        fetching ? ( 
                            <tr>
                                <td colSpan={3}>
                                    <p className="text-primary">
                                    Retrieving list...
                                    </p>
                                </td>                                                    
                            </tr> 
                        ) 
                        : 
                        (
                            <tr>
                                <td colSpan={2}>
                                    <p className="text-danger">
                                    No entries found.
                                    </p>
                                </td>
                            </tr>
                        )                                            
                    }
                </tbody>
            </Table>
        );
    }

    render(){
        return(
            <div className="">
                <div className="content">
                <div class="row">
                    <div class="col-md-12">
                        <h5 className="title" >List of Users</h5>
                    </div>
                </div>                    
                </div>
                {
                    this.tableList()
                }
                <div className="content">
                    <div className="row">
                        <div class="col-md-12">
                            <Button bsStyle="default" fill className="btn-fill " style={{width: 150 + "px", "marginBottom": 10 + "px"}} onClick={this.handleNew}>
                                New
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    listData: state.documentList.listData,
    fetching: state.documentList.fetching,    
    success: state.documentList.success,
    fail: state.documentList.fail    
});

const mapActionsToProps = {
    ...documentListActions
};

export default connect(mapStateToProps, mapActionsToProps)(ACLUserList);