import React, { Component } from "react";
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from "react-router-dom";
import NotificationSystem from "react-notification-system";

import Header from "common/components/Header";
import Footer from "common/components/Footer";
import Sidebar from "common/components/Sidebar";

import { style } from "variables/Variables.jsx";

import dashboardRoutes from "routes/dashboard.jsx";
import documentRoutes from "routes/document.jsx";

const routes = [...documentRoutes, ...dashboardRoutes];

class Dashboard extends Component {
	constructor(props) {
		super(props);
	}

	render() {

		let { account } = this.props
		let currentRole = account && account.user ? account.user.roles_mask : "guest";
		let routesByRole = routes.filter(route => route.defaultRoles && Array.isArray(route.defaultRoles) && (route.defaultRoles.includes(currentRole) || route.defaultRoles.includes("everyone")) 
			? true: currentRole=="admin" ? true:false )

		return (
			<div className="wrapper">
				<NotificationSystem ref="notificationSystem" style={style} />
				<Sidebar {...this.props} />
				<div id="main-panel" className="main-panel" ref="mainPanel">
					<Header {...this.props} />
					<Switch>
						{routesByRole.map((prop, key) => {              
							if (prop.redirect)
								return <Redirect from={prop.path} to={prop.to} key={key} />;
							return (
								<Route path={prop.path} key={key} 
										render={routeProps => (  
												<prop.component
														{...routeProps}
														{...prop}
												/>
										)}
								/>
							);
						})}

					</Switch>         
					<Footer />
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => (
    {
        account: state.account
    }
)

export default connect(mapStateToProps)(Dashboard);