/**
 * DOCUMENT LIST REDUCER
 */

const initialState  = {
    listData: [],
    fetching: false,
    success: false,
    fail: false,
    documentListCrud: { 
        new: {
            enabled: true,
            hidden: false
        } 
    },
}

export const documentList = (state = initialState, action) => {
    switch (action.type) {
        case 'DOCUMENT_LIST_FETCHING':
            return {
                ...state,
                fetching: action.fetching,
            }
        case 'DOCUMENT_LIST_FETCH_SUCCESS':
            return {
                ...state,
                success: action.success,
            }
        case 'DOCUMENT_LIST_FETCH_FAIL':
            return {
                ...state,
                fail: action.fail,
            }
        case 'DOCUMENT_LIST_SET_DATA':
            return {
                ...state,
                listData: action.listData
            }
        case 'DOCUMENT_LIST_CRUD_SET_STATE':
            return {
                ...state,
                documentListCrud: action.documentListCrud || initialState.documentListCrud
            }
        default:
            return state
    }
}