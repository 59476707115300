import React, { Component } from "react";
import { connect } from 'react-redux';

import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Alert
} from "react-bootstrap";

import { Card } from "common/components/Card.jsx";
import { FormInputs } from "common/components/FormInputs.jsx";
import { UserCard } from "common/components/UserCard.jsx";
import Button from "common/components/CustomButton.jsx";
import PictureUploader from "./../components/PictureUploader";
import { DatePicker } from "common/components/DatePicker.jsx";
import avatar from "assets/img/faces/face-3.jpg";
import moment from "moment";
import * as Settings from "settings";
import * as documentActions from "common/actions/document.action";
import * as Services from 'services';

class UserProfile extends Component {

    state = {
        user: {
            userprofile: {
                id: 0,
                email: "",
                address1: "",
                address2: "",
                date_of_birth: "",
                city: "",
                country: "",
                first_name: "",
                sex: "M",
                last_name: "",
                phone: "",
                state: "",
                zip: "",
                aboutme: ""    
            }            
        },       
    }

    constructor(props) {
        super(props);
        this.handleProfileUpdate = this.handleProfileUpdate.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

	componentDidMount(){
		console.log('UserProfile componentDidMount props', this.props);
        
		Services.user.getProfile()
		.then((res) => {
            console.log('UserProfile ', res.data);
            if(res.data && res.data){
                this.setState((state) => {
                    return {
                        user: Object.assign({}, state.user, {userprofile:res.data})
                    };
                });
            }
		});
    }

    componentWillReceiveProps(props){
        console.log('UserProfile componentWillReceiveProps', props)

        if(props.account && props.account.user ){
            if(props.account.user.roles_mask='admin'){
                this.setState((state) => {
                    return Object.assign({}, state, 
                    {
                        user: Object.assign({}, state.user, {userprofile: props.account.user})
                    });				
                }, ()=>{
                });
            }else{
                Services.patient.current(props)
                .then((res) => {		
                    this.setState((state) => {
                        return Object.assign({}, state, 
                        {
                            user: Object.assign({}, state.user, {userprofile: Array.isArray(res.data) && res.data.length ? res.data[0]: null })
                        });				
                    }, ()=>{
                        
                    });
                });
            }
        }
        
    }

    handleChange(e){
        console.log('handleChange', e);
        const { name, value } = e.target;

        this.setState((state) => {
            return Object.assign({}, state, 
            {
                user: {
                    userprofile: Object.assign({}, state.user.userprofile, {[name]:value})
                }
            });
        });
    }
    
    handleProfileUpdate(e){
        this.props.handleDocumentChange(this.props.documentSettings, { data: this.state.user.userprofile });
        //save profile
        this.props.saveDocument(Settings.userprofile);
    }

    documentAlert(){
        let {actionInProgress, actionCompleted, success, error} = this.props;
        let message = "Success."
        let variant = "success"
        if(error.data){
            variant = "danger"
            message = error.data.error.message
        }

        if(actionCompleted || error.data){
            return (
                <div style={{marginTop: 10 + "px"}}>
                    <Row>
                        <Col md={12}>
                            <Alert dismissible className={"alert-" + variant}>
                                {message}
                            </Alert>                            
                        </Col>
                    </Row>
                </div>                
            )
        }

        return (
            <div></div>
        )

    }

    render() {

        let { user } = this.state;
        console.log('UserProfile', this.state);

        let { actionCompleted } = this.props;

        return (
            <div className="content">
            <Grid fluid>
                <Row>
                <Col md={8}>
                    <Card
                    title="My Profile"
                    content={
                        <form>
                        <FormInputs
                            ncols={["col-md-5", "col-md-3", "col-md-4"]}
                            proprieties={[
                            {
                                label: "Company",
                                type: "text",
                                bsClass: "form-control",
                                placeholder: "Company",
                                value: Settings.app.defaultCompanyName,
                                disabled: true
                            },
                            {
                                label: "Username",
                                type: "text",
                                bsClass: "form-control",
                                placeholder: "Username",
                                defaultValue: user.userprofile.username,
                                disabled: true,
                            },
                            {
                                label: "Email address",
                                type: "email",
                                bsClass: "form-control",
                                placeholder: "Email",
                                disabled: true,
                                defaultValue: user.userprofile.email
                            }
                            ]}
                        />
                        <FormInputs
                            ncols={["col-md-6", "col-md-6"]}
                            proprieties={[
                            {
                                label: "First name",
                                type: "text",
                                bsClass: "form-control",
                                placeholder: "First name",
                                name: "first_name",
                                value: user.userprofile.first_name,
                                onChange: this.handleChange
                            },
                            {
                                label: "Last name",
                                type: "text",
                                bsClass: "form-control",
                                placeholder: "Last name",
                                name: "last_name",
                                value: user.userprofile.last_name,
                                onChange: this.handleChange
                            }
                            ]}
                        />
                        <DatePicker
                            ncols={["col-md-12"]}
                            proprieties={[
                            {
                                label: "Data of Birth",
                                type: "text",
                                bsClass: "form-control",
                                placeholder: "",
                                value: user.userprofile.date_of_birth ? moment(user.userprofile.date_of_birth).format('YYYY-MM-DD'):moment().format('YYYY-MM-DD'),
                                name: "date_of_birth",
                                onChange: this.handleChange
                            }
                            ]}
                        />

                        <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                            {
                                label: "Adress",
                                type: "text",
                                bsClass: "form-control",
                                placeholder: "Home Adress",
                                name: "address1",
                                value: user.userprofile.address1,
                                onChange: this.handleChange
                            }
                            ]}
                        />
                        <FormInputs
                            ncols={["col-md-4", "col-md-4", "col-md-4"]}
                            proprieties={[
                            {
                                label: "City",
                                type: "text",
                                bsClass: "form-control",
                                placeholder: "City",
                                name: "city",
                                value: user.userprofile.city,
                                onChange: this.handleChange
                            },
                            {
                                label: "State",
                                type: "text",
                                bsClass: "form-control",
                                placeholder: "State",
                                name: "state",
                                value: user.userprofile.state,
                                onChange: this.handleChange
                            },
                            {
                                label: "Postal Code",
                                type: "number",
                                bsClass: "form-control",
                                placeholder: "ZIP Code",
                                name: "zip",
                                value: user.userprofile.zip,
                                onChange: this.handleChange
                            }
                            ]}
                        />

                        <Row>
                            <Col md={12}>
                            <FormGroup controlId="formControlsTextarea">
                                <ControlLabel>About Me</ControlLabel>
                                <FormControl
                                rows="5"
                                componentClass="textarea"
                                bsClass="form-control"
                                placeholder="About Me"
                                name="aboutme"
                                value={user.userprofile.aboutme}
                                onChange={this.handleChange}
                                />
                            </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Button bsStyle="info" pullRight fill onClick={this.handleProfileUpdate}>
                                    Update Profile
                                </Button>                            
                            </Col>
                        </Row>
                        
                        { this.documentAlert() }
                        <div className="clearfix" />
                        </form>
                    }
                    />
                </Col>
                <Col md={4}>
                    <UserCard
                    bgImage="https://ununsplash.imgix.net/photo-1431578500526-4d9613015464?fit=crop&fm=jpg&h=300&q=75&w=400"
                    avatar={user.userprofile.img || "/images/placeholder.png"}
                    name={user.userprofile.firstName ? user.userprofile.firstName + " " + user.userprofile.lastName: ""}
                    userName={user.userprofile.username || ""}
                    description={
                        <span>
                        {user.userprofile.aboutme}
                        <div style={{marginTop:20+"px"}}>
                        {
                            <PictureUploader 
                                title={"Update Profile Picture"}
                                filesdir={"UP-" + user.userprofile.id}
                                initialPreview={user.userprofile.img}
                                handleChange={this.handleChange}
                            />
                        }
                        </div>
                        </span>
                    }
                    socials={
                        <div>
                        <Button simple>
                            <i className="fa fa-facebook-square" />
                        </Button>
                        <Button simple>
                            <i className="fa fa-twitter" />
                        </Button>
                        <Button simple>
                            <i className="fa fa-google-plus-square" />
                        </Button>
                        </div>
                    }
                    />
                </Col>
                </Row>
            </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    account: state.account,
    error: state.document.error,
    actionInProgress: state.document.actionInProgress,
    actionCompleted: state.document.actionCompleted,
    success: state.document.success   
});

const mapActionsToProps = {
    ...documentActions
};

export default connect(mapStateToProps, mapActionsToProps)(UserProfile);