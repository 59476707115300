import React, { Component } from "react";
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";

import HeaderLinks from "./HeaderLinks.jsx";

import logo from "assets/img/reactlogo.png";

import dashboardRoutes from "routes/dashboard.jsx";

class Sidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			width: window.innerWidth
		};
	}
	activeRoute(routeName) {
		return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
	}
	updateDimensions() {
		this.setState({ width: window.innerWidth });
	}
	componentDidMount() {
		this.updateDimensions();
		window.addEventListener("resize", this.updateDimensions.bind(this));
	}
	render() {

		let { account } = this.props
		let currentRole = account && account.user ? account.user.roles_mask : "guest";
		let routesByRole = dashboardRoutes.filter(route => route.defaultRoles && Array.isArray(route.defaultRoles) 
			&& (route.defaultRoles.includes(currentRole) || route.defaultRoles.includes("everyone")) ? true:false )

		return (
			<div
				id="sidebar"
				className="sidebar"
				data-color="black"
			>
				<div className="sidebar-background" />
				<div className="logo">
					<a
						href="#"
						className="simple-text logo-mini"
					>
						<div className="logo-img">
								<span data-notify="icon" className={"pe-7s-users"} />
						</div>
					</a>
					<a
						href="#"
						className="simple-text logo-normal"
					>
						Patient Portal
					</a>
				</div>
				<div className="sidebar-wrapper">
					<ul className="nav">
						{this.state.width <= 991 ? <HeaderLinks /> : null}
						{routesByRole.map((prop, key) => {
							if (!prop.redirect)
								return (
									<li
										className={
											prop.upgrade
												? "active active-pro"
												: this.activeRoute(prop.path)
										}
										key={key}
									>
										<NavLink
											to={prop.path}
											className="nav-link"
											activeClassName="active"
										>
											<i className={prop.icon} />
											<p>{prop.name}</p>
										</NavLink>
									</li>
								);
							return null;
						})}
					</ul>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => (
    {
        account: state.account
    }
)

export default connect(mapStateToProps)(Sidebar);
