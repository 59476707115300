
import axios from './Http';
import * as  authOperations  from "./../authentication/actions/auth.action";
// whats a tutorial without an obscure authService :)
//import authService from './../authentication/authService';

import * as authTokenLocalStorage from 'utils/authtoken.localstorage';

const httpService = {

    clearHeaderAuthorization: (store) => {
        authTokenLocalStorage.setTokenFromStorage("");
        console.log('Token from storage', authTokenLocalStorage.getTokenFromStorage());
        httpService.setupRequestInterceptors(store);
    },

    setupRequestInterceptors: (store) => {
        console.log('httpService setupRequestInterceptors ', store);
        axios.interceptors.request.use(function (config) {
            let token = authTokenLocalStorage.getTokenFromStorage()
            if(!token && store.auth){
                token = store.auth.authToken;
                console.log('httpService axios.interceptors.request.use ', token);
            }
            config.headers.Authorization =  "Basic " + token;
            return config;
        });        
    },

    // we pass the redux store and history in order to dispatch the logout actions 
    // and push the user to login
    setupResponseInterceptors: (store, history) => {
        axios.interceptors.response.use((response) => {
        // simply return the response if there is no error
            console.log('httpService', response); 
            return response;
        }, (error) => {
        // in this case we only care about unauthorized errors status:401
            console.log('httpService error', error);  
            if (error.response && error.response.status && error.response.status === 401 ) {
                console.log('httpService axios.interceptors.response ', axios.defaults);
                console.log('dispatch logout and redirect to login', history); 
                // we dispatch our logout action (more than likely changes a boolean 
                // somewhere in your store ex. isAuthenticated: false)
                
                store.dispatch(authOperations.authUserLogout());
                
                // this could just as easily be localStorage.removeItem('your-token')
                // but it's best to encapsulate this logic so it can be used elsewhere
                // by just importing it.
                
                //authService.removeToken();
                store.dispatch(authOperations.authUserAuthToken(''))
                
                // send the user to the login page since the user/token is not valid
                history.push('/login');
            }
            return Promise.reject(error);
        });
    }
};
export default httpService;