import React, { Component } from "react"
import { StatsCard } from "common/components/StatsCard.jsx";
import * as Services from "services"

class PatientInactiveCountWidget extends Component {
	
	constructor(props) {            
        super(props);
        this.state = {
            count: 0,           
		}
    }

    componentDidMount () {
        this.handleCount()
    }

    handleCount = () => {
        /*
        Services.dashboard.countEmployeeResigned().then((res) => {
            if (res.data) {
                this.setState((state) => {
                    return Object.assign({}, state, 
                    {
                        count: res.data[0].count
                    });				
                });
            }            
		});*/
    }
    
	render() {        
        return (			
            <StatsCard
                bigIcon={<i className="pe-7s-id text-danger" title="Employee Resigned/Terminated"/>}
                statsText="Inactive"
                statsValue={this.state.count}
            />
		);
	}
}

export default PatientInactiveCountWidget;
