import Api from 'utils/Http'
import fetch from 'node-fetch'

import moment from "moment";

export const patient = {

    pushContents: (content, key, options={}, cb) => {

        let {name, type} = content;
        console.log(name, type, options);
        
        const formData = new FormData()
        formData.append('id', options.ownerId|| 0)
        formData.append('file', content)
        if(options.model && options.model.file_description){
            formData.append('notes_string', options.model.file_description)
        }
        if(options.model && options.model.directory){
            formData.append('directory', options.model.directory)
        }
        if(options.model && options.model.uploaded_by){
            formData.append('uploaded_by', options.model.uploaded_by)
        }

        return Api.post('/patient_shared_files', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then((res) => {
            cb(null, Object.assign({}, res.data, {url: res.data.file_url}))
        }).catch((err)=>{
            cb(err, null)
        })
    },

    pullContents: (key, options) => {

        return Api.get('/patient_shared_files'  + (options.id ? '/' + options.id: '') + "?id[order]=desc" );

    }

}

export const patientProfile = {

    pushContents: (content, key, options={}, cb) => {

        let {name, type} = content;
        console.log(name, type, options);
        let patientProfile = options.model ? options.model.patientProfile: {};
        const formData = new FormData()
        formData.append('id', patientProfile ? patientProfile.id : 0)
        formData.append('patient_profile[patient_id]', patientProfile ? patientProfile.patient_id : 0)
        formData.append('patient_profile[first_name]', patientProfile ? patientProfile.first_name : "")
        formData.append('patient_profile[last_name]', patientProfile ? patientProfile.last_name : "")
        formData.append('patient_profile[insurance_info]', patientProfile ? patientProfile.insurance_info : "")
        formData.append('patient_profile[date_of_birth]', patientProfile ? patientProfile.date_of_birth : moment().format("YYYY-MM-DD"))
        formData.append('patient_profile[sex]', patientProfile ? patientProfile.sex : "")
        formData.append('patient_profile[avatar]', content)

        return Api.put('/patient_profiles/' + patientProfile.id, formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then((res) => {
            cb(null, Object.assign({}, res.data, {url: res.data.avatar_url}))
        }).catch((err)=>{
            cb(err, null)
        })
    }

}