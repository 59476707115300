/**
 * DOCUMENT REDUCER
 */

const initialState  = {
    primaryKey: {},
    documentData: {},
    documentSchema: {},
    documentCrud: { 
        new: {
            enabled: true,
            hidden: false
        },
        save: {
            enabled: true,
            hidden: false
        } 
    },
    error: {},
    actionInProgress: false,
    actionCompleted: false,
    fetching: false,
    fetchingFirst: false,
    fetchingPrev: false,
    fetchingNext: false,
    fetchingLast: false,
    success: false,
    fail: false,
}

export const document = (state = initialState, action) => {
    switch (action.type) {
        case 'DOCUMENT_FETCHING':
            return {
                ...state,
                fetching: action.fetching,
                actionInProgress: false,
                actionCompleted: false,                
                success: false,
                fail: false,
                error: {}           
            }
        case 'DOCUMENT_FETCHING_FIRST':
            return {
                ...state,
                fetchingFirst: action.fetchingFirst,
                fetchingPrev: false,
                fetchingNext: false,
                fetchingLast: false,
                actionInProgress: false,
                actionCompleted: false,                
                success: false,
                fail: false,
                error: {}              
            }
        case 'DOCUMENT_FETCHING_PREV':
            return {
                ...state,
                fetchingPrev: action.fetchingPrev,
                fetchingFirst: false,
                fetchingNext: false,
                fetchingLast: false,
                actionInProgress: false,
                actionCompleted: false,                
                success: false,
                fail: false,
                error: {}             
            }
        case 'DOCUMENT_FETCHING_NEXT':
            return {
                ...state,
                fetchingNext: action.fetchingNext,
                fetchingFirst: false,
                fetchingPrev: false,
                fetchingLast: false,
                actionInProgress: false,
                actionCompleted: false,                
                success: false,
                fail: false,
                error: {}              
            }
        case 'DOCUMENT_FETCHING_LAST':
            return {
                ...state,
                fetchingLast: action.fetchingLast,
                fetchingFirst: false,
                fetchingPrev: false,
                fetchingNext: false,
                actionInProgress: false,
                actionCompleted: false,                
                success: false,
                fail: false,
                error: {}           
            }
        case 'DOCUMENT_FETCH_SUCCESS':
            return {
                ...state,
                success: action.success,
                fetching: false,
                fetchingFirst: false,
                fetchingPrev: false,
                fetchingNext: false,
                fetchingLast: false,
                actionInProgress: false,
                actionCompleted: false,                
                fail: !action.success,
                error: {} 
            }
        case 'DOCUMENT_ACTION_IN_PROGRESS':
            return {
                ...state,
                actionInProgress: action.actionInProgress,
                actionCompleted: !action.actionInProgress,
                success: false,
                fail: false,
                error: {}                 
            }        
        case 'DOCUMENT_ACTION_SUCCESS':
            return {
                ...state,
                success: action.success,
                actionCompleted: action.success,
                actionInProgress: !action.success,
                fail: !action.success,
                error: {} 
            }
        case 'DOCUMENT_ACTION_FAIL':
        case 'DOCUMENT_FETCH_FAIL':
            return {
                ...state,
                fail: action.fail,
                success: !action.fail,
                actionCompleted: !action.fail,
                actionInProgress: !action.fail
            }
        case 'DOCUMENT_SET_DATA':
            return {
                ...state,
                documentData: action.documentData
            }
        case 'DOCUMENT_CRUD_SET_STATE':
            return {
                ...state,
                documentCrud: action.documentCrud || initialState.documentCrud
            }
        case 'DOCUMENT_SET_PRIMARY':
            return {
                ...state,
                primaryKey: action.primaryKey
            }
        case 'DOCUMENT_SET_ERROR':
            return {
                ...state,
                error: action.error
            }            
        default:
            return state
    }
}