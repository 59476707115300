//user
export * from './user/config/settings';
//company
export * from './company/settings';
//dental
export * from './dental/settings';

//export app settings
export const app = {
    title: "Starship ERP - Patient Portal",
    defaultCompanyName: "Maddington, Inc.",
    defaultCompanyId: 1,
    copyright: "Maddington",
    logo: "https://starship-live.s3.us-east-2.amazonaws.com/starship.png"
}

export const google = {
    clientId: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID
}

export const aws = {
    s3: {
        bucket: process.env.REACT_APP_AWSSDK_S3_BUCKET,
        region: process.env.REACT_APP_AWSSDK_REGION
    }
}