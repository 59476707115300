import React, { Component } from "react";
import { FormGroup, ControlLabel, FormControl, Row } from "react-bootstrap";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import TimePicker from 'react-time-picker';
import moment from 'moment';

export class TimePickerComponent extends Component {

    constructor(props) {
        super(props);
		this.handleTimeChange = this.handleTimeChange.bind(this);
		
        this.state = {
        	selectedTime: moment().format('HH:mm:ss'),
        	isEmpty: true,
        	isDisabled: false
        }
	
    }

    handleTimeChange(time) {
        console.log("TimePicker handleTimeChange", time);
        this.props.onChange({
            target: {
                name: this.props.name,
                value: time,
                getAttribute: (prop) => {
                    return this.props[prop];
                }
            }
        });

    }

	render() {
		return <Row>
            <div className="col-lg-12">
                <FormGroup controlId="formControlsSelectMultiple" >
                {
                    this.props.label ? <ControlLabel>{this.props.label}</ControlLabel> : ""
                }
                <br/>
                {
                <TimePicker
                    onChange={this.handleTimeChange}
                    value={this.props.value}
                    format={"HH:mm:ss"}
                    className={"form-control"}
                />
                }
                </FormGroup>
            </div>
        </Row>;
	}
}

export default TimePickerComponent;
