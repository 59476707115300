import React, {Suspense} from "react";

import {fallback} from "common/containers/fallback.jsx";

export const withSuspense = (Component, config) => {
    return class withSuspense extends React.Component{
        render(){
            return(
                <Suspense fallback={fallback()}>
                    <Component config={config} {...this.props}/>
                </Suspense>
            )
        }
    }    
}