import Api from 'utils/Http'

export const address = { 
    retrieveList : (store, options = {}) => {
        const authToken = store.auth.authToken
        return Api.get('/addresses', {
            headers: {
                Authorization: authToken,
            }
        })
    }
}