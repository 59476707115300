import Api from 'utils/Http'
import { openToastr } from './toastr.action'
import * as Services from './../../services';
/**
 * ACCOUNT ACTIONS
 */
export const accountLoading = (loading = false) => ({
	type: 'ACCOUNT_LOADING',
	loading,
})

export const accountSuccess = (success = false) => ({
	type: 'ACCOUNT_SUCCESS',
	success,
})

export const accountFail = (fail = false) => ({
	type: 'ACCOUNT_FAIL',
	fail,
})

export const setAuthToken = (authToken = '') => ({
	type: 'ACCOUNT_SET_AUTH_TOKEN',
	authToken,
})

export const accountSetData = (user = {}) => ({
	type: 'ACCOUNT_SET_USER_DATA',
	user,
})

export const accountSetCurrentEmployee = (user = {}) => ({
		type: 'ACCOUNT_SET_CURRENT_EMPLOYEE',
		user,
	})

	export const accountSetCurrentPatient = (user = {}) => ({
		type: 'ACCOUNT_SET_CURRENT_PATIENT',
		user,
	})

export const accountSetACL = (acl = {}) => ({
		type: 'ACCOUNT_SET_USER_DATA',
		acl,
	})

export const accountCheckPassword = (check = false) => ({
	type: 'ACCOUNT_CHECK_PASSWORD',
	check,
})

export const accountCheckEmailExist = (check = true) => ({
	type: 'ACCOUNT_CHECK_EMAIL_EXIST',
	check,
})

export const getUserData = () => {
	return (dispatch, getState) => {
		const store = getState()
		const authToken = store.auth.authToken
		console.log('account.action getUserData', store);
		dispatch(accountLoading(true))
		return Api.get('/users/me', {
			headers: {
				Authorization: authToken,
			},
		})
		.then((res) => {
			console.log('account.action getUserData', res)
			dispatch(accountLoading(false))
			dispatch(accountSuccess(true))
			dispatch(accountSetData(res.data))
			let store = getState()
			
			Services.patient.current(getState(), {}).then((res) => {
				if(Array.isArray(res.data) && res.data.length ){
					let user = Object.assign({}, store.account.user, {patient: res.data[0]});
					if(user.patient && user.patient.id){
						dispatch(accountSetCurrentPatient(user));
					}
				}
			});

			return res
		})
		.catch((err) => {
			// dispatch(accountLoading(false))
			dispatch(accountFail(true))
			return err.response
		})
	}
}

export const getUserACL = () => {
		return (dispatch, getState) => {
			const store = getState()
			const authToken = store.account.authToken
			const role = store.account.user.role
			dispatch(accountLoading(true))
			return Api.get('/acls?filter[where][principalType]=ROLE&filter[where][principalId]=' + role, {
				headers: {
					Authorization: authToken,
				},
			})
			.then((res) => {
				dispatch(accountLoading(false))
				dispatch(accountSuccess(true))
				dispatch(accountSetACL(res.data))
				return res
			})
			.catch((err) => {
				// dispatch(accountLoading(false))
				dispatch(accountFail(true))
				return err.response
			})
		}
	}

export const accountStartCheckEmailExist = (email) => {
	return (dispatch, getState) => {
		const store = getState()
		const authToken = store.account.authToken
		dispatch(accountLoading(true))
		return Api.get(`/user/email/${email}/exist`, {
			headers: {
				Authorization: authToken,
			},
		})
		.then((res) => {
			dispatch(accountFail(false))
			dispatch(accountLoading(false))
			dispatch(accountSuccess(true))
			dispatch(accountCheckEmailExist(res.data.exist))
			return res
		})
		.catch((err) => {
			dispatch(accountFail(true))
			dispatch(accountLoading(false))
			dispatch(accountSuccess(false))
			dispatch(accountCheckEmailExist(err.response.data.exist))
			return err.response
		})
	}
}

export const accountStartCheckPassword = (userId, password) => {
	return (dispatch, getState) => {
		const store = getState()
		const authToken = store.account.authToken
		dispatch(accountLoading(true))
		return Api.post(`/user/${userId}/check-password`, { password }, {
			headers: {
				Authorization: authToken,
			},
		})
		.then((res) => {
			dispatch(accountFail(false))
			dispatch(accountLoading(false))
			dispatch(accountSuccess(true))
			dispatch(accountCheckPassword(res.data.success))
			return res
		})
		.catch((err) => {
			console.log(err.response)
			dispatch(accountFail(true))
			dispatch(accountLoading(false))
			dispatch(accountSuccess(false))
			dispatch(accountCheckPassword(false))
			return err.response
		})
	}
}

export const accountStartChangePassword = (userId, password, newPassword) => {
	return (dispatch, getState) => {
		const store = getState()
		const authToken = store.account.authToken
		dispatch(accountLoading(true))
		return Api.post(`/user/${userId}/change-password`, { password, newPassword }, {
			headers: {
				Authorization: authToken,
			},
		})
		.then((res) => {
			dispatch(accountFail(false))
			dispatch(accountLoading(false))
			dispatch(accountSuccess(true))
			dispatch(accountSetData(res.data.user))
			dispatch(setAuthToken(res.data.token))
			dispatch(accountCheckPassword(null))
			dispatch(openToastr('success', 'Password Changed!'))
			return res
		})
		.catch((err) => {
			dispatch(accountFail(true))
			dispatch(accountLoading(false))
			dispatch(accountSuccess(false))
			dispatch(accountCheckPassword(null))
			dispatch(openToastr('error', 'Error changing password!'))
			return err.response
		})
	}
}

export const accountStartChangeEmail = (userId, email) => {
	return (dispatch, getState) => {
		const store = getState()
		const authToken = store.account.authToken
		dispatch(accountLoading(true))
		return Api.post(`/user/${userId}/change-email`, { email }, {
			headers: {
				Authorization: authToken,
			},
		})
		.then((res) => {
			dispatch(accountFail(false))
			dispatch(accountLoading(false))
			dispatch(accountSuccess(true))
			dispatch(accountSetData(res.data.user))
			dispatch(accountCheckEmailExist(null))
			dispatch(openToastr('success', 'Email Changed!'))
			return res
		})
		.catch((err) => {
			dispatch(accountFail(true))
			dispatch(accountLoading(false))
			dispatch(accountSuccess(false))
			dispatch(accountCheckEmailExist(null))
			dispatch(openToastr('error', 'Error changing email!'))
			return err.response
		})
	}
}
