import React, { PureComponent } from "react";

import {
    Grid,
    Row,
    Col,
    FormGroup,
    FormControl,
    InputGroup,
    Table,
    ButtonToolbar,
    ToggleButtonGroup ,
    ToggleButton,
    DropdownButton,
    MenuItem,
    Glyphicon
} from "react-bootstrap";

import Button from "common/components/CustomButton.jsx";
import { BeatLoader } from 'react-spinners';
import url from "./../../utils/url"
import history from "./../../history"

class DocumentListFinder extends PureComponent {

    constructor(props){
        super(props);

        this.handleSearch = this.handleSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSortItemOption = this.handleSortItemOption.bind(this);
        this.handleSortMode = this.handleSortMode.bind(this);
        this.state = {
            finder: {
                query: "",
                sortLabel: "",
                sortAttribute: "",
                sortMode: 0
            }
        }
    }

    componentDidMount() {
        console.log('DocumentListFinder.componentDidMount',this.props);

        if(this.props.location && this.props.location.search){
            
            let pageParams = url.urlSearchParams(this.props.location.search);
            let query = pageParams.q || "";      
            this.setState((state) => {
                return Object.assign({}, this.state, 
                {
                    finder: {
                        query: query
                    }
                });
            });  
        }

    }

    handleSortItemOption(index){
        console.log('DocumentListFinder handleSortItemOption', index);
        let { documentSettings } = this.props;
        let label = "", attr = "";
        if (documentSettings && documentSettings.view && documentSettings.view.headers 
            && documentSettings.view.headers.labels && documentSettings.view.headers.labels[index]){
            label = documentSettings.view.headers.labels[index];
            label = label.length > 10 ? label.substring(0,9)+"..." : label;
        }
        if (documentSettings && documentSettings.view && documentSettings.view.headers 
            && documentSettings.view.headers.attributes && documentSettings.view.headers.attributes[index]){
            attr = documentSettings.view.headers.attributes[index];
            if(typeof attr !== 'string'){
                if(attr['attribute']){
                    attr = attr['attribute']
                }else{
                    attr = "";
                }
            }
        }
        this.setState(state=>{
            return Object.assign({}, state, {
                sortAttribute: attr,
                sortLabel: label
            })
        })
    }

    handleSortMode(mode){
        console.log('DocumentListFinder handleSortMode', mode);
        this.setState(state=>{
            return Object.assign({}, state, {
                sortMode: mode
            })
        })
    }

    handleChange(e){
        const { name, value } = e.target;
        this.setState((state) => {
            return Object.assign({}, this.state, 
            {
                finder: {
                    query: value
                }
            });
        });        
    }

    handleSearch(e){
        history.replace(this.props.documentSettings.routes.local.path + "?q=" + this.state.finder.query);
        let finder = {
            query: this.state.finder.query,
        };
        if(this.state.sortAttribute){
            finder['order'] = this.state.sortAttribute +  (this.state.sortMode ? " DESC":" ASC");
        }
        this.props.retrieveDocumentList(this.props.documentSettings,{ 
            finder: finder
        });
    }

    render(){
        let { documentSettings } = this.props;
        console.log("DocumentListFinder ", this.props);
        return (
            <Table striped hover style={{marginBottom: 0 + "px"}}>
                <thead>
                    <tr>
                        <td>
                            <Grid fluid>
                                <Row>
                                    <Col md={8} lg={9}>
                                        <FormGroup>
                                            <InputGroup>
                                                <InputGroup.Addon className="btn-info">
                                                    <Glyphicon glyph="search" />
                                                </InputGroup.Addon>
                                                <FormControl 
                                                        name="search"
                                                        type="text"
                                                        placeholder="Search..." 
                                                        value={this.state.finder.query}
                                                        onChange={this.handleChange}
                                                    />
                                                <InputGroup.Button>
                                                    <Button bsStyle="info" fill className="btn-fill"  onClick={this.handleSearch} style={{width:"100px"}}>
                                                        {
                                                            !this.props.fetching ? "Search":""
                                                        }
                                                        <BeatLoader
                                                            sizeUnit={"px"}
                                                            size={8}
                                                            color={'#e3e3e5'}
                                                            loading={this.props.fetching}
                                                        />
                                                    </Button>
                                                </InputGroup.Button>
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4} lg={3}>
                                        <FormGroup>
                                            <ButtonToolbar className="pull-right">
                                            <ToggleButtonGroup name="sortMode" value={this.state.sortMode} onChange={this.handleSortMode} defaultValue={0}>
                                                <DropdownButton bsSize="small"title={this.state.sortLabel || 'Sort'} id="bg-nested-dropdown" bsStyle="info" fill className="btn-fill">
                                                    {
                                                        documentSettings && documentSettings.view && documentSettings.view.headers && documentSettings.view.headers.labels ?
                                                        documentSettings.view.headers.labels.map((label, key)=>{
                                                            return ( 
                                                                <MenuItem eventKey={key} onClick={()=>this.handleSortItemOption(key)}>{label}</MenuItem>
                                                            )
                                                        })
                                                        :
                                                        <MenuItem eventKey="id">Document Id</MenuItem>
                                                    }
                                                </DropdownButton>
                                                <ToggleButton bsSize="small" bsStyle="info" fill className="btn-fill" value={0} >ASC</ToggleButton>
                                                <ToggleButton bsSize="small" bsStyle="info" fill className="btn-fill" value={1} >DESC</ToggleButton>
                                            </ToggleButtonGroup>
                                            </ButtonToolbar>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Grid>
                        </td>
                    </tr>
                </thead>
            </Table>
        )
    }

}

export default DocumentListFinder;