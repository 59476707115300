import React from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { BeatLoader } from 'react-spinners';

export const fallback = ()=>{
    return(
        <div className="content">
            <Grid fluid>
                <Row>
                    <Col md={12}>
                        <BeatLoader
                            sizeUnit={"px"}
                            size={15}
                            color={'#e3e3e5'}
                            loading={true}
                        />
                    </Col>
                </Row>
            </Grid>
        </div>
    )
}