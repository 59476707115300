/**
 * Address List
 */
export const addressList = {
    documentType: "address",
    view: {
        headers: {
            labels: ["Address1","City","State","Country","Zip"],
            attributes: ["address1","city","state","country","zip"]
        },
        body: {
            data:[]
        }
    },
    routes: {
        local: {
            path: "/address",
            name: "Addresses",
            icon: "pe-7s-graph",
            config: "addressList"
        }
    }
}