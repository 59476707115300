import Api from 'utils/Http';
import * as Services from "services"
import * as Settings from "settings";
import moment from "moment";

export const office = {
    valueConverter: {

        convertByAttribute: (model, attribute) => {
            let statuses = {
                0: "Inactive",
                1: "Active"
            }
            switch(attribute){
                case 'status': return statuses[model[attribute]];
                default: return model[attribute];
            }
        }
    },

    retrieveList : (store, options = {}) => {
        return Services.document.retrieveList(store, Object.assign({}, options, {
            documentType: Settings.office.documentType
        }));
    },
}