/** eventsService */
export const event = {
    callbacks: {},
  
    /**
     * @param {string} eventName
     * @param {*} data
     */
    triggerEvent(eventName, data = null) {
        if (this.callbacks[eventName]) {
            Object.keys(this.callbacks[eventName]).forEach((id) => {
                this.callbacks[eventName][id](data);
            });
        }
    },
  
    /**
     * @param {string} eventName name of event
     * @param {string} id callback identifier
     * @param {Function} callback
     */
    listenEvent(eventName, id, callback) {
        if (!this.callbacks[eventName]) {
            this.callbacks[eventName] = {}
        }
        this.callbacks[eventName][id] = callback;
    },
  
    /**
     * @param {string} eventName name of event
     * @param {string} id callback identifier
     */
    unlistenEvent(eventName, id) {
        delete this.callbacks[eventName][id];
    },
}