import React from "react";

import {withDocument} from "common/containers/withDocument.jsx";
import {withDocumentList} from "common/containers/withDocumentList.jsx";

const Department = React.lazy(()=>import( "company/department/components/Department" ));
const Currency = React.lazy(()=>import( "company/currency/components/Currency" ));
const Office = React.lazy(()=>import( "company/office/components/Office" ));

var companyRoutes = [
    { path: "/offices/:id", name: "Office", component: withDocument(Office, "office") },
    { path: "/offices", name: "Office", component: withDocumentList("officeList") },
    
    { path: "/departments/:id", name: "Department", component: withDocument(Department, "department") },
    { path: "/departments", name: "Department", component: withDocumentList("departmentList") },

    { path: "/currencies/:id", name: "Currency", component: withDocument(Currency, "currency") },
    { path: "/currencies", name: "Currency", component: withDocumentList("currencyList") },
]

export default companyRoutes;