import React, { Component } from "react";
import { connect } from 'react-redux';

import DocumentCrud from "common/components/DocumentCrud";

import * as Settings from "settings";

import * as documentActions from "common/actions/document.action";
import * as toastrActions from "common/actions/toastr.action";

class Document extends Component {

    documentSettings = {}

    eventHandlers = {}

    constructor(props) {        
        super(props);
        if(props.config && Settings[props.config]){
            this.documentSettings = Settings[props.config];
        }       
        console.log('Document state', this.state); 
    }

    componentDidMount() {
        console.log('Document componentDidMount',this.props)
        if(this.props.match.params.id > 0){
            this.props.retrieveDocument(this.props.match.params.id, this.documentSettings);
        }else{
            this.props.newDocument(this.documentSettings);
        }
    }

    documentComponent(){
        let {handleDocumentChange} = this.props;
        return (
            <this.props.document 
                documentSettings={this.documentSettings} 
                handleDocumentChange={handleDocumentChange}
                eventHandlers={this.eventHandlers}
            />
        )
    }

    documentCrudComponent(){
        let {newDocument, retrieveDocument, saveDocument} = this.props;
        return (
            <DocumentCrud 
                documentSettings={this.documentSettings} 
                newDocument={newDocument}
                retrieveDocument={retrieveDocument}
                saveDocument={saveDocument}
                eventHandlers={this.eventHandlers}
            />                
        )        
    }

    render(){
        return(
            <div className="content">
            { this.documentComponent() }
            { this.documentCrudComponent() }
            </div>
        );
    }

}


const mapActionsToProps = {
    ...documentActions, ...toastrActions
};


export default connect(null, mapActionsToProps)(Document);