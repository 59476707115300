import httpService from './../../utils/httpService';

import * as Services from "./../../services"

import history from './../../history';

/**
 * AUTH ACTIONS
 */
export const authUserAuthenticating = (authenticating = false) => ({
    type: 'AUTH_USER_AUTHENTICATING',
    authenticating,
  })
  
  export const authUserAuthenticationSuccess = (success = false) => ({
    type: 'AUTH_USER_AUTHENTICATION_SUCCESS',
    success,
  })
  
  export const authUserAuthenticationFail = (fail = false) => ({
    type: 'AUTH_USER_AUTHENTICATION_FAIL',
    fail,
  })
  
  export const authUserAuthToken = (authToken = '') => ({
    type: 'AUTH_USER_SET_ACCESS_TOKEN',
    authToken,
  })
  
  export const authUserSetData = (user = {}) => ({
    type: 'AUTH_SET_USER_DATA',
    user,
  })

  export const authUserLogout = () => ({
    type: 'AUTH_LOGOUT',
  })

  export const authUserRegistering = (registering = false) => ({
    type: 'AUTH_USER_REGISTERING',
    registering,
  })
  
  export const authUserRegistered = (registered = false) => ({
    type: 'AUTH_USER_REGISTERED',
    registered,
  })

  export const authUserRegistrationFail = (registrationFail = false) => ({
    type: 'AUTH_USER_REGISTRATION_FAIL',
    registrationFail,
  })

  export const login = (email, password) => {
    return (dispatch, getState) => {
      const store = getState()
      dispatch(authUserAuthenticating(true))
      return Services.user.login(email, password)
      .then((res) => {
        console.log('auth.action login', res.data)
        dispatch(authUserAuthenticating(false))
        dispatch(authUserAuthenticationSuccess(true))
        dispatch(authUserAuthToken(res.data.token))
        dispatch(authUserSetData(res.data))
        console.log('history', history);
        history.push('/');

        httpService.setupRequestInterceptors(getState());

        return res
      })
      .catch((err) => {
        dispatch(authUserAuthenticating(false))
        dispatch(authUserAuthenticationFail(true))
        dispatch(authUserAuthToken(''))  
    
        return err.response
      })
    }
  }

  export const google = (google) => {
    return (dispatch, getState) => {
        const store = getState()
        dispatch(authUserAuthenticating(true))
        return Services.user.google(google)
        .then((res) => {
          dispatch(authUserAuthenticating(false))
          dispatch(authUserAuthenticationSuccess(true))
          dispatch(authUserAuthToken(res.data.id))
          dispatch(authUserSetData(res.data))
          httpService.setupRequestInterceptors(getState());

          console.log('history', history);
          history.push('/');
  
          return res
        })
        .catch((err) => {
          dispatch(authUserAuthenticating(false))
          dispatch(authUserAuthenticationFail(true))
          dispatch(authUserAuthToken(''))  
      
          return err.response
        })
      }      
  }

  export const sso = (token) => {
    return (dispatch, getState) => {
      const store = getState()
      dispatch(authUserAuthenticating(true))
      return Services.user.sso(token)
      .then((res) => {
        console.log('auth.action sso', res.data)
        dispatch(authUserAuthenticating(false))
        dispatch(authUserAuthenticationSuccess(true))
        dispatch(authUserAuthToken(res.data.token))
        dispatch(authUserSetData(res.data))
        history.push('/');
        httpService.setupRequestInterceptors(getState());

        return res
      })
      .catch((err) => {
        dispatch(authUserAuthenticating(false))
        dispatch(authUserAuthenticationFail(true))
        dispatch(authUserAuthToken(''))  
    
        return err.response
      })
    }
  }

  export const logout = () => {
    return (dispatch, getState) => {
      const originalState = getState()
      dispatch(authUserLogout())
      dispatch(authUserAuthToken())
      dispatch(authUserSetData(null)) 
      httpService.setupRequestInterceptors(getState());
      const promise = new Promise((resolve, reject) => {
        const state = getState()
        if (!state.account || !state.account.authToken || !state.account.authToken.length) {
          resolve({
            status: 200,
            success: true,
            message: `User ${originalState.account.user.username} logged off!`,
          })
        } else {
          reject({
            status: 500,
            success: false,
            message: `Error loggin of user: ${originalState.account.user.username}`,
          })
        }
      })
      return promise
    }
  }

  export const register = (user) => {
    return (dispatch, getState) => {
      const store = getState()
      dispatch(authUserRegistering(true))
      dispatch(authUserRegistering(false))
      dispatch(authUserRegistered(false))
      dispatch(authUserRegistrationFail(false))
      return Services.user.register(user)
      .then((res) => {
        dispatch(authUserRegistering(false))
        dispatch(authUserRegistered(true))
        dispatch(authUserAuthToken(''))  
        dispatch(authUserSetData(res.data))
        console.log('history', history);
        history.push('/registration/welcome');
        httpService.setupRequestInterceptors(getState());

        //raise userRegistered event
        Services.event.triggerEvent("userRegistered", res.data);

        return res
      })
      .catch((err) => {
        dispatch(authUserRegistering(false))
        dispatch(authUserRegistered(false))
        dispatch(authUserRegistrationFail(true))
        dispatch(authUserAuthToken(''))  
    
        return err.response
      })
    }
  }