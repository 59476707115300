import React, { PureComponent } from "react";
import { connect } from 'react-redux';

import {
    Grid,
    Row,
    Col
} from "react-bootstrap";

import { Card } from "common/components/Card.jsx";
import { FormInputs } from "common/components/FormInputs.jsx";
import {Tabs, Tab} from 'react-bootstrap-tabs';
import  RecordNavigator  from "common/components/RecordNavigator.jsx";

import ACLRoleInformation from "./ACLRoleInformation";
import ACLRoleAccessDetail from "./ACLRoleAccessDetail";

class ACLRole extends PureComponent {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e){
        console.log('handleChange', e);
        const { name, value } = e.target;
        this.props.handleDocumentChange(this.props.documentSettings, { data: {[name]:value} });
    }

    render() {
        
        return (
            <div className="content">

                <Grid fluid>
                <Row>
                <Col md={12}>
                    <Card
                    title="Role"
                    menu={
                        <RecordNavigator {...this.props}/>
                    }
                    contentStyle={{padding:0}}
                    content={
                        <form>

                        <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
                            <Tab label="Role">
                                <ACLRoleInformation 
                                    handleChange={this.handleChange}
                                    {...this.props}
                                />
                            </Tab>
                            <Tab label="Access Control">
                                <ACLRoleAccessDetail
                                    handleChange={this.handleChange}
                                    {...this.props}
                                />
                            </Tab>                                
                        </Tabs>

                        <div className="clearfix" />            
                        </form>
                    }
                    />
                </Col>
                </Row>
                </Grid>

            </div>
        )

    }
}


export default ACLRole;