import React, { PureComponent } from "react";

import {
    Grid,
    Row,
    Col,
    FormGroup,
    FormControl,
    Table
} from "react-bootstrap";

import Button from "common/components/CustomButton.jsx";
import { BeatLoader } from 'react-spinners';
import * as Services from "services";

class FinderFilter extends PureComponent {

    constructor(props){
        super(props);

        this.handleSearch = this.handleSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            finder: {
                searching: false,
                query: ""
            }
        }
    }

    componentDidMount() {
        console.log('Finder Filter.componentDidMount',this.props);
    }

    handleChange(e){
        const { name, value } = e.target;
        this.setState((state) => {
            return Object.assign({}, this.state, 
            {
                finder: {
                    query: value
                }
            });
        });        
    }

    handleSearch(e){
        Services.event.triggerEvent("onFinderSearch",{query: this.state.finder.query})
    }

    render(){

        console.log("FinderFilter render ", this.props);
        return (
            <Table striped hover style={{marginBottom: 0 + "px"}}>
                <thead>
                    <tr>
                        <td>
                            <Grid fluid>
                                <Row>
                                    <Col md={10}>
                                        <FormGroup>    
                                            <FormControl 
                                                name="search"
                                                type="text"
                                                placeholder="Search..." 
                                                value={this.state.finder.query}
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <Button bsStyle="primary" fill className="btn-fill"  onClick={this.handleSearch} style={{width:130+"px"}}>
                                            {
                                                !this.state.finder.searching ? "Search":""
                                            }
                                            {/*
                                            <BeatLoader
                                                sizeUnit={"px"}
                                                size={15}
                                                color={'#e3e3e5'}
                                                loading={this.state.finder.searching}
                                            />*/}
                                        </Button>
                                    </Col>
                                </Row>
                            </Grid>
                        </td>
                    </tr>
                </thead>
            </Table>
        )
    }

}

export default FinderFilter;