import * as Services from './../../services';
import {openToastr} from './toastr.action';
/**
 * DOCUMENT ACTIONS
 */
export const documentActionInProgress = (actionInProgress = false) => ({
    type: 'DOCUMENT_ACTION_IN_PROGRESS',
    actionInProgress,
})

export const documentFetching = (fetching = false) => ({
    type: 'DOCUMENT_FETCHING',
    fetching,
})

export const documentFetchingFirst = (fetchingFirst = false) => ({
    type: 'DOCUMENT_FETCHING_FIRST',
    fetchingFirst,
})

export const documentFetchingPrev = (fetchingPrev = false) => ({
    type: 'DOCUMENT_FETCHING_PREV',
    fetchingPrev,
})

export const documentFetchingNext = (fetchingNext = false) => ({
    type: 'DOCUMENT_FETCHING_NEXT',
    fetchingNext,
})

export const documentFetchingLast = (fetchingLast = false) => ({
    type: 'DOCUMENT_FETCHING_LAST',
    fetchingLast,
})

export const documentFetchSuccess = (success = false) => ({
    type: 'DOCUMENT_FETCH_SUCCESS',
    success,
})

export const documentActionSuccess = (success = false) => ({
    type: 'DOCUMENT_ACTION_SUCCESS',
    success,
})

export const documentActionFail = (fail = true) => ({
    type: 'DOCUMENT_ACTION_FAIL',
    fail,
})

export const documentFetchFail = (fail = true) => ({
    type: 'DOCUMENT_FETCH_FAIL',
    fail,
})

export const documentSetData = (documentData = {}) => ({
    type: 'DOCUMENT_SET_DATA',
    documentData
})

export const documentCrudSetState = (documentCrud = {}) => ({
    type: 'DOCUMENT_CRUD_SET_STATE',
    documentCrud
})

export const documentSetPrimary = (primaryKey = {}) => ({
    type: 'DOCUMENT_SET_PRIMARY',
    primaryKey
})

export const documentSetError = (error = {}) => ({
    type: 'DOCUMENT_SET_ERROR',
    error
})

export const retrieveDocument = (id, documentSettings = {}, options = {}) => {

    console.log('document.action::retrieveDocument', id, documentSettings)
    //const serviceProvider = Services[documentSettings.documentType];

    if(id){
        const serviceProvider = Services.document;
        return (dispatch, getState) => {
            dispatch(documentFetching(true))
            return serviceProvider.retrieve(id, getState(), {
                documentType: documentSettings.documentType, 
                routes: (documentSettings.routes || {})
            })
            .then((res) => {
                dispatch(documentFetchSuccess(true))
                dispatch(documentSetError({}))
                dispatch(documentSetData(res.data))
                dispatch(documentCrudSetState(null))
                dispatch(documentSetPrimary({id: res.data.id}))

                //call serviceprovider after retrieve handler
                serviceProvider.afterRetrieve(getState(), {
                    getState: getState,
                    documentType: documentSettings.documentType, 
                    documentSettings: documentSettings,
                    dispatch: dispatch,
                    handleDocumentChange: handleDocumentChange,
                    handleDocumentCrudStateChange: handleDocumentCrudStateChange,
                    newDocument: newDocument,
                    retrieveDocument: retrieveDocument
                });

                if(options.next){
                    options.next(getState())
                }

                return res
            })
            .catch((err) => {
                dispatch(documentFetchSuccess(false))
                dispatch(documentSetData({}))
                dispatch(documentSetError(err.response))
            })
        }
    }

    return newDocument();

}

export const retrieveFirstDocument = (documentSettings = {}, options = {}) => {

    console.log('document.action::retrieveDocument')
    //const serviceProvider = Services[documentSettings.documentType];

    const serviceProvider = Services.document;
    return (dispatch, getState) => {
        dispatch(documentFetchingFirst(true))
        return serviceProvider.retrieveFirst(getState(), {
            documentType: documentSettings.documentType, 
            routes: (documentSettings.routes || {})
        })
        .then((res) => {
            let data = Array.isArray(res.data) && res.data.length ? res.data[0] : res.data
            dispatch(documentFetchSuccess(true))
            dispatch(documentSetError({}))
            dispatch(documentSetData(data))
            dispatch(documentCrudSetState(null))
            if(data.id){
                dispatch(documentSetPrimary({id: data.id}))
            }else{
                //dispatch(documentSetPrimary(null))
                throw new Error('Data not valid after navigating document.')
            }

            //call serviceprovider after retrieve handler
            serviceProvider.afterRetrieve(getState(), {
                getState: getState,
                documentType: documentSettings.documentType, 
                documentSettings: documentSettings,
                dispatch: dispatch,
                handleDocumentChange: handleDocumentChange,
                handleDocumentCrudStateChange: handleDocumentCrudStateChange,
                newDocument: newDocument,
                retrieveDocument: retrieveDocument
            });

            if(options.next){
                options.next(getState())
            }

            return res
        })
        .catch((err) => {
            dispatch(documentFetchSuccess(false))
            dispatch(documentSetData({}))
            return newDocument(documentSettings, options)(dispatch, getState);
        })
    }

}

export const retrievePreviousDocument = (documentSettings = {}, options = {}) => {

    console.log('document.action::retrieveDocument')
    //const serviceProvider = Services[documentSettings.documentType];

    const serviceProvider = Services.document;
    return (dispatch, getState) => {
        dispatch(documentFetchingPrev(true))
        return serviceProvider.retrievePrev(getState(), {
            documentType: documentSettings.documentType, 
            routes: (documentSettings.routes || {})
        })
        .then((res) => {
            let data = Array.isArray(res.data) && res.data.length ? res.data[0] : res.data
            dispatch(documentFetchSuccess(true))
            dispatch(documentSetError({}))
            dispatch(documentSetData(data))
            dispatch(documentCrudSetState(null))
            if(data.id){
                dispatch(documentSetPrimary({id: data.id}))
            }else{
                //dispatch(documentSetPrimary(null))
                throw new Error('Data not valid after navigating document.')
            }

            //call serviceprovider after retrieve handler
            serviceProvider.afterRetrieve(getState(), {
                getState: getState,
                documentType: documentSettings.documentType, 
                documentSettings: documentSettings,
                dispatch: dispatch,
                handleDocumentChange: handleDocumentChange,
                handleDocumentCrudStateChange: handleDocumentCrudStateChange,
                newDocument: newDocument,
                retrieveDocument: retrieveDocument
            });

            if(options.next){
                options.next(getState())
            }
            
            return res
        })
        .catch((err) => {
            dispatch(documentFetchSuccess(false))
            dispatch(documentSetData({}))
            return retrieveLastDocument(documentSettings, options)(dispatch, getState);
        })
    }

}

export const retrieveNextDocument = (documentSettings = {}, options = {}) => {

    console.log('document.action::retrieveDocument')
    //const serviceProvider = Services[documentSettings.documentType];

    const serviceProvider = Services.document;
    return (dispatch, getState) => {
        dispatch(documentFetchingNext(true))
        return serviceProvider.retrieveNext(getState(), {
            documentType: documentSettings.documentType, 
            routes: (documentSettings.routes || {})
        })
        .then((res) => {
            let data = Array.isArray(res.data) && res.data.length ? res.data[0] : res.data
            dispatch(documentFetchSuccess(true))
            dispatch(documentSetError({}))
            dispatch(documentSetData(data))
            dispatch(documentCrudSetState(null))
            if(data.id){
                dispatch(documentSetPrimary({id: data.id}))
            }else{
                //dispatch(documentSetPrimary(null))
                throw new Error('Data not valid after navigating document.')
            }

            //call serviceprovider after retrieve handler
            serviceProvider.afterRetrieve(getState(), {
                getState: getState,
                documentType: documentSettings.documentType, 
                documentSettings: documentSettings,
                dispatch: dispatch,
                handleDocumentChange: handleDocumentChange,
                handleDocumentCrudStateChange: handleDocumentCrudStateChange,
                newDocument: newDocument,
                retrieveDocument: retrieveDocument
            });

            if(options.next){
                options.next(getState())
            }
            
            return res
        })
        .catch((err) => {
            dispatch(documentFetchSuccess(false))
            dispatch(documentSetData({}))
            return retrieveFirstDocument(documentSettings, options)(dispatch, getState);
        })
    }

}

export const retrieveLastDocument = (documentSettings = {}, options = {}) => {

    console.log('document.action::retrieveDocument')
    //const serviceProvider = Services[documentSettings.documentType];

    const serviceProvider = Services.document;
    return (dispatch, getState) => {
        dispatch(documentFetchingLast(true))
        return serviceProvider.retrieveLast(getState(), {
            documentType: documentSettings.documentType, 
            routes: (documentSettings.routes || {})
        })
        .then((res) => {
            let data = Array.isArray(res.data) && res.data.length ? res.data[0] : res.data
            dispatch(documentFetchSuccess(true))
            dispatch(documentSetError({}))
            dispatch(documentSetData(data))
            dispatch(documentCrudSetState(null))
            if(data.id){
                dispatch(documentSetPrimary({id: data.id}))
            }else{
                //dispatch(documentSetPrimary(null))
                throw new Error('Data not valid after navigating document.')
            }

            //call serviceprovider after retrieve handler
            serviceProvider.afterRetrieve(getState(), {
                getState: getState,
                documentType: documentSettings.documentType, 
                documentSettings: documentSettings,
                dispatch: dispatch,
                handleDocumentChange: handleDocumentChange,
                handleDocumentCrudStateChange: handleDocumentCrudStateChange,
                newDocument: newDocument,
                retrieveDocument: retrieveDocument
            });

            if(options.next){
                options.next(getState())
            }
            
            return res
        })
        .catch((err) => {
            dispatch(documentFetchSuccess(false))
            dispatch(documentSetData({}))
            return newDocument(documentSettings, options)(dispatch, getState);
        })
    }

}

export const saveDocument = (documentSettings = {}, options = {}) => {

    console.log('document.action::saveDocument')
    const serviceProvider = Services.document;
    return (dispatch, getState) => {
        let store = getState();
        let primaryKey = store.document.primaryKey;
        dispatch(documentActionInProgress(true))

        let beforeSave = serviceProvider.beforeSave(store, {
            getState: getState,
            documentType: documentSettings.documentType, 
            documentSettings: documentSettings,
            dispatch: dispatch,
            handleDocumentChange: handleDocumentChange,
            handleDocumentCrudStateChange: handleDocumentCrudStateChange,
            newDocument: newDocument,
            retrieveDocument: retrieveDocument
        });

        if(beforeSave.success){
            return serviceProvider.save(store, {documentType: documentSettings.documentType})
            .then((res) => {
                dispatch(documentActionSuccess(true))
                dispatch(documentSetPrimary({id: res.data.id}))
                dispatch(documentSetData(Object.assign({},store.document.documentData, res.data, {deletedRecords: {}})))
                dispatch(documentCrudSetState(null))
                
                serviceProvider.afterSave(store, {
                    getState: getState,
                    documentType: documentSettings.documentType, 
                    documentSettings: documentSettings,
                    dispatch: dispatch,
                    handleDocumentChange: handleDocumentChange,
                    handleDocumentCrudStateChange: handleDocumentCrudStateChange,
                    newDocument: newDocument,
                    retrieveDocument: retrieveDocument
                });

                if(options.next){
                    options.next(getState())
                }
                dispatch(openToastr('success',documentSettings.documentType,'Document successfully saved.'))

                return res
            })
            .catch((err) => {
                console.log('document.action save error ', err);
                dispatch(documentActionFail(true))
                dispatch(documentSetError(err.response))
                dispatch(openToastr('error',documentSettings.documentType,'Unable to save.' + 
                    err && err.response && err.response.data && err.response.data.error && err.response.data.error.message ? err.response.data.error.message: ""))
                return err.response
            })
        }else{
            dispatch(documentActionFail(true))
            let error = {error: {message: "Unable to save document as of this time."}};
            if(beforeSave.details){
                error = beforeSave.details;
            }
            dispatch(documentSetError({data: error}))
            dispatch(openToastr('error',documentSettings.documentType,'Unable to save.' + error.error.message))
            return false;
        }
    }
}

export const newDocument = (documentSettings = {}, options = {}) => {

    console.log('document.action::newDocument')
    const serviceProvider = Services.document;

    return (dispatch, getState) => {

        return serviceProvider.schema(getState(), {documentType: documentSettings.documentType})
        .then((res) => {
            let obj = serviceProvider.modelFromSchema(res.data);
            dispatch(documentSetError({}))
            dispatch(documentSetData(obj))
            dispatch(documentCrudSetState(null))
            dispatch(documentSetPrimary({id: null}))
            
            //call serviceprovider after new
            serviceProvider.afterRetrieve(getState(), {
                getState: getState,
                documentType: documentSettings.documentType, 
                documentSettings: documentSettings,
                dispatch: dispatch,
                handleDocumentChange: handleDocumentChange,
                handleDocumentCrudStateChange: handleDocumentCrudStateChange,
                newDocument: newDocument,
                retrieveDocument: retrieveDocument
            });

            if(options.next){
                options.next(getState())
            }

            dispatch(openToastr('success',documentSettings.documentType,'Successfully created new document.'))
            return res
        })
        .catch((err) => {
            dispatch(documentActionFail(true))
            dispatch(documentSetError(err.response))
            dispatch(openToastr('error',documentSettings.documentType,'Unable to create a new document.'))
            console.log(err);
            return err.response
        })
    }

}

export const duplicateDocument = (documentSettings = {}, options = {}) => {

    console.log('document.action::duplicateDocument')
    const serviceProvider = Services.document;

    return (dispatch, getState) => {

        return serviceProvider.schema(getState(), {documentType: documentSettings.documentType})
        .then((res) => {
            let obj = serviceProvider.modelFromSchema(res.data.schema);
            let store = getState();
            //current data
            let current = Object.assign({}, store.document.documentData);
            //clear id
            if(current){
                delete current.id;
            }else{
                current = obj;
            }
            //clear and set schema
            dispatch(documentSetError({}))
            dispatch(documentSetData(current))
            dispatch(documentCrudSetState(null))
            dispatch(documentSetPrimary({id: null}))
            
            //call serviceprodiver after duplicate
            if(serviceProvider.afterDuplicate){
                //call serviceprovider after new
                serviceProvider.afterDuplicate(getState(), {
                    getState: getState,
                    documentType: documentSettings.documentType, 
                    documentSettings: documentSettings,
                    dispatch: dispatch,
                    handleDocumentChange: handleDocumentChange,
                    handleDocumentCrudStateChange: handleDocumentCrudStateChange,
                    newDocument: newDocument,
                    retrieveDocument: retrieveDocument
                });
            }

            //call serviceprovider after new
            serviceProvider.afterRetrieve(getState(), {
                getState: getState,
                documentType: documentSettings.documentType, 
                documentSettings: documentSettings,
                dispatch: dispatch,
                handleDocumentChange: handleDocumentChange,
                handleDocumentCrudStateChange: handleDocumentCrudStateChange,
                newDocument: newDocument,
                retrieveDocument: retrieveDocument
            });

            if(options.next){
                options.next(getState())
            }

            dispatch(openToastr('success',documentSettings.documentType,'Successfully duplicated new document.'))
            return res
        })
        .catch((err) => {
            dispatch(documentActionFail(true))
            dispatch(documentSetError(err.response))
            dispatch(openToastr('error',documentSettings.documentType,'Unable to duplicate a new document.'))
            console.log(err);
            return err.response
        })
    }

}

export const handleDocumentChange = (documentSettings = {}, options = {}) => {

    console.log('document.action::handleDocumentChange')
    return (dispatch, getState) => {

        let state = getState();

        if(options.data){
            let d = Object.assign({}, state.document.documentData, options.data);    
            dispatch(documentSetData(d))
        }

    }
}

export const handleDocumentCrudStateChange = (documentSettings = {}, options = {}) => {

    console.log('document.action::handleDocumentCrudStateChange')
    return (dispatch, getState) => {

        let state = getState();

        if(options.state){
            let d = Object.assign({}, state.document.documentCrud, options.state);    
            dispatch(documentCrudSetState(d))
        }

    }
}

export const setPrimary = (id,  options = {}) => {
    return (dispatch, getState) => {
        dispatch(documentSetPrimary({id: id}))
        if(options.next){
            options.next(getState())
        }
    }
}