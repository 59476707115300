import React, { PureComponent } from "react";
import { connect } from 'react-redux';

import Button from "common/components/CustomButton.jsx";
import {
    Grid,
    Row,
    Col,
    ButtonGroup,
    MenuItem,
    DropdownButton
} from "react-bootstrap";

import Pagination from "react-js-pagination";
import { BeatLoader } from 'react-spinners';
import history from "./../../history"
import * as Services from './../../services';
import * as documentListActions from "common/actions/documentList.action";

class DocumentListCrud extends PureComponent {
    
    constructor(props) {
        super(props);

        this.handleNew = this.handleNew.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        console.log('DocumentCrud props', props);
    }

    handleNew(e){
        e.preventDefault();
        history.push(this.props.documentSettings.routes.local.path + "/$");
    }    

    handlePageChange(pageNumber) {
        let { listData } = this.props;
        console.log("DocumentListCrud listData", listData);
        if(listData && listData[this.props.documentSettings.documentType + "ListPagination"]){
            
            let pagination = listData[this.props.documentSettings.documentType + "ListPagination"];
            console.log("DocumentListCrud ", pagination);
            let finder = listData[this.props.documentSettings.documentType + "ListFinder"];
            let url = this.props.documentSettings.routes.local.path + "?page="+pageNumber 
                + "&pageSize=" + pagination.pageSize + "&q=" + ( finder && finder.query ? finder.query: "" );
            history.replace(url);
            this.props.retrieveDocumentList(this.props.documentSettings,{ 
                pagination: {
                    pageSize: pagination.pageSize,
                    currentPage: pageNumber-1,
                    page: pageNumber-1,
                    skip:   (pageNumber-1) * pagination.pageSize,
                    limit:  pagination.pageSize
                },
                finder: finder
            });
        }
    }

    render() {

        let {listData, documentListCrud, fetching} = this.props;
        console.log('DocumentListCrud.render', this.props);
        
        let pagination = null;
        if(listData && listData[this.props.documentSettings.documentType + "ListPagination"]){
            pagination = listData[this.props.documentSettings.documentType + "ListPagination"];
            console.log('DocumentListCrud.render pagination', pagination.totalCount, pagination.pageSize);
        }

        let customCrud = null;
        
        if(this.props.documentSettings && this.props.documentSettings.documentType && Services[this.props.documentSettings.documentType]){
            let service = Services[this.props.documentSettings.documentType];
            if(service.ui && service.ui.documentList){
                customCrud = service.ui.documentList;
            }
        }
        console.log(customCrud)
        return (
        <div className="content">
            <Grid fluid>
                <Row>
                    <Col md={2}> 
                    {
                        customCrud ? 
                        <ButtonGroup style={{width: 150 + "px", "marginTop": 20 + "px", "marginBottom": 20 + "px"}}>
                            <DropdownButton bsStyle="info" fill className="btn-fill" as={ButtonGroup} title="More Actions  " id="bg-nested-dropdown">
                                <MenuItem eventKey="new" onClick={this.handleNew}>New</MenuItem>
                                {customCrud.crud(null,"documentListCrud", this, Object.assign({}, this.props))}
                            </DropdownButton>
                        </ButtonGroup>
                        :
                        documentListCrud.new.hidden ? "" :
                        <Button bsStyle="default" fill className="btn-fill " style={{width: 150 + "px", "marginTop": 20 + "px", "marginBottom": 20 + "px"}} onClick={this.handleNew}>
                            New
                        </Button>
                    }   
                    </Col> 
                    <Col md={10}>
                        {
                        pagination && (pagination.totalCount > pagination.pageSize) ?
                        <Pagination
                            innerClass="pagination pull-right"
                            activePage={pagination.currentPage}
                            itemsCountPerPage={pagination.pageSize}
                            totalItemsCount={pagination.totalCount}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                            fetching={fetching}
                        />            
                        : ""
                        }   
                        {
                            <div className="pull-right" style={{marginTop:25+"px", marginRight:25+"px", textAlign:"right"}}>
                            <BeatLoader
                                sizeUnit={"px"}
                                size={15}
                                color={'#e3e3e5'}
                                loading={fetching}
                            />
                            </div>
                        }    
                    </Col>
                          
                </Row>
            </Grid>
        </div>
        ) 
    }
}

const mapStateToProps = (state) => ({
    listData: state.documentList.listData,
    documentListCrud: state.documentList.documentListCrud,
    fetching: state.documentList.fetching
});

const mapActionsToProps = {
    ...documentListActions
};

export default connect(mapStateToProps, mapActionsToProps)(DocumentListCrud);