/**
 * Menu List
 */
var Menu = {
    header: [
        {
            title: "Maintenance",
            defaultRoles: ['admin'],
            subMenu: [
                {
                    title: "Access Control List",
                    to: "/acls"
                },
                {
                    title: "Office",
                    to: "/offices"
                },
                {
                    title: "Department",
                    to: "/departments"
                }
            ]
        },
        {
            title: "Patient",
            defaultRoles: ['admin'],
            subMenu: [
                {
                    title: "Patient",
                    to: "/patients"
                }
            ]
        }            
    ]
}

export default Menu;