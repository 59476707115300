import React, { Component } from "react";

import { Grid, Row, Col} from "react-bootstrap";
import Card from "common/components/Card.jsx";
import FinderFilter from "finder/components/FinderFilter";
import FinderList from "finder/components/FinderList";

import * as Settings from "settings";

export class Finder extends Component {

    documentList = {}

    state = {
        documents: {}
    }

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        let documents = {}
        if(Settings){
            for(let key of Object.keys(Settings)){
                if(key.includes("List")){
                    documents[key] = Settings[key]
                }
            }
        }
        this.setState(state=>{
            return Object.assign({},state,{
                documents: documents
            })
        })
    }

    render() {
        console.log("Finder render", this.state);
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                        <Card
                                title={"Finder"}
                                category={"Universal Search"}
                                ctTableFullWidth
                                ctTableResponsive
                                content={

                                    <div>
                                        <FinderFilter />
                                        {
                                            Object.keys(this.state.documents).map((key,index)=>{
                                                return(
                                                    <FinderList documentType={key}/>
                                                )
                                            })
                                        }
                                    </div>
                                }
                        />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}
  
  export default Finder;