import React, { Component } from "react";
import { FormGroup, ControlLabel, Row } from "react-bootstrap";
import DatePickerComponent from 'react-date-picker';
import isEqual from "lodash.isequal";
import moment from 'moment';

function FieldGroup({ label, ...props }) {

    let {error} = props;
    let message = error && error.data && error.data.error && error.data.error.details && error.data.error.details.messages[props.name] ? 
        error.data.error.details.messages[props.name]: null;

    
    let val = new Date(moment(props.selectedDay || "").format("YYYY-MM-DD") || moment().format('YYYY-MM-DD'))
    console.log('DatePicker FieldGroup ',val, props.selectedDay);
    return (
        <FormGroup controlId="formControlsSelectMultiple" className={message? "has-error":""}>
            { 
                label ? <ControlLabel>{label}</ControlLabel> : ""
            }
            {
            <DatePickerComponent
                name={props.name}
                className={"form-control"}
                onChange={ (date)=>{ props.onDayChange(date, props, props.index) }}
                value={val}
                disabled={props.disabled || false}
                format={"y-MM-dd"}
                disableCalendar={props.disableCalendar || false}
            />
            }
            {
                props.error ? 
                <span className="help-block">{message}</span>
                : ""
            } 
        </FormGroup>
    );
}

export class DatePicker extends Component {

    constructor(props) {
        super(props);
		this.handleDayChange = this.handleDayChange.bind(this);
		
        this.state = {
        	selectedDay: { 0: moment().format('YYYY-MM-DD') },
        }
	
    }

    componentDidMount(){
        if(this.props.proprieties && Array.isArray(this.props.proprieties)){

            this.setState(Object.assign({}, this.state, {
                selectedDay: this.props.proprieties.reduce((acc,cur,idx)=>{
                    if(cur.value){
                        acc[idx] =  moment(cur.value).format("YYYY-MM-DD")
                    }
                    return acc;
                },{ 0: moment().format('YYYY-MM-DD') })
            }));

        }
    }

    componentDidUpdate(prevProps){

        !isEqual(prevProps.proprieties, this.props.proprieties)
        &&
        this.setState(Object.assign({}, this.state, {
            selectedDay: this.props.proprieties.reduce((acc,cur,idx)=>{
                if(cur.value){
                    acc[idx] =  moment(cur.value).format("YYYY-MM-DD")
                }
                return acc;
            },{ 0: moment().format('YYYY-MM-DD') })
        }))

        /*
        ( 
            prevProps.proprieties.length != this.props.proprieties.length || 
            prevProps.proprieties.every((value, index) => (
                this.props.proprieties[index] && value != this.props.proprieties[index]
            ))
        )
        && 
        this.setState(Object.assign({}, this.state, {
            selectedDay: this.props.proprieties.reduce((acc,cur,idx)=>{
                if(cur.value){
                    acc[idx] =  moment(cur.value).format("YYYY-MM-DD")
                }
                return acc;
            },{ 0: moment().format('YYYY-MM-DD') })
        }));*/
    }

    handleDayChange(date, props, index) {
        console.log('DatePicker selectedDay', date, props);
        date = date || new Date( moment().format('YYYY-MM-DD'))
        this.setState((state)=>{
            return Object.assign({}, state, {
                selectedDay: Object.assign({}, state.selectedDay, {
                    [index]: moment(date.toLocaleString()).format("YYYY-MM-DD")
                }) 
            })
        }, ()=> {
            props.onChange({
                target: {
                    name: props.name,
                    value: this.state.selectedDay[index],
                    getAttribute: (prop) => {
                        return props[prop];
                    }
                }
            });
        });
    }

	render() {
        console.log("DatePicker render", this.state)
		var row = [];
		for (var i = 0; i < this.props.ncols.length; i++) {
			row.push(
			<div key={i} className={this.props.ncols[i]}>
                <FieldGroup 
                    key={"datepicker-" + i}
					{...this.props.proprieties[i]} 
					onDayChange={this.handleDayChange}
                    selectedDay={this.state.selectedDay[i]}
                    index={i}
				/>
			</div>
			);
		}
		return <Row>{row}</Row>;
	}
}

export default DatePicker;