import Api from 'utils/Http'

export const patient = {


    retrieveDocumentFilters: (options) => {

        return "";

        let filters = "filter[skip]=0&filter[limit]=10";
        //pagination
        if(options.skip && options.limit){
            filters = "filter[skip]=" + options.skip + "&filter[limit]=" + options.limit
        }

        return filters;
        
    }

}