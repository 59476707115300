import React, { Component } from "react";

import Login from "./../../authentication/components/Login";
import * as Settings from "settings";

class LoginContainer extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<div id="main-panel" className="container" ref="mainPanel">
				<div className="row" style={{marginTop:80+"px"}}>
					<div className="col-lg-offset-3 col-lg-6 col-md-12">
						<img src="https://starship-live.s3.us-east-2.amazonaws.com/starship.png"   width="200px"/>
						<hr />
					</div>
					
				</div>
				<div className="row" >
					<div className="col-lg-offset-3 col-lg-6 col-md-12">
						<Login {...this.props}/>
					</div>
				</div>
				<div className="form-group col-md-12">
					<p className="copyright" style={{textAlign:"center", marginTop:30+"px"}}>
						<a href="#">{Settings.app.copyright}</a>
						&copy; {new Date().getFullYear()}{" "}
					</p>
				</div> 
			</div>
		);
	}
}

export default LoginContainer;
