import React, { PureComponent } from "react";
import { connect } from 'react-redux';

import {
    Row,
    Col,
    FormGroup,
    ButtonGroup,
    Button,
    DropdownButton,
    MenuItem,
    Glyphicon
} from "react-bootstrap";
import { BounceLoader, ClipLoader } from 'react-spinners';
import { Autocomplete } from "common/components/Autocomplete.jsx";
import * as documentActions from "common/actions/document.action";
import * as Services from './../../services';
import history from "./../../history"

class RecordNavigator extends PureComponent {
    
    constructor(props) {
        super(props);

        this.handleRefresh = this.handleRefresh.bind(this);
        this.handleFirst = this.handleFirst.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleLast = this.handleLast.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleAutocompleteSelection = this.handleAutocompleteSelection.bind(this);
        this.handleButtonStateChanged = this.handleButtonStateChanged.bind(this);
        this.handleDuplicateDocument = this.handleDuplicateDocument.bind(this);

        this.state = {
            refresh: {
                enabled: true,
                hidden: false
            },
            first: {
                enabled: true,
                hidden: false
            },
            prev: {
                enabled: true,
                hidden: false
            },
            next: {
                enabled: true,
                hidden: false
            },
            last: {
                enabled: true,
                hidden: false
            }
        }

    }

    componentWillMount(){
        if(Services["event"]){
            Services.event.listenEvent("documentCrudButtonStateChanged","DOCUMENT-CRUD", this.handleButtonStateChanged)
        }
    }

    componentWillUnmount(){
        if(Services["event"]){
            Services.event.unlistenEvent("documentCrudButtonStateChanged","DOCUMENT-CRUD")
        }
    }

    handleRefresh(e){
        e.preventDefault();
        this.props.retrieveDocument(this.props.primaryKey, this.props.documentSettings);
    }

    handleFirst(e){
        e.preventDefault();
        this.props.retrieveFirstDocument(this.props.documentSettings);
        console.log('RecordNavigator handleFirst', this.props.primaryKey);

    }  

    handlePrev(e){
        e.preventDefault();
        this.props.retrievePreviousDocument(this.props.documentSettings);
    }
     
    handleNext(e){
        e.preventDefault();
        this.props.retrieveNextDocument(this.props.documentSettings);
    }

    handleLast(e){
        e.preventDefault();
        this.props.retrieveLastDocument(this.props.documentSettings);
    }   
    
    handleBack(e){
        history.goBack();
    }

    handleDuplicateDocument(e){
        e.preventDefault();
        this.props.duplicateDocument(this.props.documentSettings);
    }

    handleAutocompleteSelection(selected, autocompleteProps){
        console.log('EmployeeBenefit selection', selected, autocompleteProps);
        if(selected && selected.id){
            this.props.retrieveDocument(selected.id, this.props.documentSettings);
        }
    }
  

    handleButtonStateChanged(e){
        if(e.state){
            this.setState((state) => {
                return Object.assign({}, state, 
                {
                    new: e.state.new || state.new,
                    save: e.state.save || state.save
                });
            });            
        }
    }

    documentCrud(){
        let customCrud = null;
        if(this.props.documentSettings && this.props.documentSettings.documentType && Services[this.props.documentSettings.documentType]){
            let service = Services[this.props.documentSettings.documentType];
            if(service.ui && service.ui.componentize){
                customCrud = service.ui.componentize;
            }
        }
        return(
            <Row>
                <Col md={12} align="">
                        <div className="col-md-3" style={{padding:"0 5 0 0", margin: "0 0 5"}}>
                            <ButtonGroup className="pull-right">
                                <Button className="btn-fill" bsStyle="default" bsSize="small">
                                    <Glyphicon glyph="time" />
                                </Button>
                                <DropdownButton className="btn-fill" bsStyle="default" bsSize="small" title="Action" id="bg-nested-dropdown">
                                    <MenuItem eventKey="1" onClick={this.handleDuplicateDocument}>Duplicate Document</MenuItem>
                                    
                                </DropdownButton>
                            </ButtonGroup>
                        </div>
                        <div className="col-md-4" style={{padding:"0 5 0 0"}}>
                        <FormGroup bsSize="small" style={{paddingLeft:0, margin:"0 0 5px"}}>
                            <Autocomplete
                                    {...this.state}
                                    label="Name"
                                    name="name"
                                    placeholder="Search"
                                    service={Services[this.props.documentSettings.documentType]}
                                    handleSelection={this.handleAutocompleteSelection}                                
                                />     
                        </FormGroup>
                        </div>
                        <div className="col-md-5" style={{padding:"0 5 0 0"}}>
                        <ButtonGroup className="pull-right">
                        {   
                            this.state.refresh.hidden ? "" :
                            <Button bsStyle="default" fill="true" className="btn-fill " 
                                bsSize="small"
                                onClick={this.handleRefresh}
                                disabled={!this.state.refresh.enabled}
                                style={{minHeight:29+"px"}}
                                >
                                {
                                    !this.props.fetching ? <Glyphicon glyph="refresh" /> : ""
                                }
                                <ClipLoader
                                    sizeUnit={"px"}
                                    size={12}
                                    color={'#e3e3e5'}
                                    loading={this.props.fetching}
                                />
                            </Button>  
                        }    
                        {   
                            this.state.first.hidden ? "" :
                            <Button bsStyle="default" fill="true" className="btn-fill " 
                                bsSize="small"
                                onClick={this.handleFirst}
                                disabled={!this.state.first.enabled}
                                style={{minHeight:29+"px"}}
                                >
                                {
                                    !this.props.fetchingFirst ? <Glyphicon glyph="fast-backward" /> : ""
                                }
                                <ClipLoader
                                    sizeUnit={"px"}
                                    size={12}
                                    color={'#e3e3e5'}
                                    loading={this.props.fetchingFirst}
                                />
                            </Button>  
                        }
                        {   
                            this.state.prev.hidden ? "" :
                            <Button bsStyle="default" fill="true" className="btn-fill " 
                                bsSize="small"
                                onClick={this.handlePrev}
                                disabled={!this.state.prev.enabled}
                                style={{minHeight:29+"px"}}
                                >
                                {
                                    !this.props.fetchingPrev ? <Glyphicon glyph="triangle-left" /> : ""
                                }
                                <ClipLoader
                                    sizeUnit={"px"}
                                    size={12}
                                    color={'#e3e3e5'}
                                    loading={this.props.fetchingPrev}
                                />
                            </Button>  
                        }
                        {   
                            this.state.next.hidden ? "" :
                            <Button bsStyle="default" fill="true" className="btn-fill " 
                                bsSize="small"
                                onClick={this.handleNext}
                                disabled={!this.state.next.enabled}
                                style={{minHeight:29+"px"}}
                                >
                                {
                                    !this.props.fetchingNext ? <Glyphicon glyph="triangle-right" /> : ""
                                }
                                <ClipLoader
                                    sizeUnit={"px"}
                                    size={12}
                                    color={'#e3e3e5'}
                                    loading={this.props.fetchingNext}
                                />
                            </Button>  
                        }
                        {   
                            this.state.last.hidden ? "" :
                            <Button bsStyle="default" fill="true" className="btn-fill "
                                bsSize="small" 
                                onClick={this.handleLast}
                                disabled={!this.state.last.enabled}
                                style={{minHeight:29+"px"}}
                                >
                                {
                                    !this.props.fetchingLast ? <Glyphicon glyph="fast-forward" /> : ""
                                }
                                <ClipLoader
                                    sizeUnit={"px"}
                                    size={12}
                                    color={'#e3e3e5'}
                                    loading={this.props.fetchingLast}
                                />
                            </Button>  
                        }
                        {
                            <Button bsStyle="default" fill="true" className="btn-fill "
                            bsSize="small" 
                            onClick={this.handleBack}
                            style={{minHeight:29+"px"}}
                            >
                                <Glyphicon glyph="arrow-left" />
                            </Button>  
                        }
                        </ButtonGroup> 
                        </div>
                    </Col>                            
            </Row>
        )
    }

    render() {

        let {actionInProgress, actionCompleted, success, error} = this.props;

        return (
        <div className="">
            { this.documentCrud() }
        </div>
        ) 
    }
}

const mapStateToProps = (state) => ({
    primaryKey: state.document.documentData.id,
    error: state.document.error,
    fetching: state.document.fetching,
    fetchingFirst: state.document.fetchingFirst,
    fetchingPrev: state.document.fetchingPrev,
    fetchingNext: state.document.fetchingNext,
    fetchingLast: state.document.fetchingLast,
    actionCompleted: state.document.actionCompleted,
    success: state.document.success
});

const mapActionsToProps = {
    ...documentActions
};

export default connect(mapStateToProps, mapActionsToProps)(RecordNavigator);