import axios from 'axios'

//baseURL: process.env.API_URL,
/*
const Api = axios.create({
  baseURL: 'http://localhost:3000/api',
});*/

//console.log(Api.interceptors);

//axios.defaults.baseURL = 'http://localhost:3150/rest/v1'

axios.defaults.baseURL = process.env.REACT_APP_STARSHIP_BASE_URL;

export default axios;