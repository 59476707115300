/**
 * Currency List
 */
export const currencyList = {
    documentType: "currency",
    view: {
        headers: {
            labels: ["Currency Name","Currency Code", "Base Value"],
            attributes: ["description","currencyCode","baseValue"]
        },
        body: {
            data:[]
        },
        pagination: {
            pageSize: 10
        },
        search: {
            attributes: ["description","currencyCode","baseValue"]
        }
    },
    routes: {
        local: {
            path: "/currencies",
            name: "Currencies",
            icon: "pe-7s-graph",
            config: "currencyList"
        }
    }
}
/**
 * Currency
 */
export const currency = {
    documentType: "currency" 
}