import React from "react";
import Api from 'utils/Http';
import moment from "moment";

import { ControlLabel } from "react-bootstrap";
import { Link } from "react-router-dom";

import DocumentListFinder from "common/components/DocumentListFinder";
import PatientListCrud from "dental/patient/components/PatientListCrud";
import PatientListInfoGraphics from "dental/patient/components/PatientListInfoGraphics";

import * as Services from "services"
import * as Settings from "settings"

export const patient = {

    validators: {
        patient: {
            last_name: [{ validator:'required' }],
            first_name: [{ validator:'required' }],
            email: [{ validator:'required' }]
        },
        hasMany: ['benefit']
    },

    valueConverter: {

        convertByAttribute: (model, attribute) => {
            switch(attribute){
                case 'patient': return model.full_name;
                case 'patientId' : return model.patient_profile ? model.patient_profile.patient_id_no : ""
                case 'birthDate': return model.patient_profile  ? moment(model.patient_profile.date_of_birth).format("MMMM D, YYYY"): '';
                case 'address': return model.patient_addresses && Array.isArray(model.patient_addresses) && model.patient_addresses.length ? 
                                    model.patient_addresses[0].address_1  + ', ' + model.patient_addresses[0].city + " " + model.patient_addresses[0].state: '';
                default: return model[attribute];
            }
        }

    },

    ui: {
        componentize: (model, attribute, container, options={}) => {

            if(model && attribute){
                switch(attribute){       
                    case 'patient':
                        return(
                            <div>
                                <img width="70" height="70" src={ (model && model.patient_profile_avatar_url ? model.patient_profile_avatar_url: null) || "/images/placeholder.png"} 
                                class="custom-logo" itemprop="logo" style={{verticalAlign:"top", border:"1px solid #dddddd"}}></img>
                                <div style={{display: "inline-block", margin: 5+"px", width: "70%"}}>
                                    <ControlLabel><Link to={"/patients/" + model.id}>{model.last_name + ", " + model.first_name}</Link></ControlLabel>
                                    <br/>
                                    <Link to={"/patients/" + model.id}>{model.email}</Link>
                                </div>
                            </div>
                        );   
                    default: return "";
                }
            }else{

                switch(attribute){       
                    case 'search':
                        return <DocumentListFinder {...container}/>
                    case 'infographics':
                        return <PatientListInfoGraphics {...container} {...options}/>
                    case 'documentListCrud':
                        return <PatientListCrud {...container} />
                    default: return "";
                } 
            }

            return null;

        }
    },

    autocomplete: (query, options={}) => {

        return Services.document.autocomplete(query, {
            documentType: 'patient',
            suggestions: (data)=>{
                return data.map(i=>({
                    id: i.id,
                    name: i.last_name + ", " + i.first_name
                }))
            }
        }).then(({suggestions})=>{
            console.log('patient.service autocomplete suggestions', suggestions);
            let options = suggestions;
            return {options};
        })
    },

    count : (store, options = {}) => {
        return Services.document.count(store, Object.assign({}, options, {
            documentType: Settings.patient.documentType
        }));
    },

    retrieveList : (store, options = {}) => {
        return Services.document.retrieveList(store, Object.assign({}, options, {
            documentType: Settings.patient.documentType
        }));
    },

    retrievePatient : (store, options = {}) => {
        return Api.get('/patients/' + options.id)
    },

    retrievePatientSchedules : (store, options={}) => {
        return Api.get('/patient_schedules' + (options.id ? '/'+options.id : '') )
    },

    retrievePatientFiles : (store, options={}) => {
        console.log('patient.service retrievePatientFiles', options);
        return Api.get('/patient_shared_files' + (options.id ? '/'+ options.id + '?1' : options.sharedWith ? '?shared_to_user_id='+ options.sharedWith : '?1') + "&order_by=id&sort=desc&page=0&per_page=20" )
    },   
    
    retrievePatientFileSharingProperties : (store, options={}) => {
        console.log('patient.service retrievePatientFileSharingProperties', options);
        return Api.get('/file_sharing_properties?shared_file_id=' + (options.id ? options.id : 0) + "&order_by=id&sort=desc&page=0&per_page=20" )
    }, 

    removePatientFile: (store, options={}) => {
        return Api.delete('/patient_shared_files/' + (options.id ? options.id : 0) )
    },

    removePatientFileSharingProperty: (store, options={}) => {
        return Api.delete('/file_sharing_properties/' + (options.id ? options.id : 0) )
    },

    addPatientFileSharingProperty: (store, options={}) => {
        return Api.post('/file_sharing_properties', {
            "file_sharing_property": {
                "shared_to_user_id": options.shared_to_user_id,
                "shared_file_id": options.shared_file_id,
                "property": { "shared":true }
            }            
        } )
    },

    savePatientSchedule: (store, schedule) => {
        return Api.post('/patient_schedules', schedule)
    },

    removePatientSchedule: (store, schedule) => {
        return Api.delete('/patient_schedules/' + schedule.id)
    },

    afterRetrieve: (store, options) => {
        // set default values
        if(store.document.documentData.id <= 0){
            options.handleDocumentChange(
                {documentType: 'patient'}, 
                {data: {
                        filesdir: "EMPDIR-" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10),
                        date_of_birth: moment().format('YYYY-MM-DD')
                    }
                }
            )(options.dispatch, options.getState);
        }
    },

    current : (store, options = {}) => {
        return Api.get('/patients?email=' + store.account.user.email)
    },

    autocomplete: (query, options={}) => {

        return Services.document.autocomplete(query, {
            documentType: 'patient',
            suggestions: (data)=>{
                return data.map(i=>({
                    id: i.id,
                    name: i.last_name + ", " + i.first_name
                }))
            }
        }).then(({suggestions})=>{
            console.log('patient.service autocomplete suggestions', suggestions);
            let options = suggestions;
            return {options};
        })
    },    
    
    transformDocument: (store, documentData) => {

        let transformedData = Object.assign({}, documentData)
        console.log('patient.service transformDocument original', transformedData)
        //remove id
        delete transformedData.id

        //transform parent record
        let userName =  documentData.first_name + "." + documentData.last_name;
        transformedData.user_name = userName.replace(/\s+/g, '')

        //status
        if(!documentData.id){
            transformedData.status = true
        }

        //set child record attributes        
        //patient addresses
        let patientAddresses = []
        if(documentData.patient_addresses){
            for(let address of documentData.patient_addresses){
                if(documentData.id){
                    address.patient_id = documentData.id
                }else{
                    delete address.id
                    delete address.patient_id 
                }
                patientAddresses.push(address)
            }
        }
        transformedData.patient_addresses_attributes = patientAddresses

        //patient guardians
        let patientGuardians = []
        if(documentData.patient_guardians){
            for(let guardian of documentData.patient_guardians){
                if(documentData.id){
                    guardian.patient_id = documentData.id
                }else{
                    delete guardian.id
                    delete guardian.patient_id 
                }
                patientGuardians.push(guardian)
            }
        }
        transformedData.patient_guardians_attributes = patientGuardians

        //patient profile
        transformedData.patient_profile_attributes = Object.assign({}, documentData.patient_profile || {})
        transformedData.patient_profile_attributes.first_name = documentData.first_name
        transformedData.patient_profile_attributes.last_name = documentData.last_name
        transformedData.patient_profile_attributes.date_of_birth = transformedData.patient_profile_attributes && transformedData.patient_profile_attributes.date_of_birth ? 
            transformedData.patient_profile_attributes.date_of_birth : (documentData.date_of_birth || moment().format('YYYY-MM-DD') )
        transformedData.patient_profile_attributes.sex = documentData.patient_profile ? (documentData.patient_profile.sex||"M"): "M"

        //delete replaced child records
        delete transformedData.patient_profile
        delete transformedData.patient_guardians
        delete transformedData.patient_addresses

        //delete audit attributes
        delete transformedData.created_at
        delete transformedData.updated_at
        delete transformedData.area_code
        delete transformedData.confirmed
        delete transformedData.office
        delete transformedData.office_id
        delete transformedData.patient_address
        delete transformedData.patient_shared_files_count
        delete transformedData.patient_documents_count
        delete transformedData.patient_current_schedules

        console.log('patient.service transformDocument transformed', transformedData)
        return transformedData;
    }

}