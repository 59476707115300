/**
 * Department List
 */
export const departmentList = {
    documentType: "department",
    view: {
        headers: {
            labels: ["Department Name","Department Code", "Department Head"],
            attributes: ["description","departmentCode","departmentHead"]
        },
        body: {
            data:[]
        },
        pagination: {
            pageSize: 10
        },
        search: {
            attributes: ["description","departmentCode","departmentHead"]
        }
    },
    routes: {
        local: {
            path: "/departments",
            name: "Departments",
            icon: "pe-7s-graph",
            config: "departmentList"
        }
    }
}
/**
 * Department
 */
export const department = {
    documentType: "department" 
}