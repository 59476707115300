import React, { Component } from "react"
import { connect } from 'react-redux';
import { Table } from "react-bootstrap"
import * as Services from "services"
import { Card } from "common/components/Card.jsx";
import moment from "moment";


class PatientSharedFilesWidget extends Component {
	
	constructor(props) {            
        super(props);
        this.state = {
		}
    }

    componentDidMount(){
        console.log('PatientSharedFilesWidget componentDidMount', this.state, this.props)

        //retrieve current patient file
        if(this.props.account && this.props.account.user && this.props.account.user.patient && this.props.account.user.patient.id){
            this.retrieveMyFiles()
            this.retrieveFilesSharedWithMe()
        }
    }

    componentDidUpdate(prevProps){
        this.state.files == undefined && this.retrieveMyFiles()
        this.state.sharedFiles == undefined && this.retrieveFilesSharedWithMe()
    }
    	
	/**
     * Retrieves the employee list
     */
    retrieveMyFiles = () => {
        let { account } = this.props
        let patient = account && account.user && account.user.patient ? account.user.patient : {id:0}
        if(patient && patient.id > 0){
            // Retrieve shared files and assign it to the state
            Services.patient.retrievePatientFiles(null, {	
                id: patient.id 
            })
            .then((res) => {
                console.log('PatientSharedFilesWidget retrieveMyFiles', res.data)			
                this.setState((state) => {
                    return Object.assign({}, state, 
                    {
                        files: res.data
                    });				
                });
            });
        }
    }
    
	/**
     * Retrieves the employee list
     */
    retrieveFilesSharedWithMe = () => {
        let { patient, account } = this.props
        let user = account.user || { id: 0, roles_mask: "guest"}
        if(user.id > 0){
            // Retrieve shared files and assign it to the state
            Services.patient.retrievePatientFiles(null, {	
                sharedWith: user.id 
            })
            .then((res) => {
                console.log('PatientSharedFilesWidget retrieveMyFiles', res.data)			
                this.setState((state) => {
                    return Object.assign({}, state, 
                    {
                        sharedFiles: res.data
                    });				
                });
            });
        }
	}    
		

	render() {	
        console.log('PatientSharedFilesWidget state', this.state)
        return (			
            <Card
                statsIcon="fa fa-history"
                id="patientSharedFiles"
                title="Shared Files"
                category="List of Files shared with me by health care provider"
                stats="Updated 3 minutes ago"
                contentStyle={{padding:0}}
                content={
					<div className="table-responsive">
                        <Table striped hover style={{borderTop: 1 + "px solid #e3e3e5", borderBottom: 1 + "px solid #e3e3e5"}} 
                            key={"patientSharedFiles-table"} className="tablesaw tablesaw-stack" data-tablesaw-mode="stack">
                            <thead>
                                <tr>
                                    <th style={{width:5+"%", textAlign: "center"}}></th>
                                    <th style={{width:20+"%"}}>File</th>
                                    <th style={{width:50+"%"}}></th>
                                    <th style={{width:25+"%"}}>Date Shared</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="4"><h5>My Files</h5></td>
                                </tr>
                                {
                                    this.state.files && this.state.files.length > 0 ?                                    
                                    this.state.files.map((file, key) => {
                                        return <tr key={"row-file-"+key}>
                                            <td style={{width:5+"%", textAlign: "center", verticalAlign: "top"}}>
                                                {key + 1}
                                            </td>
                                            <td colSpan="2" style={{verticalAlign: "top"}}>
                                                <a href={file.file_url} target="_blank">
                                                {file.notes_string ? file.notes_string : file.file_url }
                                                </a>
                                            </td>
                                            <td style={{width:25+"%", verticalAlign: "top"}}>
                                                {moment(file.created_at).format('LLL')}
                                            </td>
                                        </tr>
                                    })
                                    :
                                    <tr>
                                        <td colSpan={4}>Nothing to show.</td>
                                    </tr>
                                }
                                <tr>
                                    <td colSpan="4"></td>
                                </tr>
                                <tr>
                                    <td colSpan="4"><h5>Files Shared with Me</h5></td>
                                </tr>
                                {
                                    this.state.sharedFiles && this.state.sharedFiles.length > 0 ?                                    
                                    this.state.sharedFiles.map((file, key) => {
                                        return <tr key={"row-file-"+key}>
                                            <td style={{width:5+"%", textAlign: "center", verticalAlign: "top"}}>
                                                {key + 1}
                                            </td>
                                            <td style={{verticalAlign: "top"}}>
                                                {
                                                    file.patient && file.patient.id ? 
                                                    file.patient["first_name"] + " " + file.patient["last_name"]
                                                    : file.patient_id
                                                }
                                            </td>
                                            <td>
                                                <a href={file.file_url} target="_blank">
                                                {file.notes_string ? file.notes_string : file.file_url }
                                                </a>
                                            </td>
                                            <td style={{width:25+"%", verticalAlign: "top"}}>
                                                {moment(file.created_at).format('LLL')}
                                            </td>
                                        </tr>
                                    })
                                    :
                                    <tr>
                                        <td colSpan={3}>No shared files.</td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
					</div>					
				}   
				
            />
		);
	}
}

const mapStateToProps = (state) => (
    {
        account: state.account
    }
)

export default connect(mapStateToProps)(PatientSharedFilesWidget);