import React, { Component } from "react";

import * as Settings from "settings";
import * as Services from "services";
import url from "../../utils/url"

class RegistrationConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
        verified: false,
        error: null
    }
  }

  componentDidMount(){
    let code = "", ret = "";
    if(this.props.location && this.props.location.search){
        let pageParams = url.urlSearchParams(this.props.location.search);
        code = pageParams.code || "";
    }    
    Services.user.confirmVerification(code)
    .then(res=>{
        if(res.data.emailVerified){
            this.setState(state=>{
                return Object.assign({}, state, {
                    verified: true,
                    error: null
                })
            })
        }
    })
    .catch(err=>{
        this.setState(state=>{
            return Object.assign({}, state, {
                verified: false,
                error: err
            })
        })
    })
  }

  render() {
    return (
        <div id="main-panel" className="" ref="mainPanel">
            <div style={{
				height: 200+"px", 
				backgroundSize: "100%",
                backgroundImage:"url('https://vaycay365-live.s3.us-east-2.amazonaws.com/app/public/images/banner.jpg')",
                backgroundPosition: "0 70%"
            }}>
			</div>          
			<div style={{textAlign:"center"}}>
				<img width="250" height="65" src="https://starship-live.s3.us-east-2.amazonaws.com/starship.png" 
                class="custom-logo" itemprop="logo" style={{margin: 20+"px"}}></img>
			</div>
            
                {
                    this.state.verified ?
                    <div className="col-md-4 col-md-offset-4" style={{textAlign:"center"}}>
                        <h3>Your email has been confirmed.</h3>
                        <p>We will send you notification once the registration has been approved.</p>
                        <p>Thank you for joining our Vaycay365 family.</p>
                    </div>
                    : !this.state.verified && this.state.error ? 
                    <div className="col-md-6 col-md-offset-3" style={{textAlign:"center"}}>
                        <h3>We are unable to confirm your email.</h3>
                        <p>Please try again later.</p>
                    </div>
                    :
                    <div className="col-md-6 col-md-offset-3" style={{textAlign:"center"}}>
                        <h3>Please check your inbox for your confirmation email</h3>
                    </div>
                }
                            
            <div className="form-group col-md-12">
				<p className="copyright" style={{textAlign:"center", marginTop:30+"px"}}>
					<a href="#">{Settings.app.copyright}</a>
					&copy; {new Date().getFullYear()}{" "}
				</p>
            </div>            
        </div>
    );
  }
}

export default RegistrationConfirmation;