import React, { PureComponent } from "react";
import { connect } from 'react-redux';

import {
    Grid,
    Row,
    Col
} from "react-bootstrap";

import { Card } from "common/components/Card.jsx";
import { FormInputs } from "common/components/FormInputs.jsx";
import  RecordNavigator  from "common/components/RecordNavigator.jsx";
import { InputDropdown } from "common/components/InputDropdown.jsx";
import * as Services from "services"

class ACLUser extends PureComponent {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            roles: {},
        }
    }

    componentWillMount(){
        Services.event.listenEvent('afterSave', 'ACLUSER-AFTER_SAVE', this.handleAfterSave.bind(this));
    }
    componentWillUnmount(){
        Services.event.unlistenEvent('afterSave', 'ACLUSER-AFTER_SAVE');
    }

	componentDidMount(){        
        Services.extendedrole.retrieveList()
        .then((res) => {
            this.setState((state) => {
                return Object.assign({}, state, 
                {
                    roles: res.data.reduce((obj, item) => {
                        obj[item.id] = item.name
                        return obj
                    }, {})
                });
            });
        });
    }

    handleChange(e){
        console.log('handleChange', e);
        const { name, value } = e.target;
        this.props.handleDocumentChange(this.props.documentSettings, { data: {[name]:value} });
    }

    handleAfterSave(options){
        console.log('ACLUser afterSave ', options);
    }

    render() {
        
        let { success, documentData, listData, error } = this.props;
        //let roleList = listData.roleList;
        console.log('ACLUser render', documentData);

        return (
            <div className="content">

                <Grid fluid>
                <Row>
                <Col md={12}>
                    <Card
                    title="User"
                    menu={
                        <RecordNavigator {...this.props}/>
                    }
                    content={
                        <form>

                        <FormInputs
                            ncols={["col-md-6","col-md-6"]}
                            proprieties={[
                            {
                                label: "Email",
                                type: "text",
                                bsClass: "form-control",
                                placeholder: "Email",
                                name: "email",
                                defaultValue: documentData.email,
                                value: documentData.email,
                                disabled: documentData.id ? true:false,
                                onChange: this.handleChange
                            },
                            {
                                key: "username",
                                label: "User Name",
                                type: "text",
                                bsClass: "form-control",
                                placeholder: "User Name",
                                name: "username",
                                value: documentData.username,
                                onChange: this.handleChange
                            }
                            ]}
                        />
                        <FormInputs
                            ncols={["col-md-6","col-md-6"]}
                            proprieties={[
                            {
                                label: "Last Name",
                                type: "text",
                                bsClass: "form-control",
                                placeholder: "Last Name",
                                name: "lastName",
                                value: documentData.lastName,
                                onChange: this.handleChange
                            },
                            {
                                key: "firstName",
                                label: "First Name",
                                type: "text",
                                bsClass: "form-control",
                                placeholder: "First Name",
                                name: "firstName",
                                value: documentData.firstName,
                                onChange: this.handleChange
                            }
                            ]}
                        />
                        <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                            {
                                key: "realm",
                                label: "Realm",
                                type: "text",
                                bsClass: "form-control",
                                placeholder: "Realms",
                                name: "realm",
                                value: documentData.realm,
                                onChange: this.handleChange
                            }
                            ]}
                        />
                        <InputDropdown
                            ncols={["col-md-6"]}
                            proprieties={[
                            {
                                key: "role",
                                label: "Default Role",
                                type: "select",
                                bsClass: "form-control",
                                placeholder: "Role",
                                name: "role",
                                value: documentData.role,
                                onChange: this.handleChange,
                                selectionList: this.state.roles
                            }
                            ]}
                        />
                        <InputDropdown
                            ncols={["col-md-6"]}
                            proprieties={[
                            {
                                key: "status",
                                label: "Status",
                                type: "select",
                                bsClass: "form-control",
                                name: "status",
                                value: documentData.status,
                                onChange: this.handleChange,
                                selectionList: {
                                    0: "Inactive", 1: "Active"
                                }
                            }
                            ]}
                        />
                        <div className="clearfix" />            
                        </form>
                    }
                    />
                </Col>
                </Row>
                </Grid>

            </div>
        )

            
    }
}

const mapStateToProps = (state) => ({
    documentData: state.document.documentData,
    error: state.document.error,
    actionInProgress: state.document.actionInProgress,
    success: state.document.success
});

export default connect(mapStateToProps)(ACLUser);