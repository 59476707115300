import React, { Component } from "react";

import {AsyncTypeahead} from 'react-bootstrap-typeahead'

export class Autocomplete extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            isLoading: false,
            multiple: false,
            options: []
        }

        this.handleSearch = this.handleSearch.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
    }

    handleSearch(query){
        this.setState({isLoading: true});

        if(this.props.service && this.props.service.autocomplete){
            this.props.service.autocomplete(query)
            .then(({options}) => {
                this.setState({
                    isLoading: false,
                    options,
                });
            });            
        }else{
            this.setState({
                isLoading: false,
                options: []
            })
        }
    }

    handleSelection(selected){
        this.setState({selected});
        if(this.props.handleSelection){
            this.props.handleSelection(selected.pop(), this.props);
        }
    }

    render() {

        let {error} = this.props;
        let message = error && error.data && error.data.error.details.messages[this.props.name] ? 
            this.props.name + " " + error.data.error.details.messages[this.props.name]: null;

        return (
            <div>
            <AsyncTypeahead
                {...this.state}
                {...this.props}
                labelKey={this.props.name}
                isLoading={this.state.isLoading}
                onSearch={this.handleSearch}
                onChange={this.handleSelection}
                selected={this.state.selected}
                defaultSelected={this.props.defaultSelected}
                placeholder={this.props.placeholder || "Search"}             
            /> 
            {
                error ? 
                <span className="help-block">{message}</span>
                : ""
            }
            </div>
        );
    }
}
  
  export default Autocomplete;