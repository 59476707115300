import React from "react";

import {withDocument} from "common/containers/withDocument.jsx";
import {withSuspense} from "common/containers/withSuspense.jsx";
import {withDocumentList} from "common/containers/withDocumentList.jsx";


const Patient = React.lazy(()=>import("dental/patient/components/Patient"));
const DocumentListPdfViewer = React.lazy(()=>import( "common/components/DocumentListPdfViewer" ));

var dentalRoutes = [
    { path: "/pdf", name: "PDF Viewer", component: withSuspense(DocumentListPdfViewer, "pdfviewer") },

    //temp
    { path: "/patients/:id", name: "Patient", component: withDocument(Patient, "patient") },
    { path: "/patients", name: "Patient", component: withDocumentList("patientList") },
];

export default dentalRoutes;