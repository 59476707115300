import React, { Component } from "react"
import { StatsCard } from "common/components/StatsCard.jsx";
import * as Services from "services"

class PatientCountWidget extends Component {
	
	constructor(props) {            
        super(props);
        this.state = {
            count: 0,           
		}
    }

    componentDidMount () {
        console.log('[Widget] Employee Count Mounted')
        this.handleCount()
    }

    handleCount = () => {
        /*
        Services.patient.count().then((res) => {
            this.setState((state) => {
				return Object.assign({}, state, 
				{
					count: res.data.count
				});				
            });
		});*/
    }
    
	render() {        
        return (			
            <StatsCard
                bigIcon={<i className="pe-7s-id text-success" title="Patients" />}
                statsText="Patients"
                statsValue={this.state.count}
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Completed"
            />
		);
	}
}

export default PatientCountWidget;
