import React, {Suspense} from "react";
import {fallback} from "common/containers/fallback.jsx";
import DocumentList from "./../components/DocumentList";

export const withDocumentList = (config) => {
    return class withDocumentList extends React.Component{
        render(){
            return(
                <Suspense fallback={fallback()}>
                    <DocumentList config={config} {...this.props}/>
                </Suspense>
            )
        }
    }    
}