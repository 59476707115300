import React, { PureComponent } from "react";
import { connect } from 'react-redux';

import Button from "common/components/CustomButton.jsx";
import {
    Grid,
    Row,
    Col
} from "react-bootstrap";
import { BeatLoader } from 'react-spinners';


import * as documentActions from "common/actions/document.action";
import * as Services from './../../services';

class DocumentCrud extends PureComponent {
    
    notificationSystem = null;

    constructor(props) {
        super(props);

        this.handleNew = this.handleNew.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleButtonStateChanged = this.handleButtonStateChanged.bind(this);
        this.handleCustomAction = this.handleCustomAction.bind(this);
        console.log('DocumentCrud props', props);

    }

    componentWillMount(){
        if(Services["event"]){
            Services.event.listenEvent("documentCrudButtonStateChanged","DOCUMENT-CRUD", this.handleButtonStateChanged)
        }
    }

    componentWillUnmount(){
        if(Services["event"]){
            Services.event.unlistenEvent("documentCrudButtonStateChanged","DOCUMENT-CRUD")
        }
    }

    handleSave(e){
        e.preventDefault();
        let beforeSave = true;

        if(Services["event"]){
            Services.event.triggerEvent("beforeSave", e);
        }

        //allow other components to handle beforeSave event
        if(this.props.eventHandlers && this.props.eventHandlers['beforeSave']){
            if(Array.isArray(this.props.eventHandlers['beforeSave'])){
                for(let eventHandler of this.props.eventHandlers['beforeSave']){
                    beforeSave = eventHandler(e);
                }
            }else{
                let eventHandler = this.props.eventHandlers['beforeSave'];
                beforeSave = eventHandler(e);
            }
        }

        //continue saving only if beforeSave returns true
        if(beforeSave){
            this.props.saveDocument(this.props.documentSettings);
        }

        //allow other components to handle afterSave event
        if(this.props.eventHandlers && this.props.eventHandlers['afterSave']){
            if(Array.isArray(this.props.eventHandlers['afterSave'])){
                for(let eventHandler of this.props.eventHandlers['afterSave']){
                    eventHandler(e);
                }
            }else{
                let eventHandler = this.props.eventHandlers['afterSave'];
                eventHandler(e);
            }
        }

        if(Services["event"]){
            Services.event.triggerEvent("afterSave", e);
        }

    }

    handleNew(e){
        e.preventDefault();
        this.props.newDocument(this.props.documentSettings);
    }    

    handleButtonStateChanged(e){
        if(e.state){
            this.props.handleDocumentCrudStateChange(this.props.documentSettings, {
                state: e.state
            })       
        }
    }

    handleCustomAction(e){
        e.preventDefault();
        //allow other components to handle extended action events
        Services.event.triggerEvent("documentCrudCustomButtonClicked", e);
    }

    documentCrud(){
        let customCrud = null;
        let { documentSettings, documentCrud} = this.props;
        if(documentSettings && documentSettings.documentType && Services[documentSettings.documentType]){
            let service = Services[documentSettings.documentType];
            if(service.ui && service.ui.componentize){
                customCrud = service.ui.componentize;
            }
        }
        return(
            <Grid fluid>
                <Row>
                    <Col md={12}>
                        {
                        documentCrud.new.hidden ? "" :
                        <Button bsStyle="default" fill className="btn-fill " 
                            style={{width: 150 + "px"}} onClick={this.handleNew}
                            disabled={ !documentCrud.new.enabled }
                            >
                            New
                        </Button>  
                        }                      
                        {
                        documentCrud.save.hidden ? "" :
                        <Button bsStyle="primary" fill className="btn-fill" 
                            style={{marginLeft: 5 + "px", width: 150 + "px"}} onClick={this.handleSave}
                            disabled={ !documentCrud.save.enabled }
                            >
                            {
                            !this.props.actionInProgress ? "Save": ""
                            }
                            <BeatLoader
                                sizeUnit={"px"}
                                size={10}
                                color={'#e3e3e5'}
                                loading={this.props.actionInProgress}
                            />
                        </Button>
                        }
                        {
                            customCrud ? 
                            customCrud(null, "documentCrud", this.props)
                            : ""
                        }   
                    </Col>                            
                </Row>
            </Grid>
        )
    }

    render() {

        let {actionInProgress, actionCompleted, success, error} = this.props;
        console.log('DocumentCrud.render', success);
        console.log('CRUD error', error);
        console.log('CRUD: actionInProgress', actionInProgress);
        console.log('CRUD: actionCompleted', actionCompleted);

        let variant = actionCompleted ? 'info' : null;

        return (
        <div className="content">
            { this.documentCrud() }
        </div>
        ) 
    }
}

const mapStateToProps = (state) => ({
    documentCrud: state.document.documentCrud,
    error: state.document.error,
    actionInProgress: state.document.actionInProgress,
    actionCompleted: state.document.actionCompleted,
    success: state.document.success
});

const mapActionsToProps = {
    ...documentActions
};

export default connect(mapStateToProps, mapActionsToProps)(DocumentCrud);