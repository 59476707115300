import React, { Component } from "react";

import {
  Grid,
  Row,
  Col
} from "react-bootstrap";

import { Card } from "common/components/Card.jsx";
import {Tabs, Tab} from 'react-bootstrap-tabs';
import ACLRoleList from './ACLRoleList.jsx';
import ACLUserList from './ACLUserList.jsx';

class ACL extends Component {

    state = {      
    }

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="content">
            <Grid fluid>
                <Row>
                <Col md={12}>
                    <Card
                    title="Access Control List"
                    contentStyle={{padding:0}}
                    content={
                        <form>

                            <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
                                <Tab label="Role">
                                    <ACLRoleList 
                                        handleChange={this.handleChange}
                                        {...this.props}
                                    />
                                </Tab>
                                <Tab label="User">
                                    <ACLUserList
                                        handleChange={this.handleChange}
                                        {...this.props}
                                    />
                                </Tab>                                
                            </Tabs>

                            <div className="clearfix" />            
                        </form>
                    }
                    />
                </Col>
                </Row>
            </Grid>
            </div>
        );
    }
}

export default ACL;