/**
 * Patient List
 */
export const patientList = {
    documentType: "patient",
    view: {
        headers: {
            labels: ["Patient","ID#","Address","Birth Date"],
            widths: ["40%","10%","30%","20%"],
            attributes: [
                {componentize: "patient", attribute: "email"},
                {convertByAttribute: "patientId", attribute:"patient_id_no"},
                {convertByAttribute: "address", attribute:"address_1"},
                {convertByAttribute: "birthDate", attribute:"date_of_birth "}
            ],
        },
        pagination: {
            pageSize: 10
        },
        body: {
            data:[]
        },
        search: {
            attributes: ["lastName","firstName","email","idNumber"]
        }
    },
    routes: {
        local: {
            path: "/patients",
            name: "Patients",
            icon: "pe-7s-graph",
            config: "patientList"
        }
    }
}
/**
 * Employee
 */
export const patient = {
    title: "Patient",
    documentType: "patient",
    schema: {
        properties: {
            user_name: { type: 'String'},
            first_name: { type: 'String'},
            last_name: { type: 'String'},
            email: { type: 'String'},
            status: { type: 'Number'},
            date_of_birth: { type: 'Date'},
            office_id: { type: 'Number'},
            patient_addresses: { type: 'Array'},
            patient_profile: { type: 'Object'},
            patient_guardians: { type: 'Array'},
            patient_current_schedules: { type: 'Array'},
            patient_shared_files_count: { type: 'Number'},
            patient_documents_count: { type: 'Number'}
        }
    },
    routes: {
        local: {
            path: "/patients",
            config: "patient"
        },
        service: {
            path: "/patients",
            //include: "filter[include]=patientBenefit&filter[include]=patientHead"
        }
    },
    gender:{
        1 : 'Male',
        0 : 'Female'   
    }
}