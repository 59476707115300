import Api from 'utils/Http'

import * as Services from 'services';

export const user = {

    retrieveList : (store, options = {}) => {
        let filters = Services.document.retrieveDocumentFilters(options);
        return Api.get('/users?' + filters)
    },

    register: (user) => {
        return Api.post('/auth/sign-up', user);
    },

    login : (email, password) => {
        return Api.post('/auth/sign-in', {email: email, password: password});
    },

    sso : (token) => {
        return Api.get('/auth/sso?token=' + token);
    },
    
    google: (google) => {
        if(google && google['profileObj'] && google['tokenObj']){

            let email = google['profileObj']['email'];
            let token = google['tokenObj']['access_token'];
            let ttl = google['tokenObj']['expires_in'];
            let sid = google['profileObj']['googleId'];

            //authenticate
            return Api.get('/users/google?email=' + email + '&token=' + token + '&ttl=' + ttl + '&sid=' + sid)

        }else{
            return Promise.reject(new Error("Invalid Google Credentials"));
        }
    },

    getProfile : (options = {}) => {
        return Api.get('/users/me');
    },

    autocomplete: (query, options={}) => {

        return Services.document.autocomplete(query, {
            documentType: 'user',
            suggestions: (data)=>{
                return data.map(i=>({
                    id: i.id,
                    name: i.last_name + ", " + i.first_name + " : " + i.email
                }))
            }
        }).then(({suggestions})=>{
            console.log('user.service autocomplete suggestions', suggestions);
            let options = suggestions;
            return {options};
        })
    },

}

export const extendeduser = {

    retrieveList : (store, options = {}) => {
        let filters = Services.document.retrieveDocumentFilters(options);
        return Api.get('/extendeduser?' + filters)
    }

}