import React, { PureComponent } from "react";
import { connect } from 'react-redux';

import {
    Table,
    Button,
    Glyphicon
} from "react-bootstrap";

import { FormInputs } from "common/components/FormInputs.jsx";
import { CustomCheckbox } from "common/components/CustomCheckbox.jsx";
import ACLAccessPropertyDetail from "./ACLAccessPropertyDetail";
import * as Services from "services";
import * as Settings from "settings";
import Inflection from "inflection";


class ACLRoleAccessDetail extends PureComponent {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        //this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            acls: {},
            rbacProperties: {},
            rbacAccessTypes: {},
            detail: {
                show: false,
                title: "",
                model: ""
            }
        }
    }

    componentDidMount(){
        let {documentData} = this.props;
        if(documentData.id){
            Services.acl.retrieveList(null, {
                filters: "filter[where][principalType]=ROLE&filter[where][principalId]=" + documentData.id
            }).then(res => {
                console.log('ACLRoleAccessDetail componentDidMount', res.data);
                let rbac = { rbacProperties:{}, rbacAccessTypes:{}  }
                res.data.reduce((obj, item) => {
                    rbac.rbacProperties[item.model] = Array.from(new Set([...(rbac.rbacProperties[item.model]||[]), ...[item.property]]));
                    rbac.rbacAccessTypes[item.model] = Array.from(new Set([...(rbac.rbacAccessTypes[item.model]||[]), ...[item.accessType.toLowerCase()]]));
                }, {});
                this.setState(state => {
                    return Object.assign({}, state, {
                        rbacProperties: rbac.rbacProperties,
                        rbacAccessTypes: rbac.rbacAccessTypes
                    });
                });
            });
        }
    }

    handleChange(e){
        let {name, value} = e.target;
        let doc = e.target.getAttribute('data-document');
        console.log(doc, name, value);
        let rbacAccessTypes={}, rbacProperties={};
        if(value){
            rbacAccessTypes =  Object.assign({}, this.state.rbacAccessTypes, {
                [doc]: Array.from(new Set([...(this.state.rbacAccessTypes[doc] || []), ...[name] ])) 
            });
            rbacProperties = Object.assign({}, this.state.rbacProperties,{
                [doc]: Array.from(new Set([...(this.state.rbacProperties[doc] || []), ...Settings.acl.acl[name] ]))  
            });
        }else{
            rbacAccessTypes = Object.assign({}, this.state.rbacAccessTypes,{
                [doc]: (this.state.rbacAccessTypes[doc]||[]).filter(item=>item!==name)
            });
            rbacProperties =  Object.assign({}, this.state.rbacProperties,{
                [doc]: (this.state.rbacProperties[doc]||[]).filter(item=> !(Settings.acl.acl[name]).includes(item) )
            });
        }

        this.setState(state => {
            return Object.assign({}, state, {
                rbacAccessTypes: rbacAccessTypes,
                rbacProperties: rbacProperties
            });
        }, ()=>{
            this.props.handleDocumentChange(this.props.documentSettings, { data: {
                aclAssignment: {
                    accessTypes: rbacAccessTypes,
                    properties: rbacProperties
                }
            }});
        });

        

    }

    handleShow(title, model) {
        this.setState(state => {
            return Object.assign({}, state, {
                detail: {
                    show: true,
                    title: title,
                    model: model
                }
            });
        });         
    }    

    handleClose(e, options) {
        console.log("handleClose",this.state, options);
        this.setState(state => {
            return Object.assign({}, state, {
                rbacProperties: Object.assign({}, state.rbacProperties, {
                    [options.model]: options.selectedProperties
                }),
                detail: {
                    show: false
                }
            });
        });
    }  

    render() {
        
        let { success, documentData, documentSettings, actionInProgress, error } = this.props;
        console.log('ACLRoleAccessDetail render', this.state);

        return (
            <div className="">
                <Table striped hover style={{borderTop: 1 + "px solid #e3e3e5", borderBottom: 1 + "px solid #e3e3e5"}} >
                    <thead>
                    <tr>
                        <th key={"name"} style={{width:"30%"}}>Document</th>
                        <th key={"description"} style={{width:"70%"}}>Access</th>
                    </tr>
                    </thead>
                    <tbody>
                        {
                            
                            Object.keys(Services).map((key, i) => {                                                
                                return(
                                    Settings[key] ? 
                                    <tr key={key}>
                                        <td key={"name-" + key}>{Inflection.camelize(key)}</td>
                                        <td key={"description-" + key}>
                                        <div class="col-md-2">
                                            <CustomCheckbox
                                                label="MENU"
                                                name="menu"
                                                value={ (this.state.rbacAccessTypes[key] || []).includes("menu") }
                                                isChecked={ (this.state.rbacAccessTypes[key] || []).includes("menu") }
                                                onChange={this.handleChange}
                                                {...this.props}
                                                data-document={key}
                                                data-rowId={i}
                                            />
                                        </div>
                                        <div class="col-md-2">
                                            <CustomCheckbox
                                                label="READ"
                                                name="read"
                                                value={ (this.state.rbacAccessTypes[key] || []).includes("read") }
                                                isChecked={ (this.state.rbacAccessTypes[key] || []).includes("read") }
                                                onChange={this.handleChange}
                                                {...this.props}
                                                data-document={key}
                                                data-rowId={i}
                                            />
                                        </div>
                                        <div class="col-md-2">
                                            <CustomCheckbox
                                                label="WRITE"
                                                name="write"
                                                value={ (this.state.rbacAccessTypes[key] || []).includes("write") }
                                                isChecked={ (this.state.rbacAccessTypes[key] || []).includes("write") }
                                                onChange={this.handleChange}
                                                {...this.props}
                                                data-document={key}
                                                data-rowId={i}
                                            />
                                        </div>
                                        <div class="col-md-2">
                                            <CustomCheckbox
                                                label="EXECUTE"
                                                name="execute"
                                                value={ (this.state.rbacAccessTypes[key] || []).includes("execute") }
                                                isChecked={ (this.state.rbacAccessTypes[key] || []).includes("execute") }
                                                onChange={this.handleChange}
                                                {...this.props}
                                                data-document={key}
                                                data-rowId={i}
                                            />
                                        </div>
                                        <div class="col-md-4">
                                        <Button bsStyle="default" bsSize="small" fill className="btn-fill " onClick={this.handleShow.bind(this, Inflection.humanize(key), key) } data-rowId={i} data-title={Inflection.humanize(key)} data-model={key}>
                                            <Glyphicon glyph="align-justify" bsSize="small" data-rowId={i} data-title={Inflection.humanize(key)} data-model={key}/>
                                        </Button>
                                        </div>
                                        </td>
                                    </tr>  
                                    : ""
                                );
                            })  
                                                            
                        }
                    </tbody>
                </Table>

                <ACLAccessPropertyDetail 
                    title = {this.state.detail.title}
                    model = {this.state.detail.model}
                    rbac = {this.state.rbacProperties}
                    showModal = {this.state.detail.show}
                    handleClose = {this.handleClose}
                />

                <div className="clearfix" />        
                
            </div>
        )

      
        
    }
}

const mapStateToProps = (state) => ({
    documentData: state.document.documentData,
    error: state.document.error,
    actionInProgress: state.document.actionInProgress,
    success: state.document.success
});

export default connect(mapStateToProps)(ACLRoleAccessDetail);