import React, { Component } from "react";
import { connect } from 'react-redux';

import {
  Table,
  Button
} from "react-bootstrap";
import { Link } from "react-router-dom";
import * as documentListActions from "common/actions/documentList.action";
import * as Settings from "settings";
import history from "./../../history"

class ACLRoleList extends Component {

    documentSettings = {}

    constructor(props){
        super(props)
        this.documentSettings = Settings['extendedrole'];
        this.handleNew = this.handleNew.bind(this);
    }

    componentDidMount() {
        this.props.retrieveDocumentList(this.documentSettings);
    }

    handleNew(e){
        e.preventDefault();
        history.push("/extendedroles/$");
    }

    tableList() {

        let { fail, fetching, listData } = this.props;

        if(listData['extendedroleList']){
            listData = listData["extendedroleList"];
        }

        return (
            <Table striped hover style={{borderTop: 1 + "px solid #e3e3e5", borderBottom: 1 + "px solid #e3e3e5"}} >
                <thead>
                <tr>
                    <th key={"name"} style={{width:"30%"}}>Name</th>
                    <th key={"description"} style={{width:"70%"}}>Description</th>
                </tr>
                </thead>
                <tbody>
                    {
                        fail ? (
                            <tr>
                                <td colSpan={2}>
                                    <p className="text-danger">
                                    Unable to retrieve list.
                                    </p>
                                </td>
                            </tr>                                                
                        ) :
                        listData.length ? (
                        listData.map((obj, key) => {                                                
                            return(
                                <tr key={key}>

                                <td key={"name-" + key}><Link to={this.documentSettings.routes.local.path + "/" + obj.id}>{obj['name']}</Link></td>
                                <td key={"description-" + key}><Link to={this.documentSettings.routes.local.path + "/" + obj.id}>{obj['description']}</Link></td>

                                </tr>  
                                            
                            );
                        }) ) 
                        :
                        fetching ? ( 
                            <tr>
                                <td colSpan={2}>
                                    <p className="text-primary">
                                    Retrieving list...
                                    </p>
                                </td>                                                    
                            </tr> 
                        ) 
                        : 
                        (
                            <tr>
                                <td colSpan={2}>
                                    <p className="text-danger">
                                    No entries found.
                                    </p>
                                </td>
                            </tr>
                        )                                            
                    }
                </tbody>
            </Table>
        );
    }

    render(){
        return(
            <div className="">
                <div className="content">
                    <div className="row">
                        <div className="col-md-12">
                            <h5 className="title" >List of Roles</h5>
                        </div>
                    </div>                    
                </div>
                {
                    this.tableList()
                }
                <div className="content">
                    <div className="row">
                        <div class="col-md-12">
                            <Button bsStyle="default" fill className="btn-fill " style={{width: 150 + "px", "marginBottom": 10 + "px"}} onClick={this.handleNew}>
                                New
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    listData: state.documentList.listData,
    fetching: state.documentList.fetching,    
    success: state.documentList.success,
    fail: state.documentList.fail    
});

const mapActionsToProps = {
    ...documentListActions
};

export default connect(mapStateToProps, mapActionsToProps)(ACLRoleList);