import Api from 'utils/Http'

import * as Services from 'services';

export const role = {
    retrieveList : (store, options = {}) => {

        let filters = Services.document.retrieveDocumentFilters(options);
        return Api.get('/roles?' + filters)
    }
}

export const extendedrole = {
    retrieveList : (store, options = {}) => {

        let filters = Services.document.retrieveDocumentFilters(options);
        return Api.get('/extendedroles?' + filters)
    },
    beforeSave : (store, options = {}) => {
        return {
            success: true
        }
    },
    saveDocument : (store, options = {}) => {
        //save role and acl assignment
        return Api.put('/extendedroles/acl/assignment', (options.documentData|| {}) )
    }
}

export const acl = {
    retrieveList : (store, options = {}) => {

        let filters = Services.document.retrieveDocumentFilters(options);
        return Api.get('/acls?' + filters)
    }
}