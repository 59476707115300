import React from "react";
import ReactDOM from "react-dom";

import { Router, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';


import indexRoutes from "routes/index.jsx";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard.css?v=1.2.0";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";

import Toastr from 'toastr';

import history from './history';
import httpService from './utils/httpService';

import {
    authUserAuthToken,
    closeToastr,
} from './actions';

/**
* REDUX
*/
import cucumberStore from './store';

/**
* HELPERs
*/
import * as authTokenLocalStorage from 'utils/authtoken.localstorage';
import serviceWorker from "utils/serviceWorker";

require('dotenv').config();

const store = cucumberStore();
let currentToastrType;

store.subscribe(() => {
    const state = store.getState();
    const previusToastr = currentToastrType
    currentToastrType = state.toastr.toastrType
    if (currentToastrType && previusToastr !== currentToastrType) {
      const { toastr } = state
      Toastr[toastr.toastrType](toastr.message, toastr.title, { timeOut: 2000 })
      store.dispatch(closeToastr())
    }
    authTokenLocalStorage.authTokenStorageHandler(state.auth.authToken);
});

// set authToken if in localStorage
store.dispatch(authUserAuthToken(authTokenLocalStorage.getTokenFromStorage()));

httpService.setupRequestInterceptors(store);
httpService.setupResponseInterceptors(store, history);

ReactDOM.render(
    <Provider store={store}>
        <Router history={history} >
            <Switch>
                {
                    indexRoutes.map((prop, key) => {
                    return <Route path={prop.path} component={prop.component} key={key} />
                    })
                }
            </Switch>
        </Router>        
    </Provider>,            
    document.getElementById("root")
);

//register service worker
serviceWorker();