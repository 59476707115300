/* eslint-disable */

export default () => {
    /* eslint-disable-line no-restricted-globals */
    self.addEventListener("message", e => {
        // eslint-disable-line no-restricted-globals
        if (!e) return;

        console.log('notificationWorker.eventListener ', e.data);

        if(e.data.notification){

            const notifications = [];
            const max = Math.random() * (10 - 1) + 1;
            for (let i = 0; i < max; i++) {
                let notification = {
                    id: i,
                    description: "Notification: " + (i+1+e.data.notification.new.length+e.data.notification.old.length)
                }
                notifications.push(notification);
            }

            postMessage(notifications);
        }
    });
};