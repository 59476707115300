import React from 'react';
import { connect } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import * as userActions  from './../actions/auth.action';
import url from "./../../utils/url";

class SSO extends React.Component {

    componentDidMount(props) {
        console.log('SSO componentDidUpdate')
        let pageParams = url.urlSearchParams(window.location.search)
        if(pageParams['token']){
            this.props.sso(pageParams['token']);
        }
    }

    render() {
        return(
            <div className="row">
                <div className="text-center">
                    <BeatLoader
                        sizeUnit={"px"}
                        size={15}
                        color={'#e3e3e5'}
                        loading={this.props.authenticating}
                    />
                    {
                        this.props.fail &&
                        <p className="text-danger">
                            Unable to authenticate your session. Please go back to your app and log in again.
                        </p>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
	authenticating: state.auth.authenticating,
	success: state.auth.success,
	fail: state.auth.fail 
});

const mapActionsToProps = {
    ...userActions
};

export default connect(mapStateToProps, mapActionsToProps)(SSO)