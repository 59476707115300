import React, { PureComponent } from "react";
import Dropzone from 'react-dropzone';
import { BeatLoader } from 'react-spinners';
import isEqual from "lodash.isequal";

class FileInput extends PureComponent{

    constructor(props){
        super(props);
        this.onDrop = this.onDrop.bind(this);
        this.onDragEnter = this.onDragEnter.bind(this);
        this.state = {
            files: [],
            loading: false
        }
    }

    componentDidMount(){
        if(this.props.initialPreview && Array.isArray(this.props.initialPreview)){
            this.setState(state => Object.assign({}, state,  { files: this.props.initialPreview }) )
        }
    }

    componentDidUpdate(prevProps){

        !isEqual(prevProps.initialPreview, this.props.initialPreview)
        &&
        this.setState(state => Object.assign({}, state,  { files: this.props.initialPreview }) )
    }

    onDragEnter(){
        console.log('FileInput onDragEnter');
    }

    onDrop(files){
        console.log(files);

        if(files.length && this.props.contentManager){

            this.setState((state) => {
                return Object.assign({}, state, 
                {
                    loading: true
                });
            });
            for(let file of files){
                this.props.contentManager.pushContents(file,this.props.tag,{
                    filesdir: this.props.filesdir,
                    fileType: this.props.filetype,
                    ownerId: this.props.ownerId,
                    model: this.props.model ? this.props.model: {}
                },(err, res) => {
                    if(!err && res){
                        console.log(res);
                        let stateFiles = [];
                        if(this.props.multiple){
                            stateFiles = [...this.state.files, res.url];
                        }else{
                            stateFiles = [res.url];
                        }
                        this.setState((state) => {
                            return Object.assign({}, state, 
                            {
                                files: stateFiles
                            });
                        });
                        if(this.props.handleChange){ 
                            this.props.handleChange({target:{
                                name: this.props.name,
                                value: res.url
                            }})                   
                        }
                    }
                    this.setState((state) => {
                        return Object.assign({}, state, 
                        {
                            loading: false
                        });
                    });
                    console.log('FileInput state ',this.state);
    
                });
            }
            
        }
    };

    preview(files=[]){
        //console.log('FileInput preview', files);
        return files.map((obj, key) => {
            
            if(!obj || obj == undefined){
                obj = this.props.placeholder || "/images/placeholder.png"
            }
            console.log('FileInput preview', obj, key);

            if(this.props.multiple){
                if(this.props.filetype && (this.props.filetype.includes("document") || this.props.filetype.includes("pdf") ) 
                    && this.props.placeholder) {
                    return (
                        <div class="row">
                            <div className="col-md-2">
                                <img key={key} 
                                src={this.props.placeholder} 
                                className="form-control" 
                                style={{display:'inline', margin: '10px 10px 10px 0', height: '100px', width: '100px'}}/>
                            </div>
                            <div className="col-md-8" style={{padding:15+"px"}}>
                                <a href={obj}> { obj.substr(0,100) + (obj.length > 100 ? ' ...':'')} </a>
                            </div>
                        </div>
                    )

                }else{
                    return (
                        <div class="row">
                            <div className="col-md-2">
                                <img key={key} 
                                src={"images/placeholder.png"} 
                                className="form-control" 
                                style={{display:'inline', margin: '10px 10px 10px 0', height: '100px', width: '100px'}}/>
                            </div>
                            <div className="col-md-8" style={{padding:15+"px"}}>
                                <a href={obj}> { obj.substr(0,100) + (obj.length > 100 ? ' ...':'')} </a>
                            </div>
                        </div>
                    )                    
                }
            }
            return <img key={key} 
                    src={obj} 
                    className="form-control" 
                    style={{height: this.props.imageHeight, width: this.props.imageWidth || 'auto', display: 'inline', margin: '10px 10px 10px 0'}}/>
        })
    }

    render(){
        return (
            <Dropzone
                {...this.props}
                onDrop = {this.onDrop}
            >
                {({getRootProps, getInputProps, isDragActive}) => (
                    <div {...getRootProps()}>
                        <input {...getInputProps()}  />
                        {
                        Array.isArray(this.state.files) && this.state.files.length ?
                        this.preview(this.state.files) :
                        <img src="/images/placeholder.png" className="form-control" style={{height: this.props.imageHeight, width: this.props.imageWidth || 'auto'}}/>
                        }
                        <div className="" style={{display:'block', marginTop: 10+"px", border: 0}}>
                        {isDragActive ? "Drop it like it's hot!" : 'Click me or drag a file to upload!'}
                        </div>
                        {
                        this.state.loading ? 
                        <div className='sweet-loading' style={{marginTop:15+"px", marginBottom:15+"px"}}>
                            <BeatLoader
                                sizeUnit={"px"}
                                size={15}
                                color={'#e3e3e5'}
                                loading={this.state.loading}
                            />
                        </div> 
                        :""
                        }
                        
                    </div> 
                )}
            </Dropzone>            
        )
    }

}

export default FileInput;