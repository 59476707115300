/**
 * TOASTR NOTIFICATIONS
 */

export const openToastr = (toastrType = null, title = '', message = '') => ({
  type: 'TOASTR_OPEN',
  toastrType,
  title,
  message,
})

export const closeToastr = () => ({
  type: 'TOASTR_CLOSE',
})

export const clearToastr = () => ({
    type: 'TOASTR_CLEAR',
})


export const addNotifications = (type, title, message, options={}) => {
    return (dispatch, getState) => {
        dispatch(openToastr(type,title,message))
        if(options.next){
            options.next(getState())
        }
    }
}

export const clearNotifications = (options={}) => {
    return (dispatch, getState) => {
        dispatch(clearToastr())
        if(options.next){
            options.next(getState())
        }
    }
}