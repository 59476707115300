import React, { Component } from "react"
import { connect } from 'react-redux';
import { StatsCard } from "common/components/StatsCard.jsx";
import * as Services from "services"

class PatientCountWidget extends Component {
	
	constructor(props) {            
        super(props);
        this.state = {
            count: 0,           
		}
    }

    componentDidMount () {
        console.log('[Widget] Welcome Widget Mounted')
    }

    handleCount = () => {
    }
    
	render() {        
        let { account } = this.props
        return (			
            <StatsCard
                bigIcon={<i className="pe-7s-id text-success" title="Welcome" />}
                statsText="Welcome"
                statsDesc={account && account.user && account.user.first_name != undefined ? account.user.first_name + " " + account.user.last_name : ""}
                statsIcon={<i className="fa fa-user-o" />}
                statsIconText={account && account.user ? account.user.email: ""}
            />
		);
	}
}

const mapStateToProps = (state) => (
    {
        account: state.account
    }
)

export default connect(mapStateToProps)(PatientCountWidget);