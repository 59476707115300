import React, { Component } from "react";
import SSO from "./../../authentication/components/SSO";
import * as Settings from "settings";
import url from "./../../utils/url";

class SSOContainer extends Component {
	constructor(props) {
		super(props);
    }
    
	render() {
		return (
			<div id="main-panel" className="container" ref="mainPanel">
				<div className="row" style={{marginTop:80+"px"}}>
					<div className="col-lg-offset-3 col-lg-6 col-md-12">
                        <div className="row">
                            <div className="text-center">
						        <img src="https://starship-live.s3.us-east-2.amazonaws.com/starship.png"   width="200px"/>
                            </div>
                        </div>
					</div>
					
				</div>
				<div className="row" style={{marginTop:30+"px"}}>
					<div className="col-lg-offset-3 col-lg-6 col-md-12">
                       <SSO {...this.props} />
					</div>
				</div>
				<div className="form-group col-md-12">
					<p className="copyright" style={{textAlign:"center", marginTop:30+"px"}}>
						<a href="#">{Settings.app.copyright}</a>
						&copy; {new Date().getFullYear()}{" "}
					</p>
				</div> 
			</div>
		);
	}
}

export default SSOContainer;
