import React, { Component } from "react";
import { FormGroup, ControlLabel, FormControl, Row } from "react-bootstrap";

function FieldGroup({ label, ...props }) {
    
    let {error} = props;
    let message = error && error.data && error.data.error && error.data.error.details && error.data.error.details.messages[props.name] ? 
        error.data.error.details.messages[props.name]: null;
    let options = [];
    for (let key of Object.keys(props.selectionList)) {
		let selectItem = props.selectionList[key];
        options.push(
			<option key={key} value={key}>
				{selectItem}
			</option>
        );
    }

    return (
        <FormGroup controlId="formControlsSelectMultiple" className={message? "has-error":""}>
            <ControlLabel>{label}</ControlLabel>
            {
            <FormControl componentClass="select" {...props}>
                {options}
            </FormControl>
            }
            {
                props.error ? 
                <span className="help-block">{message}</span>
                : ""
            } 
        </FormGroup>
    );
}

export class InputDropdown extends Component {
  render() {
    var row = [];
    for (var i = 0; i < this.props.ncols.length; i++) {
      row.push(
        <div key={i} className={this.props.ncols[i]}>
          <FieldGroup {...this.props.proprieties[i]} />
        </div>
      );
    }
    return <Row>{row}</Row>;
  }
}

export default InputDropdown;
