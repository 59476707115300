import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";

import Menu from "./../../menus";
import NotificationSystem from "react-notification-system";
//notification workers
import notificationWorker from "./../../notificationworker.js";
import WebWorker from "./../../webworker.js";
import { style } from "variables/Variables.jsx";
import * as Services from 'services';
//account
import * as accountActions from "common/actions/account.action";
//toastr
import * as toastrActions from "common/actions/toastr.action";

class HeaderLinks extends Component {

    notificationSystem = null;

    constructor(props){
        super(props);
        this.state = {
            notifications: {
                new: [],
                old: []
            }
        }
    }

    componentDidMount()  {
        this.worker = new WebWorker(notificationWorker);
        this.worker.addEventListener("message", event => {
            this.setState((state) => {
                return Object.assign({}, state, 
                {
                    notifications: {
                        new: event.data,
                        old: [...state.notifications.new, ...state.notifications.old]
                    }
                });
            });  
        });

        /*
        setInterval(() => {
            this.worker.postMessage({notification: this.state.notifications});
        }, 20000);//20 seconds
        */
       
        this.props.getUserData();
        this.notificationSystem = this.refs.notificationSystem;
    }

    componentWillUnmount(){
        this.worker.terminate();
    }

    componentWillReceiveProps(props){
        if(props.toastr){
            console.log('HeaderLinks componentWillReceiveProps', props.toastr);
        }
    }

    documentAlert(){
        let {toastr} = this.props;
        let icon = "pe-7s-info"
        
        if(toastr.toastrType=="error"){
            icon = "pe-7s-attention"
        }
        
        if(this.notificationSystem && toastr.toastrType){
            console.log('HeaderLinks componentWillReceiveProps addNotification', toastr);
            this.notificationSystem.clearNotifications();
            this.notificationSystem.addNotification({
                title: <span data-notify="icon" className={icon} />,
                message: (
                  <div>
                      {toastr.message}
                  </div>
                ),
                level: toastr.toastrType,
                autoDismiss: 20
            });
            this.props.clearNotifications()
        }

        return (
            <div></div>
        )

    }

    render() {
        console.log('HeaderLinks render', this.props.toastr)
        const notification = (
            <div>
                <i className="fa fa-globe" />
                <b className="caret" />
                {
                this.state.notifications.new.length > 0 ?
                <span className="notification">{this.state.notifications.new.length}</span>
                : ""
                }
                <p className="hidden-lg hidden-md">Notification</p>
            </div>
        );
        let notifications = [...this.state.notifications.new, ... this.state.notifications.old].slice(0, 10);
        let currentRole = this.props.account && this.props.account.user && this.props.account.user.roles_mask ?
                            this.props.account.user.roles_mask : 'guest';
        return (
            <React.Fragment>
                <Nav>
                    <NavItem eventKey={1} href="#">
                        <i className="fa fa-dashboard" />
                        <p className="hidden-lg hidden-md">Dashboard</p>
                    </NavItem>
                    <NavDropdown
                    eventKey={2}
                    title={notification}
                    noCaret
                    id="basic-nav-dropdown"
                    >
                    {
                        notifications.length > 0 ?
                        notifications.map((notification, key)=>{
                            return (
                                <MenuItem key={key} eventKey={key}>{notification.description}</MenuItem>
                            )
                        })
                        :
                        <MenuItem eventKey={"empty-notifications"}>No notification received.</MenuItem>
                    }
                    </NavDropdown>
                    <NavItem eventKey={3} href="/finder">
                        <i className="fa fa-search" />
                        <p className="hidden-lg hidden-md">Search</p>
                    </NavItem>
                </Nav>
                <Nav pullRight>
                {
                    Menu.header.map((menu, key)=>{
                        return (

                            menu.defaultRoles && 
                            (menu.defaultRoles.includes('guest') || menu.defaultRoles.includes(currentRole)) ?
                            <NavDropdown
                                key={key}
                                eventKey={key + 1}
                                title={menu.title}
                                id="basic-nav-dropdown-right"
                            >
                            {
                                menu.subMenu ? 
                                (
                                    menu.subMenu.map((m,k)=>{
                                        return (
                                            <MenuItem key={k} eventKey={(key + 1) + "." + k} href={m.to}>
                                                {m.title}
                                            </MenuItem>
                                        )
                                    })
                                )
                                : ""
                            }
                            </NavDropdown>
                            :""
                        )  
                    })
                }          
                <NavItem eventKey={3} href="/logout">
                    Log out
                </NavItem>
            </Nav>
                {
                    this.documentAlert()
                }
                <NotificationSystem ref="notificationSystem" style={style} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    account: state.account,
    toastr: state.toastr
});

const mapActionsToProps = {
    ...accountActions, ...toastrActions
};

export default connect(mapStateToProps, mapActionsToProps)(HeaderLinks);