import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import * as Services from 'services';

class PatientListInfoGraphics extends Component {

	constructor(props){
		super(props);
		this.state = {      
            resigned: 0
		}
	}

	componentDidMount(){   
        
        /*
        Services.patient.count(null,{
            filters: 'where[status][gte]=0',
            pagination: { disabled: true }
        })
        .then(res=>{
            this.setState(state=>{
                return Object.assign({}, state, {
                    resigned: res.data.count
                })
            })
            console.log('PatientListInfoGraphics componentDidMount', res);
        })
        */
    }

    render() {
        const { pagination } = this.props;
        console.log('DocumentListInfoGraphics', this.props.pagination)

        let pageDescription = pagination ? pagination.currentPage: 1 //+ ' of ' + Math.ceil(pagination.totalCount/pagination.pageSize) : 1;

        return (
            <React.Fragment>
            <Col md={4} style={{paddingRight:0}}>
                <div className="card card-stats" style={{marginBottom:0}}>
                    <div className="content">
                        <Row>
                            <Col xs={4}>
                            <div className="icon-big text-center">
                                {<i className="pe-7s-users text-info" />}
                            </div>
                            </Col>
                            <Col xs={8}>
                            <div className="numbers">
                                <p>Active</p>
                                {this.props.pagination ? this.props.pagination.totalCount: '-'}
                            </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
            <Col md={4} style={{paddingRight:0}}>
                <div className="card card-stats" style={{marginBottom:0}}>
                    <div className="content">
                        <Row>
                            <Col xs={4}>
                            <div className="icon-big text-center">
                                {<i className="pe-7s-less text-danger" />}
                            </div>
                            </Col>
                            <Col xs={8}>
                            <div className="numbers">
                                <p>Inactive</p>
                                {this.state.resigned}
                            </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
            <Col md={4} style={{paddingRight:0}}>
                <div className="card card-stats" style={{marginBottom:0}}>
                    <div className="content">
                        <Row>
                            <Col xs={4}>
                            <div className="icon-big text-center">
                                {<i className="pe-7s-less text-info" />}
                            </div>
                            </Col>
                            <Col xs={8}>
                            <div className="numbers">
                                <p>Page</p>
                                <p>{pageDescription}</p>
                            </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
            </React.Fragment>
        );
    }
}

export default PatientListInfoGraphics;